// Only used for Athlete u_pool
import BaseModule from './base_module'
import sysUserGroup from '../../service-now/tables/sys_user_group'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'
import customerContact from '../../service-now/tables/customer_contact'

class SysUserGroup extends BaseModule {
    constructor() {
        super(sysUserGroup.table)
        this.sync = true
        this.syncOrder = 16 // Loaded after customer_contact
        this.relationships = [
            {table: customerContact.table, field: 'u_pool'}
        ]
    }

    async fetch() {
        const athletes = await dal.modules.customer_contact.getAll()
        // Get all the Blood Sample records that are related to the loaded DCF numbers.
        return await pagination.paginateData(
            'sys_idIN',
            athletes.map((x) => x.u_pool),
            (pageQuery) =>
                api.getTableData(sysUserGroup.table, `${pageQuery}`, true)
        )
    }
}

const module = new SysUserGroup()
export default module