// Only used for athletes since location red flags are not settable and we can just use display_value to get those.
import BaseModule from './base_module'
import uRedFlag from '../../service-now/tables/u_red_flag'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class RedFlag extends BaseModule {
    constructor() {
        super(uRedFlag.table)
        this.sync = true
        this.syncOrder = 16 // After customer_contact
    }

    async fetch() {
        const athletes = await dal.modules.customer_contact.getAll()
        const athleteRedFlags = await pagination.paginateData(
            'u_athleteIN',
            athletes.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(uRedFlag.table, `${pageQuery}^u_flag_type=Athlete`, false)
        )

        const locations = await dal.modules.cmn_location.getAll()
        const locationRedFlags = await pagination.paginateData(
            'u_locationIN',
            locations.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(uRedFlag.table, `${pageQuery}^u_flag_type=Location`, false)
        )

        return [...athleteRedFlags, ...locationRedFlags]
    }
}

const module = new RedFlag()
export default module
