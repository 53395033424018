import BaseModule from './base_module'
import cmdbModel from '../../service-now/tables/cmdb_model'
import almHardware from '../../service-now/tables/alm_hardware'
import api from '../../service-now/api'

class ProductModel extends BaseModule {
    constructor() {
        super(cmdbModel.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: almHardware.table,
                field: 'model'
            }
        ]
    }

    async fetch() {
        // Get all the Product Models where the asset class is alm_hardware (Equipment used for urine and blood samples).
        const query = `asset_class=alm_hardware`
        const { data } = await api.getTableData(cmdbModel.table, query, true)
        return data.result
    }
}

const module = new ProductModel()
export default module
