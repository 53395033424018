import BaseModule from './base_module'
import dsDocument from '../../service-now/tables/ds_document'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'
import dsDocumentReference from '../../service-now/tables/ds_document_references'
import dsDocumentVersion from '@/service-now/tables/ds_document_version'
class DSDocument extends BaseModule {
    constructor() {
        super(dsDocument.table)

        this.sync = true

        this.syncOrder = 11 // Make sure this is after test_sessions
        this.relationships = [
            {
                table: dsDocumentReference.table,
                field: 'target_record'
            },
            {
                table: dsDocumentReference.table,
                field: 'document'
            },
            {
                table: dsDocumentVersion.table,
                field: 'document'
            }
        ]
    }

    async fetch() {
        const missionIds = await dal.modules.sn_customerservice_test_mission.getSysIds()
        const testSessionIds = await dal.modules.sn_customerservice_test_session.getSysIds()

        // TODO: filter on state?
        // state: "active"

        const missionData = await pagination.paginateData(
            'parentRecordSysIdIN',
            missionIds,
            (pageQuery) => api.getTableData(dsDocument.table, `${pageQuery}`)
        )

        const testSessionData = await pagination.paginateData(
            'u_recordIN',
            testSessionIds,
            (pageQuery) => api.getTableData(dsDocument.table, `${pageQuery}`)
        )

        return [...missionData, ...testSessionData]
    }
}

const module = new DSDocument()
export default module
