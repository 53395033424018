<template>
   <v-row>
      <v-col cols="12">
         <v-main>
            <div v-if="dcf" style="min-height: 100vh;">
               <v-sheet v-if="dcf && athlete" class="si-form-sheet text-body-2 mx-auto">
                  <v-container fluid>
                     <v-row>
                        <v-col cols="12" md="12">
                           <h2>Athlete Information</h2>
                           <DividerWithIcons />
                           <p class="font-weight-bold font-italic">
                              Athlete contact information
                           </p>
                           <br>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Address Type</span>
                           <v-combobox v-model="selectedAddressType" label="" density="compact" :items="addressTypes" item-title="label"
                              item-value="id" @update:model-value="updateAddressType" bg-color="white"></v-combobox>
                        </v-col>
                     </v-row>
                     <v-row v-if="selectedAddressType">
                        <v-col cols="12" md="6" class="si-form">
                           <v-text-field v-model="dcf.u_street" label="Street" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <v-text-field v-model="dcf.u_suburb" label="Suburb" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <v-text-field v-model="dcf.u_city" label="City" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <v-combobox v-model="selectedCountry" label="" density="compact" :items="countryOptions" item-title="name"
                              item-value="id" @update:model-value="updateCountry" bg-color="white"></v-combobox>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <v-text-field v-model="dcf.u_zip_postal_code" label="Post Code" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <v-text-field v-model="athlete.mobile_phone" label="Phone Number" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                     </v-row>
                     <div v-if="selectedAddressType" class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Email</span>
                           <v-text-field v-model="athlete.email" label="" density="compact" bg-color="white"
                              :error-messages="x$.email.$errors.map(e => e.$message)" @blur="x$.email.$touch"
                              type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form-opt-out">
                           <span class="font-italic si-top-label">
                              Athlete hasn’t provided an email address
                           </span>
                           <v-checkbox v-model="dcf.u_athlete_hasn_t_provided_an_email_address" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Nationality</span>
                           <v-combobox v-model="selectedNationalities" label="" density="compact" :items="nationalityList" item-title="u_name"
                              multiple
                              item-value="id" @update:model-value="updateNationalities" bg-color="white"></v-combobox>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="font-weight-bold font-italic">
                              Other Contacts
                           </p>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Coach</span>
                           <div class="si-form-inline">
                              <v-text-field v-model="dcf.u_coach_first_name" label="First Name" density="compact"
                                 required :error-messages="v$.u_coach_first_name.$errors.map(e => e.$message)"
                                 @blur="v$.u_coach_first_name.$touch" bg-color="white" type="text"></v-text-field>
                              <v-text-field v-model="dcf.u_coach_last_name" label="Surname" density="compact" required
                                 :error-messages="v$.u_coach_last_name.$errors.map(e => e.$message)"
                                 @blur="v$.u_coach_last_name.$touch" bg-color="white" type="text"></v-text-field>
                           </div>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form-opt-out">
                           <span class="font-italic si-top-label">
                              Athlete does not have a coach
                           </span>
                           <v-checkbox v-model="dcf.u_athlete_does_not_have_a_coach" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Doctor</span>
                           <div class="si-form-inline">
                              <v-text-field v-model="dcf.u_doctor_first_name" label="First Name" density="compact"
                                 required :error-messages="v$.u_doctor_first_name.$errors.map(e => e.$message)"
                                 @blur="v$.u_doctor_first_name.$touch" bg-color="white" type="text"></v-text-field>
                              <v-text-field v-model="dcf.u_doctor_last_name" label="Surname" density="compact" required
                                 :error-messages="v$.u_doctor_last_name.$errors.map(e => e.$message)"
                                 @blur="v$.u_doctor_last_name.$touch" bg-color="white" type="text"></v-text-field>
                           </div>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form-opt-out">
                           <span class="font-italic si-top-label">
                              Athlete does not have a doctor
                           </span>
                           <v-checkbox v-model="dcf.u_athlete_does_not_have_a_doctor" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Medical Centre</span>
                           <v-text-field v-model="dcf.u_medican_centre_name" label="" density="compact" bg-color="white"
                              type="text"></v-text-field>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Arrival time at DCS</span>
                           <div class="si-form-inline">
                              <v-text-field v-model="arrivalDate" label="" density="compact" bg-color="white" required
                                 @update:model-value="updateArrivalDate" type="date"
                                 :error-messages="w$.arrivalDate.$errors.map(e => e.$message)" @blur="w$.arrivalDate.$touch">
                              </v-text-field> 
                              <v-text-field v-model="arrivalTime" label="" density="compact" bg-color="white" required
                                 @update:model-value="updateArrivalTime" type="time"
                                 :error-messages="w$.arrivalTime.$errors.map(e => e.$message)" @blur="w$.arrivalTime.$touch">
                              </v-text-field>
                           </div>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Reason for Delay</span>
                           <v-combobox v-model="selectedReason" label="" density="compact" :items="reasonOptions" item-title="label"
                           item-value="id" @update:model-value="updateReason" bg-color="white"></v-combobox>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="12" class="si-form-inline">
                           <p>
                              Athlete was chaperoned continuously until arriving at DCS?
                           </p>
                           <v-checkbox v-model="dcf.u_athlete_was_chaperoned_continuously_until_arriving_at_dcs" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12" class="si-form-inline" v-if="!isTrue(dcf.u_athlete_was_chaperoned_continuously_until_arriving_at_dcs)">
                           <v-textarea label="Comments" density="compact" bg-color="white">
                           </v-textarea>
                        </v-col>

                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Type of identification</span>
                           <v-combobox v-model="selectedIdentification" label="" density="compact" :items="identificationOptions" item-title="label"
                           item-value="id" @update:model-value="updateIdentification" bg-color="white"></v-combobox>
                        </v-col>
                     </v-row>
                     <v-row v-if="isPhotoId">
                        <v-col cols="12" md="12" class="si-form">
                           <span class="font-italic si-top-label">ID Description</span>
                           <v-text-field v-model="dcf.u_id_description" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                     </v-row>
                     <v-row v-else-if="isThirdPartyId">
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Third Party ID Name</span>
                           <v-text-field v-model="dcf.u_third_party_id_name" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" class="si-form">
                           <span class="font-italic si-top-label">Third Party ID Role</span>
                           <v-text-field v-model="dcf.u_third_party_id_role" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                     </v-row>
                     <v-row v-else-if="isOtherId">
                        <v-col cols="12" md="12" class="si-form">
                           <span class="font-italic si-top-label">Other</span>
                           <v-text-field v-model="dcf.u_other" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                     </v-row>

                  </v-container>
               </v-sheet>

               <v-sheet class="si-form-sheet text-body-2 mx-auto">
                  <v-container fluid>
                     <v-row>
                        <v-col cols="12" md="12">
                           <h2>Comments</h2>
                           <DividerWithIcons is-compact="true" />
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="12" class="si-form-inline">
                           <v-textarea v-model="dcf.u_athlete_info_comments" label="Athlete Information comments" density="compact" bg-color="white">
                           </v-textarea>
                        </v-col>
                     </v-row>
                  </v-container>
               </v-sheet>

               <v-container class="pa-0" fluid>
                  <v-row class="d-flex justify-end">
                     <v-col cols="12" md="3" class="d-flex">
                        <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                           variant="tonal">
                           Save
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-container>

            </div>
         </v-main>
      </v-col>
   </v-row>
   <v-snackbar v-model="validationError">
      Please fill in all required fields.

      <template v-slot:actions>
         <v-btn color="red" variant="text" @click="validationError = false">
            Close
         </v-btn>
      </template>
   </v-snackbar>
</template>

<script>

import { ref, onMounted, computed, toRaw } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'
import customer_contact from '@/dal/modules/customer_contact'
import sys_choice from '@/dal/modules/sys_choice'
import core_country from '@/dal/modules/core_country'
import sn_customerservice_nationality from '@/dal/modules/sn_customerservice_nationality'
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'

export default {
   name: 'AthleteInformation',
   components: {
      DividerWithIcons
   },
   props: {
      dcfId: {
         type: String,
         required: true
      },
      missionId: {
         type: String,
         required: true
      },
   },
   setup(props) {
      const dcf = ref({})
      const athlete = ref({})
      const emailNotProvided = ref(false)
      const coachNotProvided = ref(false)
      const doctorFirstName = ref('')
      const doctorLastName = ref('')
      const doctorNotProvided = ref(false)
      const wasChaperoned = ref(false)
      const isPhotoId = ref(false)
      const isThirdPartyId = ref(false)
      const isOtherId = ref(false)
      const validationError = ref(false)
      const selectedAddressType = ref(null)
      const arrivalTime = ref(null)
      const arrivalDate = ref(null)
      const addressTypes = ref([])
      const nationalityList = ref([])
      const selectedNationalities = ref([])
      const reasonOptions = ref([])
      const selectedReason = ref(null)
      const identificationOptions = ref([])
      const selectedIdentification = ref(null)
      const countryOptions = ref([])
      const selectedCountry = ref(null)

      onMounted(async () => {

         addressTypes.value  = await sys_choice.getDcfAddressTypes()
         nationalityList.value = await sn_customerservice_nationality.getAll()
         reasonOptions.value = await sys_choice.getDcfDelayReasonOptions()
         identificationOptions.value = await sys_choice.getIdentificationTypeOptions()
         const countries = await core_country.getAll()
         countryOptions.value = countries.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))

         dcf.value = await sn_customerservice_dcf.get(props.dcfId)
         athlete.value = await customer_contact.get(dcf.value.u_athlete)

         if (dcf.value.u_athlete_country && dcf.value.u_athlete_country !== '') {
            selectedCountry.value = countryOptions.value.find(x => x.id === dcf.value.u_athlete_country)
         }

         if (dcf.value.u_arrival_time_at_dcs) {
            arrivalDate.value = dateTimePickerInitialise('date', dcf.value.u_arrival_time_at_dcs)
            arrivalTime.value = dateTimePickerInitialise('time', dcf.value.u_arrival_time_at_dcs)
         }
         if (dcf.value.u_athlete && dcf.value.u_athlete !== '') {
            athlete.value = await customer_contact.get(dcf.value.u_athlete)
         }
         if (dcf.value.u_address_type && dcf.value.u_address_type !== '') {
            selectedAddressType.value = addressTypes.value.find(x => x.value === dcf.value.u_address_type)
         }
         
         if (athlete.value.u_nationality && dcf.value.u_nationality !== '') {
            const nationalityIds = athlete.value.u_nationality.split(',')
            for (let i = 0; i < nationalityIds.length; i++) {
               selectedNationalities.value.push(nationalityList.value.find(x => x.id === nationalityIds[i]))
            }
         }

         if (dcf.value.u_reason_for_delay && dcf.value.u_reason_for_delay !== '') {
            selectedReason.value = reasonOptions.value.find(x => x.value == dcf.value.u_reason_for_delay)
         }

         if (dcf.value.u_identification_document && dcf.value.u_identification_document !== '') {
            selectedIdentification.value = identificationOptions.value.find(x => x.value === dcf.value.u_identification_document)
            updateIdSelectionType(dcf.value.u_identification_document)
         }


      })

      const updateArrivalDate = (value) => {
         dcf.value.u_arrival_time_at_dcs = dateTimePickerUpdate('date', value, dcf.value.u_arrival_time_at_dcs)
      }

      const updateArrivalTime = (value) => {
         dcf.value.u_arrival_time_at_dcs = dateTimePickerUpdate('time', value, dcf.value.u_arrival_time_at_dcs)
      }

      const updateIdSelectionType = (value) => {
         if (value === 'photo') {
            isPhotoId.value = true
            isThirdPartyId.value = false
            isOtherId.value = false
         } else if (value === 'third_party_id') {
            isPhotoId.value = false
            isThirdPartyId.value = true
            isOtherId.value = false
         } else if (value === 'other') {
            isPhotoId.value = false
            isThirdPartyId.value = false
            isOtherId.value = true
         }
      }

      const updateAddressType = (choice) => {
         dcf.value.u_address_type = choice.value
      }  

      const updateCountry = (value) => {
         dcf.value.u_athlete_country = value.id
      }

      const updateNationalities = (value) => {
         const nationalityIds = value.map(x => x.id).join(',')
         athlete.value.u_nationality = nationalityIds
      }

      const updateReason = (choice) => {
         dcf.value.u_reason_for_delay = choice.value
      }

      const updateIdentification = (choice) => {
         dcf.value.u_identification_document = choice.value
         updateIdSelectionType(choice.value)
      }

      
      const dcfRules = computed(() => {
         const localRules = {
            u_coach_first_name: { },
            u_coach_last_name: { },
            u_doctor_last_name: { },
            u_doctor_first_name: { },
         }
         if (!isTrue(dcf.value.u_athlete_does_not_have_a_coach)) {
            localRules.u_coach_first_name = {
               required
            }
            localRules.u_coach_last_name = {
               required
            }
         }
         if (!isTrue(dcf.value.u_athlete_does_not_have_a_doctor)) {
            localRules.u_doctor_last_name = {
               required
            }
            localRules.u_doctor_first_name = {
               required
            }
         }
         return localRules
      })

      const athleteRules = computed(() =>{
         const localRules = {
            email: {  },
         }
         if (!isTrue(dcf.value.u_athlete_hasn_t_provided_an_email_address)) {
            localRules.email = {
               required,
               email
            }
         }
         return localRules
      })

      const variableRules = {
         arrivalDate: { required, mustBeValidDate: mustBeValidDate() },
         arrivalTime: { required, mustBeValidTime: mustBeValidTime() },
      }

      const v$ = useVuelidate(dcfRules, dcf)
      const w$ = useVuelidate(variableRules, arrivalDate, arrivalTime)
      const x$ = useVuelidate(athleteRules, athlete)

      async function save() {
         v$.value.$validate()
         w$.value.$validate()
         x$.value.$validate()
         if (v$.value.$invalid || w$.value.$invalid || x$.value.$invalid) {
            validationError.value = true
            return
         }

         let data = toRaw(dcf.value)
         await sn_customerservice_dcf.save(data)
         let athleteData = toRaw(athlete.value)
         await customer_contact.save(athleteData)

         await dal.commit()

      }

      return {
         tab: null,
         loading: false,
         emailNotProvided,
         coachNotProvided,
         doctorFirstName,
         doctorLastName,
         doctorNotProvided,
         selectedAddressType,
         addressTypes,
         nationalityList,
         selectedNationalities,
         reasonOptions,
         selectedReason,
         countryOptions,
         selectedCountry,
         arrivalTime,
         arrivalDate,
         wasChaperoned,
         isPhotoId,
         isThirdPartyId,
         isOtherId,
         dcf,
         athlete,
         save,
         updateArrivalDate,
         updateArrivalTime,
         updateAddressType,
         updateCountry,
         updateNationalities,
         updateReason,
         updateIdentification,
         identificationOptions,
         selectedIdentification,
         v$,
         w$,
         x$,
         validationError,
         isTrue,
      }
   }
}
</script>

<style></style>
