import BaseModule from './base_module'
import snChainOfCustody from '../../service-now/tables/sn_customerservice_chain_of_custody'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class ChainOfCustody extends BaseModule {
    constructor() {
        super(snChainOfCustody.table)
        this.sync = true
        this.syncOrder = 11 // After Test Session
    }

    async fetch() {
        const testSessions = await dal.modules.sn_customerservice_test_session.getAll()
        // Get all the Chain of Custody records that are related to the loaded Test Sessions.
        return await pagination.paginateData(
            'u_test_sessionIN',
            testSessions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snChainOfCustody.table,
                    `${pageQuery}`
                )
        )
    }
}

const module = new ChainOfCustody()
export default module
