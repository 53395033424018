<template>
  <v-card>
    <v-toolbar color="#f97316" dark>
      <v-btn icon="mdi-close" @click="close"></v-btn>

      <v-toolbar-title><span v-if="!isEdit">Add</span> Sample: {{ sampleTitle }} <span v-if="isHgh">{{ tubeType }} </span></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text="Save" variant="text" @click="save"></v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-sheet v-if="bloodSample" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>

        <v-row>
          <v-col cols="6" md="3" class="si-form">
            <span v-if="isBloodPassport">Was a blood passport sample collected?</span>
            <span v-else-if="isHgh">Was a hGH sample collected?</span>
            <span v-else-if="isDbs">Was a DBS sample collected?</span>

          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-if="isBloodPassport" v-model="bloodSample.u_was_a_blood_passport_sample_collected"
              true-value="true" false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_was_a_blood_passport_sample_collected) ? 'Yes' : 'No'" hide-details
              inset></v-switch>
            <v-switch v-else-if="isHgh" v-model="bloodSample.u_was_a_blood_sample_collected" true-value="true"
              false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_was_a_blood_sample_collected) ? 'Yes' : 'No'" hide-details inset></v-switch>
            <v-switch v-else-if="isDbs" v-model="bloodSample.u_was_dbs_sample_collected" true-value="true"
              false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_was_dbs_sample_collected) ? 'Yes' : 'No'" hide-details inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="!isTrue(bloodSample.u_was_a_blood_passport_sample_collected) && isBloodPassport"
          class="supplementary-question">
          <v-col cols="6" md="3" class="si-form">
            Reason why sample was not collected?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-textarea v-model="bloodSample.u_reason_why_blood_passport_sample_was_not_collected" 
              :required="!isTrue(bloodSample.u_was_a_blood_passport_sample_collected)"
              :error-messages="v$.u_reason_why_blood_passport_sample_was_not_collected.$errors.map(e => e.$message)"
              @blur="v$.u_reason_why_blood_passport_sample_was_not_collected.$touch" label="Reason Details"
              density="compact" bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row v-else-if="!isTrue(bloodSample.u_was_a_blood_sample_collected) && isHgh" class="supplementary-question">
          <v-col cols="6" md="3" class="si-form">
            Reason why sample was not collected?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-textarea v-model="bloodSample.u_reason_why_blood_sample_was_not_obtained" 
              :required = "!isTrue(bloodSample.u_was_a_blood_sample_collected)"
              :error-messages="v$.u_reason_why_blood_sample_was_not_obtained.$errors.map(e => e.$message)"
              @blur="v$.u_reason_why_blood_sample_was_not_obtained.$touch" label="Reason Details" density="compact"
              bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row v-else-if="!isTrue(bloodSample.u_was_dbs_sample_collected) && isDbs" class="supplementary-question">
          <v-col cols="6" md="3" class="si-form">
            Reason why sample was not collected?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-textarea v-model="bloodSample.u_reason_why_dbs_sample_was_not_obtained" 
              :required = "!isTrue(bloodSample.u_was_dbs_sample_collected)"
              :error-messages="v$.u_reason_why_dbs_sample_was_not_obtained.$errors.map(e => e.$message)"
              @blur="v$.u_reason_why_dbs_sample_was_not_obtained.$touch" label="Reason Details" density="compact"
              bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet v-if="bloodSample" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            <span v-if="isDbs">DBS Kit Used</span>
            <span v-else>Kit Used</span>
          </v-col>
          <v-col cols="4" md="3" class="si-form">
            <v-combobox v-model="selectedKit" label="" density="compact" :items="kitOptions" item-title="serial_number"
              @update:model-value="updateKit" item-value="id" bg-color="white"></v-combobox>
          </v-col>
          <v-col cols="2" md="2" class="si-form si-select-left-label" v-if="isLoading">
            <v-btn class="si-right-action-button" @click="handleOnCanStop" size="large">
              <v-icon icon="mdi-stop-circle"></v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" md="2" class="si-form si-select-left-label" v-else>
            <v-btn class="si-right-action-button" @click="handleOnCanPlay" size="large">
              <v-icon icon="mdi-barcode-scan"></v-icon>
            </v-btn>
            <v-btn v-if="decode" class="si-right-action-button" @click="handleOnReset" size="large">
              <v-icon icon="mdi-refresh"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <StreamQrcodeBarcodeReader ref="refCamera" capture="shoot" show-on-stream @onloading="onLoading"
          @result="onResult" />
        <v-bottom-sheet v-model="showScanError" inset>
          <v-card class="text-center" height="200">
            <v-card-text>
              <v-btn class="mt-6" style="background-color: #758f01; color:white !important"
                @click="showScanError = !showScanError">
                Close
              </v-btn>
              <br>
              <br>
              <div>
                {{ scanErrorDetails }}
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
        <v-row>
          <v-col cols="6" md="3" class="si-form">
            Manufacturer
          </v-col>
          <v-col cols="6" md="3" class="si-form">
            <v-text-field readonly style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
              {{ getManufacturer(selectedKit) }}
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isBloodPassport || isHgh">
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            Tube Type
          </v-col>
          <v-col cols="6" md="3" class="si-form">
            <v-text-field readonly label="" density="compact" bg-color="white" type="text">
              {{ tubeType }}
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isBloodPassport || isHgh">
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            # of Tubes
          </v-col>
          <v-col cols="6" md="3" class="si-form">
            <v-combobox v-model="selectedNumberTubes" label="" density="compact" :items="numberTubes" item-title="label"
              item-value="id" bg-color="white"></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            Collection Date Time
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <div style="display: flex;">
              <v-text-field v-model="collectionDate" label="" density="compact" bg-color="white" type="date"
                :error-messages="w$.collectionDate.$errors.map(e => e.$message)" @blur="w$.collectionDate.$touch"
                @update:model-value="updateCollectionDate">
              </v-text-field>
              <v-text-field v-model="collectionTime" label="" density="compact" bg-color="white" type="time"
                :error-messages="w$.collectionTime.$errors.map(e => e.$message)" @blur="w$.collectionTime.$touch"
                @update:model-value="updateCollectionTime">
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            Sealed Time
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <div style="display: flex;">
              <v-text-field v-model="sealedDate" label="" density="compact" bg-color="white" type="date"
                :error-messages="w$.sealedDate.$errors.map(e => e.$message)" @blur="w$.sealedDate.$touch"
                @update:model-value="updateSealedDate">
              </v-text-field>
              <v-text-field v-model="sealedTime" label="" density="compact" bg-color="white" type="time"
                :error-messages="w$.sealedTime.$errors.map(e => e.$message)" @blur="w$.sealedTime.$touch"
                @update:model-value="updateSealedTime">
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          TODO: Temperature Logger ID
        </v-row> -->
      </v-container>
    </v-sheet>

    <v-sheet v-if="bloodSample" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row v-if="isBloodPassport || isHgh">
          <v-col cols="6" md="3" class="si-form">
            <span class="si-top-label">Blood Collection Official </span>
            <v-combobox v-model="selectedBco" label="" density="compact" :items="bcoList" item-title="name"
              @update:model-value="updateBco" :error-messages="w$.selectedBco.$errors.map(e => e.$message)"
              @blur="w$.selectedBco.$touch" item-value="id" bg-color="white"></v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="isBloodPassport || isHgh" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">Blood Collection Official Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref='bloodCollectionOfficalSignature' :max-width='options.maxWidth'
                :min-width='options.minWidth' height="300px" :options='{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor,
                }' />
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn @click='clearBloodCollectionOfficalSignature' class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <h3>Modifications/Irregularities/Comments</h3>
            <DividerWithIcons is-compact="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" class="si-form">
            Were there any modifications or irregularities to standard <span v-if="isBloodPassport">Blood sample</span>
            <span v-else-if="isHgh">Blood (hGH) sample</span><span v-else-if="isDbs">dried blood spot</span>
            procedures?
          </v-col>
          <v-col cols="6" md="6" class="si-form">

            <v-switch v-if="isBloodPassport"
              v-model="bloodSample.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures_bl"
              true-value="true" false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures_bl) ? 'Yes' : 'No'"
              hide-details inset></v-switch>
            <v-switch v-else-if="isHgh"
              v-model="bloodSample.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures"
              true-value="true" false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures) ? 'Yes' : 'No'"
              hide-details inset></v-switch>
            <v-switch v-else-if="isDbs"
              v-model="bloodSample.u_were_there_any_modifications_or_irregularities_to_standard_procedures_dbs"
              true-value="true" false-value="false" :color="switchColor"
              :label="isTrue(bloodSample.u_were_there_any_modifications_or_irregularities_to_standard_procedures_dbs) ? 'Yes' : 'No'"
              hide-details inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="modificationsOrIrregularities" class="supplementary-question">
          <v-col cols="12" md="12" class="si-form">
            <v-textarea v-if="isBloodPassport" v-model="bloodSample.u_blood_passport_sample_comments" label="Comments"
              density="compact" bg-color="white">
            </v-textarea>
            <v-textarea v-else-if="isHgh" v-model="bloodSample.u_blood_sample_comments" label="Comments"
              density="compact" bg-color="white">
            </v-textarea>
            <v-textarea v-else-if="isDbs" v-model="bloodSample.u_dbs_comments" label="Comments" density="compact"
              bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet v-if="bloodSample" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <h3>Signature</h3>
            <DividerWithIcons is-compact="true" />
          </v-col>
        </v-row>

        <v-row v-if="isDbs">
          <v-col cols="6" md="3" class="si-form">
            <span class="si-top-label">DCO/BCO </span>
            <v-combobox v-model="selectedBcoDco" label="" density="compact" :items="bcoDcoList" item-title="name"
              @update:model-value="updateBcoDco" :error-messages="w$.selectedBcoDco.$errors.map(e => e.$message)"
              @blur="w$.selectedBcoDco.$touch" item-value="id" bg-color="white"></v-combobox>
          </v-col>
        </v-row>
        <v-row v-else-if="isBloodPassport || isHgh">
          <v-col cols="6" md="3" class="si-form">
            <span class="si-top-label">Doping Collection Official </span>
            <v-combobox v-model="selectedDco" label="" density="compact" :items="dcoList" item-title="name"
              @update:model-value="updateDco" :error-messages="w$.selectedDco.$errors.map(e => e.$message)"
              @blur="w$.selectedDco.$touch" item-value="id" bg-color="white"></v-combobox>
          </v-col>
        </v-row>

        <v-row v-if="isDbs">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">DCO/BCO Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref='dcoBcoSignature' :max-width='options.maxWidth' :min-width='options.minWidth'
                height="300px" :options='{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor,
                }' />
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn @click='clearDcoBcoSignature' class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else class="supplementary-question">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">Doping Control Officer Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref='dcoSignature' :max-width='options.maxWidth' :min-width='options.minWidth'
                height="300px" :options='{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor,
                }' />
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn @click='clearDCOSignature' class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-container class="pa-0" fluid>
      <v-row class="d-flex justify-end">
        <v-col cols="12" md="3" class="d-flex">
          <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue';
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import { StreamQrcodeBarcodeReader } from 'vue3-barcode-qrcode-reader'
import { playBeep } from '@/utils/utils.js';
import { useUserStore } from '@/stores/user.js'
import { useMissionStore } from '@/stores/mission'
import { useBloodSampleStore } from '@/stores/bloodSample'
import sn_customerservice_blood_samples from '@/dal/modules/sn_customerservice_blood_samples';
import cmdb_model from '@/dal/modules/cmdb_model';
import core_company from '@/dal/modules/core_company';
import sys_choice from '@/dal/modules/sys_choice';
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'

export default {
  name: 'UrineSampleEdit',
  components: {
    DividerWithIcons,
    VueSignaturePad,
    StreamQrcodeBarcodeReader
  },
  props: {
    id: {
      type: String,
      required: true
    },
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
      type: String,
      required: true
    },
    testType: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const sample = ref()
    const userStore = useUserStore()
    const missionStore = useMissionStore()
    const bloodSampleStore = useBloodSampleStore()
    const bloodSample = ref()
    const isLoaded = ref(false)
    const validationError = ref(false)
    const isBloodPassport = ref(false)
    const isHgh = ref(false)
    const isDbs = ref(false)
    const sampleTitle = ref('')
    const tubeType = ref('')
    const numberTubes = ref([])
    const selectedNumberTubes = ref(null)
    const bcoList = ref([])
    const dcoList = ref([])
    const bcoDcoList = ref([])
    const leadDco = ref(null)
    const dcoBcoSignature = ref(null)
    const dcoSignature = ref(null)
    const isSampleCollected = ref(false)
    const collectionDate = ref(null)
    const collectionTime = ref(null)
    const sealedDate = ref(null)
    const sealedTime = ref(null)
    const selectedBco = ref(null)
    const selectedDco = ref(null)
    const selectedBcoDco = ref(null)
    const bloodCollectionOfficalSignature = ref(null)
    const kitOptions = ref([])
    const kitModelList = ref([])
    const kitManufacturerList = ref([])
    const selectedKit = ref(null)
    const showScanError = ref(false)
    const scanErrorDetails = ref('Scan Error. Please try again')

    const options = ref({
      penColor: 'rgb(0,0,0)',
      backgroundColor: 'rgb(255, 255, 255)',
      maxWidth: 2,
      minWidth: 2,
    })

    onMounted(async () => {
      try {

        if (props.testType === 'BloodPassport') {
          isBloodPassport.value = true;
          sampleTitle.value = 'Blood - Blood Passport';
          tubeType.value = 'SST II (Gold Tops)';
        } else if (props.testType === 'hGH') {
          isHgh.value = true;
          sampleTitle.value = 'Blood (hGH)';
          tubeType.value = 'EDTA (Purple Tops)';
        } else if (props.testType === 'DriedBloodSpot') {
          isDbs.value = true;
          sampleTitle.value = 'Dried Blood Spot';
        }

        await missionStore.loadMission(props.missionId)
        bcoList.value = missionStore.bcoList
        dcoList.value = missionStore.dcoList
        leadDco.value = missionStore.dco

        bcoDcoList.value = Array.from(new Set([...dcoList.value, ...bcoList.value]))
        if (isDbs.value) {
          await bloodSampleStore.loadBloodHardware()
        } else {
          await bloodSampleStore.loadDriedBloodHardware()
        }

        numberTubes.value = await sys_choice.getNumberTubesOptions()
        kitOptions.value = bloodSampleStore.hardwareList
        kitOptions.value = kitOptions.value.filter(kit => kit.serial_number !== '' && kit.serial_number !== null)
        kitModelList.value = await cmdb_model.getAll()
        kitManufacturerList.value = await core_company.select("u_type", 'test_kit_manufacturer')

        if (props.id && props.id !== 'new') {
          bloodSample.value = await sn_customerservice_blood_samples.get(props.id)
          if (isBloodPassport.value) {
            if (bloodSample.value.u_bco_name_blood_passport != '') {
              selectedBco.value = bcoList.value.find(bco => bco.id === bloodSample.value.u_bco_name_blood_passport)
            }
            if (bloodSample.value.u_dco_name_blood_passport != '') {
              selectedDco.value = dcoList.value.find(dco => dco.id === bloodSample.value.u_dco_name_blood_passport)
            }
            if (bloodSample.value.u_blood_passport_kit_used != '') {
              selectedKit.value = kitOptions.value.find(x => x.id === bloodSample.value.u_blood_passport_kit_used)
            }
            if (bloodSample.value.u_of_tubes_blood_passport != '') {
              selectedNumberTubes.value = numberTubes.value.find(x => x.value === bloodSample.value.u_of_tubes_blood_passport)
            }
            if (bloodSample.value.u_sealed_date_time_blood_passport) {
              sealedDate.value = dateTimePickerInitialise('date', bloodSample.value.u_sealed_date_time_blood_passport)
              sealedTime.value = dateTimePickerInitialise('time', bloodSample.value.u_sealed_date_time_blood_passport)
            }
          } else if (isHgh.value) {
            if (bloodSample.value.u_bco_name != '') {
              selectedBco.value = bcoList.value.find(bco => bco.id === bloodSample.value.u_bco_name);
            }
            if (bloodSample.value.u_blood_dco_name != '') {
              selectedDco.value = dcoList.value.find(dco => dco.id === bloodSample.value.u_blood_dco_name);
            }
            if (bloodSample.value.u_blood_kit_used != '') {
              selectedKit.value = kitOptions.value.find(x => x.id === bloodSample.value.u_blood_kit_used);
            }
            if (bloodSample.value.u_blood_of_tubes != '') {
              selectedNumberTubes.value = numberTubes.value.find(x => x.id === bloodSample.value.u_blood_of_tubes);
            }
            if (bloodSample.value.u_blood_sealed_date_time) {
              sealedDate.value = dateTimePickerInitialise('date', bloodSample.value.u_blood_sealed_date_time)
              sealedTime.value = dateTimePickerInitialise('time', bloodSample.value.u_blood_sealed_date_time)
            }
          } else if (isDbs.value) {
            if (bloodSample.value.u_bco_dco_name != '') {
              selectedBcoDco.value = bcoDcoList.value.find(x => x.id === bloodSample.value.u_bco_dco_name);
            }
            if (bloodSample.value.u_dbs_kit_used != '') {
              selectedKit.value = kitOptions.value.find(x => x.id === bloodSample.value.u_dbs_kit_used);
            }
            if (bloodSample.value.u_dbs_sealed_date_time) {
              sealedDate.value = dateTimePickerInitialise('date', bloodSample.value.u_dbs_sealed_date_time)
              sealedTime.value = dateTimePickerInitialise('time', bloodSample.value.u_dbs_sealed_date_time)
            }
          }

        } else {
          // create new Blood Sample
          bloodSample.value = await sn_customerservice_blood_samples.createNew();
          bloodSample.value.u_dcf_number = props.dcfId;

          // Set fixed and default values - based on type
          if (isBloodPassport.value) {
            bloodSample.value.u_dco_name_blood_passport = userStore.user_sys_id;
            bloodSample.value.u_tube_type_blood_passport = tubeType.value;
            selectedNumberTubes.value = numberTubes.value.find(x => x.value === '1');
            bloodSample.value.u_of_tubes_blood_passport = selectedNumberTubes.value.value;
            if (leadDco.value && dcoList.value.length > 0) {
              selectedDco.value = dcoList.value.find(dco => dco.id === leadDco.value.id);
              bloodSample.value.u_dco_name_blood_passport = selectedDco.value ? selectedDco.value.id : '';
            }

          } else if (isHgh.value) {
            bloodSample.value.u_blood_dco_name = userStore.user_sys_id;
            bloodSample.value.u_blood_tube_type = tubeType.value;
            selectedNumberTubes.value = numberTubes.value.find(x => x.value === '2');
            bloodSample.value.u_blood_of_tubes = selectedNumberTubes.value.value;
            if (leadDco.value && dcoList.value.length > 0) {
              selectedDco.value = dcoList.value.find(dco => dco.id === leadDco.value.id);
              bloodSample.value.u_blood_dco_name = selectedDco.value ? selectedDco.value.id : '';
            }

          } else if (isDbs.value) {
            if (leadDco.value && bcoDcoList.value.length > 0) {
              selectedBcoDco.value = bcoDcoList.value.find(x => x.id === leadDco.value.id);
              bloodSample.value.u_bco_dco_name = selectedBcoDco.value ? selectedBcoDco.value.id : '';
            }
          }

        }


      } catch (error) {
        console.error("Error:", error);
      } finally {
        isLoaded.value = true;
      }

    })

    const modificationsOrIrregularities = computed(() => {
      const mod1 = isTrue(bloodSample.value?.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures);
      const mod2 = isTrue(bloodSample.value?.u_any_modifications_or_irregularities_to_standard_blood_collection_procedures_bl);
      const mod3 = isTrue(bloodSample.value?.u_were_there_any_modifications_or_irregularities_to_standard_procedures_dbs);
      return mod1 || mod2 || mod3;
    });

    // Note - maybe no field for collection datetime yet?
    // Might be sn_customerservice_sample.u_sample_sealed_time
    // link sample u_test_session_id: "909051731ba5161016122137b04bcb0c"

    // TODO - Collection Date not yet implemented 
    const updateCollectionDate = (value) => {
      collectionDate.value = value;
    }
    const updateCollectionTime = (value) => {
      collectionTime.value = value;
    }

    const updateSealedDate = (value) => {
      if (isBloodPassport.value) {
        bloodSample.value.u_sealed_date_time_blood_passport = dateTimePickerUpdate('date', value, bloodSample.value.u_sealed_date_time_blood_passport)
      } else if (isHgh.value) {
        bloodSample.value.u_blood_sealed_date_time = dateTimePickerUpdate('date', value, bloodSample.value.u_blood_sealed_date_time)
      } else if (isDbs.value) {
        bloodSample.value.u_dbs_sealed_date_time = dateTimePickerUpdate('date', value, bloodSample.value.u_dbs_sealed_date_time)
      }
    }

    const updateSealedTime = (value) => {
      if (isBloodPassport.value) {
        bloodSample.value.u_sealed_date_time_blood_passport = dateTimePickerUpdate('time', value, bloodSample.value.u_sealed_date_time_blood_passport)
      } else if (isHgh.value) {
        bloodSample.value.u_blood_sealed_date_time = dateTimePickerUpdate('time', value, bloodSample.value.u_blood_sealed_date_time)
      } else if (isDbs.value) {
        bloodSample.value.u_dbs_sealed_date_time = dateTimePickerUpdate('time', value, bloodSample.value.u_dbs_sealed_date_time)
      }
    }

    const updateNumberTubes = (choice) => {
      if (isBloodPassport.value) {
        bloodSample.value.u_of_tubes_blood_passport = choice.value
      } else if (isHgh.value) {
        bloodSample.value.u_blood_of_tubes = choice.value
      }
    }

    const updateBco = (value) => {
      if (isBloodPassport.value) {
        bloodSample.value.u_bco_name_blood_passport = value.id
      } else if (isHgh.value) {
        bloodSample.value.u_bco_name = value.id
      }
    }

    const updateDco = (value) => {
      if (isBloodPassport.value) {
        bloodSample.value.u_dco_name_blood_passport = value.id
      } else if (isHgh.value) {
        bloodSample.value.u_blood_dco_name = value.id
      }
    }

    const updateBcoDco = (value) => {
      if (isDbs.value) {
        bloodSample.value.u_bco_dco_name = value.id
      }
    }

    const getManufacturer = (selectedKit) => {
      if (selectedKit) {
        const model = kitModelList.value.find(model => model.id === selectedKit.model);
        if (model) {
          const manufacturer = kitManufacturerList.value.find(manufacturer => manufacturer.id === model.manufacturer);

          if (isBloodPassport.value) {
            bloodSample.value.u_manufacturer_blood_passport = manufacturer.id
          } else if (isHgh.value) {
            bloodSample.value.u_blood_manufacturer = manufacturer.id
          } else if (isDbs.value) {
            bloodSample.value.u_dbs_manufacturer = manufacturer.id
          }
          return manufacturer ? manufacturer.name : "";
        }
      }
      return "";
    }

    const clearDCOSignature = () => {
      if (dcoSignature.value) {
        dcoSignature.value.clearCanvas();
      }
    }

    const clearDcoBcoSignature = () => {
      if (dcoBcoSignature.value) {
        dcoBcoSignature.value.clearCanvas();
      }
    }

    const clearBloodCollectionOfficalSignature = () => {
      if (bloodCollectionOfficalSignature.value) {
        bloodCollectionOfficalSignature.value.clearCanvas();
      }
    }

    const decode = ref(undefined)
    const isLoading = ref(false)

    function onResult(data) {
      if (data) {
        playBeep()
        const scannedBarcode = data;
        const selectedKit = kitOptions.value.find(kit => kit.serial_number === scannedBarcode);
        if (!selectedKit) {
          scanErrorDetails.value = 'The code scanned: ' + data + ' was not found. Please try again.';
          showScanError.value = true;
          return;
        }
        decode.value = data
      }
    }

    function onLoading(loading) {
      isLoading.value = loading
    }

    // define ref for component
    const refCamera = ref(null)

    function handleOnCanPlay() {
      refCamera.value?.onCanPlay()
    }

    function handleOnReset() {
      refCamera.value?.onReset()
    }

    function handleFacemode() {
      refCamera.value?.onChangeFacemode()
    }

    function handleOnCanStop() {
      refCamera.value?.onCanStop()
    }

    const updateKit = (value) => {
      selectedKit.value = value;
      if (isBloodPassport.value) {
        bloodSample.value.u_blood_passport_kit_used = value.id;
      } else if (isHgh.value) {
        bloodSample.value.u_blood_kit_used = value.id;
      } else if (isDbs.value) {
        bloodSample.value.u_dbs_kit_used = value.id;
      }
    }

    const bloodSampleRules = computed(() => {
      const localRules = {
        u_reason_why_blood_passport_sample_was_not_collected: {},
        u_reason_why_blood_sample_was_not_obtained: {},
        u_reason_why_dbs_sample_was_not_obtained: {}
      }
      if (bloodSample.value && isBloodPassport.value && !isTrue(bloodSample.value.u_was_a_blood_passport_sample_collected)) {
        localRules.u_reason_why_blood_passport_sample_was_not_collected = {
          required
        }
      }
      if (bloodSample.value && isHgh.value && !isTrue(bloodSample.value.u_was_a_blood_sample_collected)) {
        localRules.u_reason_why_blood_sample_was_not_obtained = {
          required
        }
      }
      if (bloodSample.value && isDbs.value && !isTrue(bloodSample.value.u_was_dbs_sample_collected)) {
        localRules.u_reason_why_dbs_sample_was_not_obtained = {
          required
        }
      }
      return localRules
    })

    const variableRules = computed(() => {
      const localRules = {
        selectedBco: { },
        selectedDco: { },
        selectedBcoDco: { },
        sealedDate: { required, mustBeValidDate: mustBeValidDate() },
        sealedTime: { required, mustBeValidTime: mustBeValidTime() },
        collectionDate: { required, mustBeValidDate: mustBeValidDate() },
        collectionTime: { required, mustBeValidTime: mustBeValidTime() }
      }
      if (isDbs.value) {
        localRules.selectedBcoDco = {
          required
        }
      } else {
        localRules.selectedBco = {
          required
        }
        localRules.selectedDco = {
          required
        }
      }
      return localRules
    })

    const v$ = useVuelidate(bloodSampleRules, { bloodSample })
    const w$ = useVuelidate(variableRules, { selectedBco, selectedDco, selectedBcoDco, sealedDate, sealedTime, collectionDate, collectionTime })


    const close = () => {
      emit('close')
    }

    async function save() {
      v$.value.$validate()
      w$.value.$validate()
      if (v$.value.$invalid || w$.value.$invalid) {
        validationError.value = true
        return;
      }

      let data = toRaw(bloodSample.value)
      await sn_customerservice_blood_samples.save(data)
      await dal.commit()
    }

    return {
      switchColor: '#2e4d23',
      tab: null,
      isLoaded,
      sample,
      bloodSample,
      isBloodPassport,
      isHgh,
      isDbs,
      sampleTitle,
      tubeType,
      numberTubes,
      dcoBcoSignature,
      clearDcoBcoSignature,
      dcoSignature,
      clearDCOSignature,
      options,
      close,
      save,
      isSampleCollected,
      updateNumberTubes,
      selectedNumberTubes,
      collectionDate,
      collectionTime,
      updateCollectionDate,
      updateCollectionTime,
      sealedDate,
      sealedTime,
      updateSealedDate,
      updateSealedTime,
      decode,
      isLoading,
      onResult,
      onLoading,
      refCamera,
      handleOnCanPlay,
      handleOnReset,
      handleFacemode,
      handleOnCanStop,
      modificationsOrIrregularities,
      dcoList,
      selectedBco,
      selectedDco,
      updateBco,
      updateDco,
      updateBcoDco,
      selectedBcoDco,
      bcoList,
      bcoDcoList,
      bloodCollectionOfficalSignature,
      clearBloodCollectionOfficalSignature,
      selectedKit,
      kitOptions,
      getManufacturer,
      updateKit,
      showScanError,
      scanErrorDetails,
      isTrue,
      v$,
      w$,
      validationError
      
    }
  },

};
</script>

<style></style>
