// Only used to give options for selecting the laboratory
import BaseModule from './base_module'
import coreCompany from '../../service-now/tables/core_company'
import api from '../../service-now/api'

class Location extends BaseModule {
    constructor() {
        super(coreCompany.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
        ]
    }

    async fetch() {
        // Get by type: laboratories, venues, manufacturer companies (links to kit) 
        const query = `u_typeINLaboratory,Venue,test_kit_manufacturer`
        const { data } = await api.getTableData(coreCompany.table, query, true)
        return [...data.result]
    }
}

const module = new Location()
export default module