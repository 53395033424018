<template>
  <v-app id="app-container">
    <v-main>
      <div>
        <v-alert color="error" icon="mdi-alert" elevation="2" v-if="!persist">
          <strong>Warning:</strong> Permanent Storage permission is not granted. Data may be lost.
          <v-btn v-if="isOnline" @click="persistPermission" class="float-right">
            Permission
          </v-btn>
          <p>{{ permissionError }}</p>
        </v-alert>
        <v-alert color="error" icon="mdi-alert" elevation="2" v-if="syncError">
          <strong>Error:</strong> Sync failed please try again
          <v-btn v-if="isOnline" @click="sync" class="float-right">
            Sync
          </v-btn>
        </v-alert>
        <v-alert color="info" icon="mdi-alert" elevation="2" v-else-if="changeLogRepositoryCount > 0">
          <strong>Warning:</strong> Unsaved data please sync when online
          <v-badge :content="changeLogRepositoryCount" inline></v-badge>
          <v-btn v-if="isOnline" @click="sync" class="float-right">
            Sync
          </v-btn>
        </v-alert>
      </div>
      <router-view />
    </v-main>
    <PWABadge />
    <sync-overlay />
  </v-app>
</template>

<script>
import { RouterView } from 'vue-router'
import { ref, computed } from 'vue'
import PWABadge from '@/components/PWABadge.vue'
import SyncOverlay from './components/SyncOverlay.vue';
import { useAppStore } from '@/stores/app';
import dal from '@/dal';
import { useOnline } from '@vueuse/core'
export default {
  name: 'TestView',
  components: {
    PWABadge,
    SyncOverlay,
    RouterView
  },
  setup() {
    // reactive array

    const appStore = useAppStore()
    const syncError = computed(() => !appStore.syncComplete && !appStore.isLoading)
    const changeLogRepositoryCount = ref(0)
    const isOnline = useOnline()
    const permissionError = ref('')

    const persist = computed(() => appStore.persist)

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isStandalone = window.navigator.standalone === true;

    // workaround for iOS PWA
    if (isIOS && !isStandalone) {
      appStore.persist = true;
    }

    // check for unsaved data every second
    window.setInterval(async () => {
      changeLogRepositoryCount.value = await dal.changeLogRepository.count('changeLog');
    }, 1000);

    const persistPermission = async () => {
      if (navigator.storage && navigator.storage.persist) {
        appStore.persist = await navigator.storage.persisted();
        if (appStore.persist) {
          console.log('Storage is already persisted');
        } else {
          appStore.persist = await navigator.storage.persist();
          if (appStore.persist) {
            console.log('Persistent storage granted');
          } else {
            console.log('Persistent storage denied');
            permissionError.value = 'Storage permission was NOT granted'
          }
        }
      }
    }

    const sync = async () => {
      await dal.syncAll();
    }

    return {
      persist,
      syncError,
      changeLogRepositoryCount,
      isOnline,
      sync,
      persistPermission,
      permissionError
    }
  }
}
</script>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: left;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 1rem;
  }
}
</style>
