import BaseModule from './base_module'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'
import snChainOfCustody from '../../service-now/tables/sn_customerservice_chain_of_custody'
import snDCOReport from '../../service-now/tables/sn_customerservice_dco_report'
import snDCF from '../../service-now/tables/sn_customerservice_dcf'
import snSessionInstruction from '../../service-now/tables/sn_customerservice_m2m_session_instruction'
import snUnsuccessfulAttemptRecord from '../../service-now/tables/sn_customerservice_unsuccessful_attempt_record'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'
import customer_contact from '@/service-now/tables/customer_contact'
import ds_document from '@/service-now/tables/ds_document'

class TestSession extends BaseModule {
    constructor() {
        super(snTestSession.table)

        this.sync = true

        this.syncOrder = 10 // Make sure this is after test_mission

        this.relationships = [
            {
                table: customer_contact.table,
                field: 'u_athlete'
            },
            { table: ds_document.table, field: 'parentRecordSysId' },
            { table: snChainOfCustody.table, field: 'u_test_session' },
            {
                table: snDCF.table,
                field: 'u_test_session_id'
            },
            {
                table: snDCOReport.table,
                field: 'u_number'
            },
            {
                table: snSessionInstruction.table,
                field: 'u_test_session'
            },
            {
                table: snUnsuccessfulAttemptRecord.table,
                field: 'u_number'
            }
            // TODO: add all child tables here
        ]
    }

    async getByMissionId(missionId) {   
        return await this.repository.db[this.name]
        .where('u_mission_order')
        .equals(missionId)
        .toArray()
    }

    async fetch() {
        const missions = await dal.modules.sn_customerservice_test_mission.getAll()

        return await pagination.paginateData(
            'u_mission_orderIN',
            missions.map((x) => x.sys_id),
            (pageQuery) => api.getTableData(snTestSession.table, `${pageQuery}`, true)
        )
    }
}

const module = new TestSession()
export default module
