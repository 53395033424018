import HttpError from './HttpError.js'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app.js'

/**
 * Retry a method on failure and test check passes
 * @param {*} action method to retry
 * @param {*} test check to try again
 * @param {*} retries number of retries before giving up
 * @returns
 */
function retry(action, test, retries = 1) {
    return action().catch(async (e) => {
        if (retries > 0 && (await test(e))) {
            return retry(action, test, retries - 1)
        }
        throw e
    })
}

/**
 * Fetch that throws HttpErrors and attempts to refresh token on 401
 * @param {*} url
 * @param {*} properties
 * @returns
 */
async function fetchWithRetry(url, properties) {
    try {
        return await retry(
            async () => {
                const userStore = useUserStore()
                const appStore = useAppStore()

                properties.headers = properties.headers || {}

                // update accessToken
                properties.headers['Authorization'] = `Bearer ${userStore.accessToken}`
                properties.headers['X-CSRF-TOKEN'] = appStore.csrfToken

                var response = await fetch(url, properties)

                if (!response.ok) {
                    const content = await response.text()
                    throw new HttpError(
                        `HTTP error! status: ${response.status} ${content}`,
                        response.status,
                        response
                    )
                }

                return response
            },
            async (e) => {
                if (e instanceof HttpError && e.code == 401) {
                    // probably expired token attempt refresh
                    const userStore = useUserStore()
                    try {
                        await userStore.fetchToken()
                        return true
                    } catch (e) {
                        console.warn('Failed to refresh token', e)
                        return false
                    }
                }
                return false
            },
            1 // retry once only
        )
    } catch (e) {
        // still 401 after refresh attempt, logout user
        if (e instanceof HttpError && e.code == 401) {
            // logout user
            const userStore = useUserStore()
            userStore.logout()
            window.location.reload()
        }
        throw e
    }
}

export { fetchWithRetry as fetch }
