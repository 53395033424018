class HttpError {
    constructor(message, code, request) {
        this.message = message
        this.name = 'HttpError'
        this.code = code
        this.request = request
    }
}

export default HttpError
