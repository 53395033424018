<template>
   <v-row>
      <v-col cols="12">
         <v-main>
            <div v-if="dcf" style="min-height: 100vh">
               <v-sheet class="si-form-sheet text-body-2 mx-auto">
                  <v-container fluid>
                     <v-row>
                        <v-col cols="12" md="12">
                           <h2>Notification</h2>
                           <DividerWithIcons />
                           <p class="font-weight-bold font-italic">Notification</p>
                           <p>
                              You have been selected for doping control and you are required to comply
                              with sample collection. Please be advised that failure to comply or refusal
                              to provide a sample may result in an anti-doping rule violation.
                           </p>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="12" md="5" class="si-form-inline">
                           <span class="font-italic si-left-label">Notified by</span>
                           <v-combobox v-model="notifiedBy" label="" density="compact" bg-color="white"
                              :items="notifiedByOptions" item-title="name" item-value="id"
                              @update:model-value="updateNotifiedBy"
                              :error-messages="w$.notifiedBy.$errors.map(e => e.$message)" @blur="w$.notifiedBy.$touch"></v-combobox>
                        </v-col>
                        <v-col cols="12" md="7" class="si-form-inline">
                           <span class="font-italic si-left-label">Notified at</span>
                           <v-text-field v-model="notificationDate" label="" density="compact" bg-color="white" required
                              @update:model-value="updateNotificationDate" type="date"
                              :error-messages="w$.notificationDate.$errors.map(e => e.$message)" @blur="w$.notificationDate.$touch">
                           </v-text-field> 
                           <v-text-field v-model="notificationTime" label="" density="compact" bg-color="white" required
                              @update:model-value="updateNotificationTime" type="time"
                              :error-messages="w$.notificationTime.$errors.map(e => e.$message)" @blur="w$.notificationTime.$touch">
                           </v-text-field>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="font-weight-bold font-italic">Notification type</p>
                        </v-col>
                        <v-col cols="12" md="12" class="si-form-inline">
                           <p>
                              Was the athlete aware of their selection before being under observation by
                              SCP?
                           </p>
                           <v-checkbox v-model="dcf.u_was_the_athlete_aware_of_their_selection_before_being_under_observation_by_scp" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                        <v-col cols="12" md="12" class="si-form-inline" v-if="isTrue(dcf.u_was_the_athlete_aware_of_their_selection_before_being_under_observation_by_scp)">
                           <v-textarea v-model="dcf.u_advance_notice_reason" :required="safeguardCheckbox"
                              :error-messages="v$.u_advance_notice_reason.$errors.map((e) => e.$message)"
                              @blur="v$.u_advance_notice_reason.$touch" label="Reason for advance notice"
                              density="compact" bg-color="white">
                           </v-textarea>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="font-weight-bold font-italic">Safeguard modifications</p>
                        </v-col>
                        <v-col cols="12" md="12" class="si-form-inline">
                           <p>
                              Safeguarding modifications apply to athletes who are minors, visually or
                              intellectually impaired.<br />
                              Does this athlete require modifications in place to safeguard their
                              wellbeing?
                           </p>
                           <v-checkbox v-model="dcf.u_does_this_athlete_require_modifications_in_place_to_safeguard_their_wellbeing" 
                              true-value="true" false-value="false" density="compact" bg-color="white"
                              label=""></v-checkbox>
                        </v-col>

                        <v-col cols="12" md="12" class="si-form-inline">
                           <p>
                              Were there any modifications or irregularities to standard notification
                              procedures?
                           </p>
                           <v-checkbox v-model="dcf.u_were_any_other_modifications_made_when_notifying_the_athlete"
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                     </v-row>
                     <v-row style="">
                        <v-col cols="6" md="3" class="si-form" style="padding-top: 10px !important;">
                           Name of second adult present at notification:
                        </v-col>
                        <v-col cols="6" md="3" class="si-form">
                           <v-text-field v-model="dcf.u_name_of_second_adult_present_at_notification" label=""
                              density="compact" bg-color="white" type="text">
                           </v-text-field>
                        </v-col>
                     </v-row>
                     <v-row style="">
                        <v-col cols="6" md="3" class="si-form" style="padding-top: 10px !important;">
                           Role of second adult present:
                        </v-col>
                        <v-col cols="6" md="3" class="si-form">
                           <v-text-field v-model="dcf.u_role_of_second_adult_present" label=""
                              density="compact" bg-color="white" type="text">
                           </v-text-field>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="font-weight-bold font-italic">Failure to comply warning</p>
                        </v-col>
                        <v-col cols="12" md="12" class="si-form-inline">
                           <p>
                              You have been selected for doping control and you are required to comply with sample collection.
                              Please be aware that failure to comply or refusal to provide a sample may result in an anti-doping rule violation.
                           </p>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="font-weight-bold font-italic">Notification Comments</p>
                        </v-col>
                        <v-col cols="12" md="12" class="si-form-inline" >
                           <v-textarea v-model="dcf.u_notification_comments" class="no-label-textarea"
                           :required="isTrue(dcf.u_does_this_athlete_require_modifications_in_place_to_safeguard_their_wellbeing)
                           || isTrue(dcf.u_were_any_other_modifications_made_when_notifying_the_athlete)"
                           :error-messages="v$.u_notification_comments.$errors.map( (e) => e.$message)" 
                           @blur="v$.u_notification_comments.$touch"
                           label="" density="compact" bg-color="white">
                           </v-textarea>
                        </v-col>
                     </v-row>
                  </v-container>
               </v-sheet>


               <v-sheet class="si-form-sheet text-body-2 mx-auto">
                  <v-container fluid>
                     <v-row>
                        <v-col cols="12" md="12">
                           <h2>Athlete rights and responsibilities</h2>
                           <DividerWithIcons />
                           <p class="">As an athlete, you also….</p>
                           <br>
                           <v-btn @click="showAthletePolicy = true" variant="outlined">
                              View Athlete Privacy Policy
                           </v-btn>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row style="align-items: center">
                        <v-col cols="6" md="6" class="si-form-signature">
                           <span class="font-italic si-left-label">Athlete Signature </span>
                           <div class="si-signature-field">
                              <vue-signature-pad ref="athleteSignature" :max-width="options.maxWidth"
                                 :min-width="options.minWidth" height="300px" :options="{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor
                                 }" />
                           </div>
                        </v-col>
                        <v-col cols="6" md="6">
                           <v-btn @click="clearAthleteSignature" class="si-right-action-button" size="large">
                              <v-icon icon="mdi-undo"></v-icon>
                           </v-btn>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row>
                        <v-col cols="12" md="12">
                           <p class="">
                              The athlete was notified according to standard procedures with any
                              modifications or irregularities noted.
                           </p>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                     <v-row style="align-items: center">
                        <v-col cols="6" md="6" class="si-form-signature">
                           <span class="font-italic si-left-label">Notifying SCP Signature </span>
                           <div class="si-signature-field">
                              <vue-signature-pad ref="scpSignature" :max-width="options.maxWidth"
                                 :min-width="options.minWidth" height="300px" :options="{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor
                                 }" />
                           </div>
                        </v-col>
                        <v-col cols="6" md="6">
                           <v-btn @click="clearScpSignature" class="si-right-action-button" size="large">
                              <v-icon icon="mdi-undo"></v-icon>
                           </v-btn>
                        </v-col>
                     </v-row>
                     <div class="si-form-gap"></div>
                  </v-container>
               </v-sheet>

               <v-container class="pa-0" fluid>
                  <v-row class="d-flex justify-end">
                     <v-col cols="12" md="3" class="d-flex">
                        <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                           variant="tonal">
                           Save
                        </v-btn>
                     </v-col>
                  </v-row>
               </v-container>
            </div>
         </v-main>
      </v-col>
   </v-row>
   <v-snackbar v-model="validationError">
      Please fill in all required fields.

      <template v-slot:actions>
         <v-btn color="red" variant="text" @click="validationError = false"> Close </v-btn>
      </template>
   </v-snackbar>
   
   <v-dialog v-model="showAthletePolicy" transition="dialog-bottom-transition">
      <v-card title="Athlete Privacy Policy" style="margin-left: auto;
        margin-right: auto;
        min-width: 80vw;">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" class="si-athlete-policy">
               <p>I understand and agree that:</p>
               <p>
                  <ol>
                     <li>
                           A sample is biological material collected for the purposes of Doping Control. Doping Control means all steps and processes from test distribution planning through to ultimate disposition of any appeal including all steps and processes in between such as provision of whereabouts information, Sample Collection and handling, laboratory analysis, therapeutic use exemptions, results management and hearings.
                     </li>
                     <li>
                           My sample may be analysed to detect Prohibited Substances and Prohibited Methods identified on the Prohibited List, and other substances as may be directed by WADA pursuant to Article 4.5 of the World Anti-Doping Code, or to assist the authorised Anti-Doping Organisation in profiling relevant parameters in my urine, blood or other matrix, including DNA or genomic profiling, or for any other legitimate anti-doping purpose.
                     </li>
                     <li>
                           The authorised Anti-Doping Organisation will receive: the analytical results of my Sample Collection; any reports from my doping control officer indicating my possible refusal or failure to submit to Sample Collection including providing the required information; other information relating to any possible anti-doping rule violation and other documentation from my sample collection.
                     </li>
                     <li>
                           The authorised Anti-Doping Organisation may collect, store, process, or disclose information related to doping control, including but not limited to, laboratory results and possible sanctions, where necessary and appropriate to conduct its anti-doping activities under the Sports Anti-Doping Rules and the World Anti-Doping Code. The information may be shared with WADA, other Anti-Doping Organisations, International Federations, National Governing Bodies of Sports, the New Zealand Olympic Committee or Paralympics New Zealand and any other relevant bodies for anti-doping purposes. Disclosures may be outside New Zealand. The information may be provided to other agencies if required by law.
                     </li>
                     <li>
                           I have rights of access to and correction of personal information as provided by the Privacy Act 2020. I also have rights with respect of the information as provided under the International Standards for the Protection of Privacy and Personal Information (ISPPPI) including the procedure for submitting complaints pursuant to Article 11.5. Any compliant should be submitted to privacy@drugfreesport.org.nz.
                     </li>
                     <li>
                           Any such collection, storage, processing or disclosure will comply with the Sports Anti-Doping Rules (as applicable), the World Anti-Doping Code (including the International Standard for Testing and Investigations and the International Standard for the Protection of Privacy and Personal Information) and the applicable law (including the Privacy Act 2020).
                     </li>
                     <li>
                           I acknowledge that I have had the opportunity to read the Athlete Privacy Policy - <a href="https://drugfreesport.org.nz/athlete-privacy-policy" target="_blank">Athlete Privacy Policy | Drug Free Sport New Zealand</a>.
                     </li>
                  </ol>
               </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Close" variant="plain" @click="showAthletePolicy = false"></v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import { useMissionStore } from '@/stores/mission'
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'

export default {
   name: 'DcfNotification',
   components: {
      DividerWithIcons,
      VueSignaturePad,
   },
   props: {
      dcfId: {
         type: String,
         required: true
      },
      missionId: {
         type: String,
         required: true
      }
   },
   setup(props) {
      const dcf = ref({})
      const missionStore = useMissionStore()
      const scpSignature = ref(null)
      const athleteSignature = ref(null)
      const notifiedBy = ref(null)
      const validationError = ref(false)
      const showAthletePolicy = ref(false)
      const notificationTime = ref(null)
      const notificationDate = ref(null)
      const notifiedByOptions = ref([])

      const options = ref({
         penColor: 'rgb(0,0,0)',
         backgroundColor: 'rgb(255, 255, 255)',
         maxWidth: 2,
         minWidth: 2
      })

      onMounted(async () => {
         dcf.value = await sn_customerservice_dcf.get(props.dcfId)

         if (dcf.value.u_notification_date) {
            notificationDate.value = dateTimePickerInitialise('date', dcf.value.u_notification_date)
            notificationTime.value = dateTimePickerInitialise('time', dcf.value.u_notification_date)
         }

         await missionStore.loadMission(props.missionId)
         notifiedByOptions.value = missionStore.notificationList

         if (dcf.value.u_notifying_chaperone && dcf.value.u_notifying_chaperone !== '') {
            notifiedBy.value = notifiedByOptions.value.find(x => x.id === dcf.value.u_notifying_chaperone)
         }
      })

      const clearScpSignature = () => {
         if (scpSignature.value) {
            scpSignature.value.clearCanvas()
         }
      }

      const clearAthleteSignature = () => {
         if (athleteSignature.value) {
            athleteSignature.value.clearCanvas()
         }
      }

      const updateNotifiedBy = (value) => {
         dcf.value.u_notifying_chaperone = value.id
      }

      const updateNotificationDate = (value) => {
         dcf.value.u_notification_date = dateTimePickerUpdate('date', value, dcf.value.u_notification_date)
      }

      const updateNotificationTime = (value) => {
         dcf.value.u_notification_date = dateTimePickerUpdate('time', value, dcf.value.u_notification_date)
      }

      const dcfRules = computed(() => {
         const localRules = {
            u_advance_notice_reason: {},  
            u_were_any_other_modifications_made_when_notifying_the_athlete: { }, 
             u_notification_comments: {}
         }
         // if (isTrue(dcf.value.u_were_any_other_modifications_made_when_notifying_the_athlete)) {
         //    localRules.u_if_safeguard_yes_please_enter_a_comment_if_there_is_no_athlete_representative_ = {
         //       required
         //    }
         // }
         if (isTrue(dcf.value.u_was_the_athlete_aware_of_their_selection_before_being_under_observation_by_scp)) {
            localRules.u_advance_notice_reason = {
               required
            }
         }
         if (isTrue(dcf.value.u_does_this_athlete_require_modifications_in_place_to_safeguard_their_wellbeing)
             || isTrue(dcf.value.u_were_any_other_modifications_made_when_notifying_the_athlete)) {
            localRules.u_notification_comments = { 
               required: helpers.withMessage('Please enter comments describing modifications made', required)
             }
         }
         return localRules
      })

      const variableRules = {
        notifiedBy: { required },
        notificationDate: { required, mustBeValidDate: mustBeValidDate() },
        notificationTime: { required, mustBeValidTime: mustBeValidTime() }
      }

      const v$ = useVuelidate(dcfRules, dcf)
      const w$ = useVuelidate(variableRules, { notifiedBy, notificationDate, notificationTime })

      const save = async () => {
         try {
            await v$.value.$validate()
            await w$.value.$validate()
            if (v$.value.$invalid || w$.value.$invalid) {
               validationError.value = true
               return
            }
            let data = toRaw(dcf.value)
            await sn_customerservice_dcf.save(data)
            await dal.commit()
         } catch (error) {
            console.error('Error saving:', error)
         }
      }

      return {
         tab: null,
         loading: false,
         dcf,
         notifiedByOptions,
         scpSignature,
         athleteSignature,
         clearScpSignature,
         clearAthleteSignature,
         options,
         notifiedBy,
         updateNotifiedBy,
         notificationTime,
         notificationDate,
         updateNotificationDate,
         updateNotificationTime,
         save,
         isTrue,
         validationError,
         showAthletePolicy,
         w$,
         v$
      }
   }
}
</script>

<style></style>
