import BaseModule from './base_module'
import cmnLocation from '../../service-now/tables/cmn_location'
import sysUser from '../../service-now/tables/sys_user'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'

class Location extends BaseModule {
    constructor() {
        super(cmnLocation.table)

        this.sync = true

        this.syncOrder = 1

        this.relationships = [
            {
                table: sysUser.table,
                field: 'contact'
            },
            {
                table: cmnLocation.table,
                field: 'parent'
            }
        ]
    }

    async fetch() {
        // Get all the locations that are associated with the loaded companies.
        const coreCompany = await dal.modules.core_company.getAll()

        // TODO: also need other locations that are not associated with the loaded companies 
        // e.g. missing location: "11ed02911bf255100b5d54a9274bcb24" on UAR report

        return await pagination.paginateData(
            'companyIN',
            coreCompany.map((x) => x.sys_id),
            (pageQuery) => api.getTableData(cmnLocation.table, `${pageQuery}`, true)
        )
    }
}

const module = new Location()
export default module
