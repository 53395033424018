import BaseRepository from '@/core/dal/base-repository'
import Dexie from 'dexie'

function applySortFunction(results, skip, take, sortFunc) {
    var total = results.length

    if (results && sortFunc) {
        results.sort(sortFunc)
    }

    // Paging
    if (skip || take) {
        if (skip === undefined || take === null) {
            skip = 0
        }
        if (take === undefined || take === null) {
            take = results.length
        }
        results = results.slice(skip, skip + take)
    }

    results.totalCount = total
    return results
}

class Repository extends BaseRepository {
    constructor(dbName, schema) {
        super()

        this.dbName = dbName
        this.schema = schema
        this.db = null
    }

    async open() {
        this.db = new Dexie(this.dbName)

        // Apply schema changes
        for (var key in this.schema) {
            var s = this.schema[key]
            console.debug(`Database Schema => ${s.version}`)
            await this.db.version(s.version).stores(s.changes).upgrade()
            console.debug(`Database Schema Upgraded => ${s.version}`)
        }

        try {
            await this.db.open()
        } catch (e) {
            console.error(`Database Open Error => ${e}`)
        }

        return 'database opened'
    }

    async checkTableExists(name) {
        if (!this.db[name]) {
            console.debug(`Database Table does not exist => ${name}`)
            throw new Error(`Database Table does not exist => ${name}`)
        }
    }

    async getAll(name, skip, take) {
        this.checkTableExists(name)
        var result = await this.db[name]

        if (skip) result = result.offset(skip)
        if (take) result = result.limit(take)

        return result.toArray()
    }

    async getAllByKey(name) {
        this.checkTableExists(name)
        var result = {}

        await this.db[name].toCollection().each(function (item, cursor) {
            result[cursor.primaryKey] = item
        })

        return result
    }

    getByKey(tableName, keys, indexName) {
        this.checkTableExists(tableName)
        if (indexName) return this.getByIndex(tableName, indexName, false, keys)

        return this.getByIndex(tableName, this.db[tableName].schema.primKey.keyPath, true, keys)
    }

    get(name, id) {
        this.checkTableExists(name)
        return this.db[name].get(id)
    }

    async getByIndex(tableName, indexName, unique, keys) {
        this.checkTableExists(tableName)
        var result = {}

        await this.db[tableName]
            .where(indexName)
            .anyOf(keys)
            .each(function (item) {
                var mappingKey = Array.isArray(item[indexName])
                    ? item[indexName].join(',')
                    : item[indexName]

                if (!unique) {
                    if (!result[mappingKey]) result[mappingKey] = []
                    result[mappingKey].push(item)
                } else {
                    result[mappingKey] = item
                }
            })

        return result
    }

    getAllKeys(name) {
        this.checkTableExists(name)
        return this.db[name].toCollection().primaryKeys()
    }

    async select(tableName, indexName, value, skip, take) {
        this.checkTableExists(tableName)
        var result = this.db[tableName].where(indexName).equals(value)

        var count = await result.count()

        if (skip) result = result.offset(skip)
        if (take) result = result.limit(take)

        var a = await result.toArray()
        a.totalCount = count

        return a
    }
    async selectFirst(tableName, indexName, value) {
        const data = await this.select(tableName, indexName, value, 0, 1)
        if (data && data.length > 0) return data[0]
        return null
    }
    async selectFunction(tableName, indexName, value, offset, take, sortFunc) {
        this.checkTableExists(tableName)

        var results = await this.select(tableName, indexName, value)

        applySortFunction(results, offset, take, sortFunc)

        return results
    }
    async search(tableName, indexName, value, offset, take) {
        this.checkTableExists(tableName)
        var result = this.db[tableName].where(indexName).startsWithIgnoreCase(value)

        var count = await result.count()

        if (offset) result = result.offset(offset)
        if (take) result = result.limit(take)

        var a = await result.toArray()
        a.totalCount = count

        return a
    }
    async searchSort(tableName, indexName, value, offset, take, sortFunc) {
        this.checkTableExists(tableName)
        var result = this.db[tableName].where(indexName).startsWithIgnoreCase(value).distinct()

        var count = await result.count()

        result = await result.sortBy(sortFunc)

        if (offset) result = await result.offset(offset)
        if (take) result = await result.limit(take)

        var a = await result.toArray()

        a.totalCount = count

        return a
    }
    async searchFunction(tableName, searchFunc, value, skip, take, sortFunc) {
        this.checkTableExists(tableName)

        var results = await this.getAll(tableName)

        // Apply search filter
        if (searchFunc) {
            results = results.filter(function (item) {
                return searchFunc(item, value)
            })
        }

        if (sortFunc) {
            applySortFunction(results, skip, take, sortFunc)
        }

        return results
    }
    async searchFunctionCount(tableName, searchFunc, value) {
        this.checkTableExists(tableName)
        const results = await this.searchFunction(tableName, searchFunc, value, 0, 1, null)
        return results.totalCount
    }

    selectKeys(tableName, indexName, value) {
        this.checkTableExists(tableName)
        return this.db[tableName].where(indexName).equals(value).primaryKeys()
    }

    put(name, data) {
        this.checkTableExists(name)
        return this.db[name].put(data)
    }

    async putAll(name, data) {
        this.checkTableExists(name)
        await this.db[name].clear()
        return this.db[name].bulkPut(data)
    }

    async putArray(name, data) {
        this.checkTableExists(name)
        return this.db[name].bulkPut(data)
    }

    delete(name, keys) {
        if (!Array.isArray(keys)) keys = [keys]
        return this.db[name].bulkDelete(keys)
    }

    count(name, index, value) {
        this.checkTableExists(name)
        if (index) {
            const q = this.db[name].where(index).equals(value)
            return q.count()
        }
        return this.db[name].count()
    }

    getPrimaryKey(name) {
        this.checkTableExists(name)
        return this.db[name].schema.primKey.keyPath
    }

    clear(name) {
        this.checkTableExists(name)
        return this.db[name].clear()
    }

    async commit() {
        // push changes to permanent storage
        return true
    }

    // do post for server communication
    post(url, data) {
        if (!navigator.onLine) return Promise.resolve(null)

        return window.App.post(url, data)
    }

    /// OVERRIDE FOR SYNC
    putChanges(tableName, key, data) {
        return this.put(tableName, data)
    }

    putChangeLog(changeLog) {
        this.checkTableExists('changeLog')
        return this.db.transaction('rw', this.db.changeLog, async () => {
            if (changeLog.type === 'u' || changeLog.type === 'update') {
                var previousChangeLogs = await this.db.changeLog.reverse().limit(1).toArray()

                if (previousChangeLogs.length > 0) {
                    var previousChangeLog = previousChangeLogs[0]

                    // if the previous change log is the same as the current change log update instead of adding
                    if (
                        previousChangeLog &&
                        ['u', 'update', 'i', 'insert'].includes(previousChangeLog.type) &&
                        previousChangeLog.table === changeLog.table &&
                        previousChangeLog.key === changeLog.key
                    ) {
                        previousChangeLog.data = { ...previousChangeLog.data, ...changeLog.data }

                        previousChangeLog.relationships = Array.from(
                            new Set([
                                ...changeLog.relationships,
                                ...previousChangeLog.relationships
                            ])
                        )

                        previousChangeLog.updatedon = changeLog.createdon

                        return this.db.changeLog.put(previousChangeLog)
                    }
                }
            }

            return this.db.changeLog.put(changeLog)
        })
    }
}

export default Repository
