import BaseModule from './base_module'
import snOffered from '../../service-now/tables/sn_customerservice_offered'
import api from '../../service-now/api'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import { useUserStore } from '@/stores/user'

class OfferedMissions extends BaseModule {
    constructor() {
        super(snOffered.table)
        this.sync = true
        this.syncOrder = 0 // Before test missions
        this.relationships = [
            {
                table: snTestMission.table,
                field: 'u_offered_task'
            }
        ]
    }

    async fetch() {
        const userStore = useUserStore()
        const userId = userStore.user_sys_id
        const query = `u_offered_to=${userId}`
        const { data } = await api.getTableData(snOffered.table, query)

        return data.result
    }
}

const module = new OfferedMissions()
export default module
