export default [
    {
        version: 1.0,
        changes: {
            alm_hardware: 'id, sys_id, model',
            attachment: 'id, sys_id, table_name, table_sys_id',
            cmdb_model: 'id, sys_id, manufacturer, cmdb_model_category',
            cmdb_model_category: 'id, sys_id, name',
            cmn_location: 'id, sys_id, name, parent, company',
            core_company: 'id, sys_id, contact, parent, name, u_type',
            core_country: 'id, sys_id, name, active',
            customer_contact: 'id, sys_id, sys_updated_on, active',
            ds_document_references: 'id, sys_id, document, target_record',
            ds_document: 'id, sys_id, sys_updated_on, parentRecordSysId',
            ds_document_version: 'id, sys_id, document',
            sn_customerservice_blood_samples:
                'id, sys_id, u_blood_passport_kit_used, u_blood_manufacturer, u_blood_kit_used, u_bco_name_blood_passport, u_blood_dco_name, u_dcf_number, u_manufacturer_blood_passport, u_dbs_kit_used, u_dco_name_blood_passport, u_dbs_manufacturer, u_blood_sample_type, [u_dcf_number+u_blood_sample_type] ',
            sn_customerservice_chain_of_custody:
                'id, sys_id, sys_updated_on, u_number, u_assigned_dco, u_test_session, u_test_mission',
            sn_customerservice_dcf:
                'id, sys_id, sys_updated_on, u_number, u_athlete, u_test_session_id, sys_updated_by, [u_test_session_id+u_athlete]',
            sn_customerservice_dco_report:
                'id, sys_id, sys_updated_on, u_number, u_athlete, sys_created_on, sys_updated_by, u_dco_name',
            sn_customerservice_declaration_of_medications_supplements: 'id, sys_id, u_dcf_number',
            sn_customerservice_discipline: 'id, sys_id, u_name, u_linked_if, u_sport, u_linked_nso',
            sn_customerservice_m2m_mission_instruction:
                'id, sys_id, sys_updated_on, u_test_mission, u_mission_instruction',
            sn_customerservice_m2m_session_instruction:
                'id, sys_id, u_test_session, u_special_instruction',
            sn_customerservice_nationality: 'id, sys_id',
            sn_customerservice_offered: 'id, sys_id, u_offer, u_offered_to',
            sn_customerservice_pftc:
                'id, sys_id, u_sport, u_mission_order, u_athlete_name, u_number, u_test_session, u_location',
            sn_customerservice_sample:
                'id, sys_id, sys_updated_on, u_full_sample_code, u_test_session_id, sys_created_on, u_status, u_assigned_to',
            sn_customerservice_sports: 'id, sys_id, u_name, u_responsible',
            sn_customerservice_supp_report:
                'id, sys_id, sys_updated_on, u_number, u_athlete_name, sys_updated_by, u_author, sys_created_on',
            sn_customerservice_test_mission:
                'id, sys_id, number, u_offered_chaperones, u_assigned_chaperones, u_offered_dcos, u_offered_bcos, u_assigned_bcos, u_assigned_support_dcos, u_assigned_lead_dco, u_offered_task, u_nso, u_blood_screens_required, u_urine_screens_required, u_soc, u_results_management_authority, u_venue, u_lab, u_testing_authority, u_dco, u_sample_collection_authority, u_sport, u_discipline',
            sn_customerservice_test_session:
                'id, sys_id, sys_updated_on, u_mission_order, number, sys_created_on, assigned_to, u_test_session',
            sn_customerservice_unsuccessful_attempt_record:
                'id, sys_id, sys_updated_on, u_number, sys_created_on',
            sn_customerservice_urine_samples:
                'id, sys_id, u_urine_manufacturer, u_urine_kit_used, u_partial_sample_dco, u_partial_sample_code, u_partial_sample_manufacturer, u_dcf_number',
            sn_customerservice_wsp_choice: 'id, sys_id',
            sys_choice:
                'id, sys_id, sys_domain_path, name, sys_domain, language, element, sequence, label, inactive,[name+element]',
            sys_user_group: 'id, sys_id, manager, parent, name, u_sports',
            sys_user: 'id, sys_id, sys_updated_on, u_number, u_user_type, name, sys_created_on',
            u_red_flag: 'id, sys_id, u_athlete, u_location'
        }
    }
]
