<template>
  <v-text-field v-model="selectedDate" :error-messages="errors" label="" density="compact" bg-color="white"
    @update:model-value="updateDate" type="date">
  </v-text-field>
</template>

<script setup>
import { ref, watch } from 'vue'
import moment from 'moment/moment';

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  errorMessages: {
    type: Array,
    default: () => [],
  },
  validationError: {
    type: Object,
    default: null,
  },
})
const errors = ref(props.errorMessages);
const selectedDate = ref(props.modelValue);
const emit = defineEmits(['update:modelValue'])
const dateFormat = 'YYYY-MM-DD';

const updateDate = (value) => {
  let currentNotificationDate = moment(selectedDate.value);
  const newDate = moment(value, dateFormat);
  currentNotificationDate.year(newDate.year());
  currentNotificationDate.month(newDate.month());
  currentNotificationDate.date(newDate.date());
  selectedDate.value = currentNotificationDate.format(dateFormat);
}

const resolveErrors = () => {
  errors.value = props.validationError?.$errors.map(e => e.$message);
}
if (props.validationError) {
  resolveErrors();
}
// watch for changes in errors
watch(props.validationError, resolveErrors);
watch(props.errorMessages, () => {
  errors.value = props.errorMessages;
});

// set initial date from props
updateDate(props.modelValue);

// watch for changes in selected date
watch(selectedDate, (newDate) => {
  emit("update:modelValue", newDate);
});

</script>
