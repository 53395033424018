import dal from './dal.js'

import almHardware from './modules/alm_hardware.js'
import attachment from './modules/attachment.js'
import cmdbModel from './modules/cmdb_model.js'
import cmdbModelCategory from './modules/cmdb_model_category.js'
import cmnLocation from './modules/cmn_location.js'
import coreCompany from './modules/core_company.js'
import coreCountry from './modules/core_country.js'
import customerContact from './modules/customer_contact.js'
import documentReference from './modules/ds_document_references.js'
import document from './modules/ds_document.js'
import documentVersion from './modules/ds_document_version.js'
import bloodSamples from './modules/sn_customerservice_blood_samples.js'
import chainOfCustody from './modules/sn_customerservice_chain_of_custody.js'
import dcf from './modules/sn_customerservice_dcf.js'
import dcoReport from './modules/sn_customerservice_dco_report.js'
import declarationOfMedicationsOrSupplements from './modules/sn_customerservice_declaration_of_medications_supplements.js'
import discipline from './modules/sn_customerservice_discipline.js'
import missionInstruction from './modules/sn_customerservice_m2m_mission_instruction.js'
import sessionInstruction from './modules/sn_customerservice_m2m_session_instruction.js'
import nationality from './modules/sn_customerservice_nationality.js'
import offered from './modules/sn_customerservice_offered.js'
import pftc from './modules/sn_customerservice_pftc.js'
import sample from './modules/sn_customerservice_sample.js'
import sport from './modules/sn_customerservice_sports.js'
import suppReport from './modules/sn_customerservice_supp_report.js'
import testMission from './modules/sn_customerservice_test_mission.js'
import testSession from './modules/sn_customerservice_test_session.js'
import unsuccessfulAttemptRecord from './modules/sn_customerservice_unsuccessful_attempt_record.js'
import urineSamples from './modules/sn_customerservice_urine_samples.js'
import wspChoice from './modules/sn_customerservice_wsp_choice.js'
import sysChoice from './modules/sys_choice.js'
import sysUserGroup from './modules/sys_user_group.js'
import sysUser from './modules/sys_user.js'
import uRedFlag from './modules/u_red_flag.js'

dal.registerModule(almHardware)
dal.registerModule(attachment)
dal.registerModule(cmdbModel)
dal.registerModule(cmdbModelCategory)
dal.registerModule(cmnLocation)
dal.registerModule(coreCompany)
dal.registerModule(coreCountry)
dal.registerModule(customerContact)
dal.registerModule(documentReference)
dal.registerModule(document)
dal.registerModule(documentVersion)
dal.registerModule(bloodSamples)
dal.registerModule(chainOfCustody)
dal.registerModule(dcf)
dal.registerModule(dcoReport)
dal.registerModule(declarationOfMedicationsOrSupplements)
dal.registerModule(discipline)
dal.registerModule(missionInstruction)
dal.registerModule(sessionInstruction)
dal.registerModule(nationality)
dal.registerModule(offered)
dal.registerModule(pftc)
dal.registerModule(sample)
dal.registerModule(sport)
dal.registerModule(suppReport)
dal.registerModule(testMission)
dal.registerModule(testSession)
dal.registerModule(unsuccessfulAttemptRecord)
dal.registerModule(urineSamples)
dal.registerModule(wspChoice)
dal.registerModule(sysChoice)
dal.registerModule(sysUserGroup)
dal.registerModule(sysUser)
dal.registerModule(uRedFlag)

// map child tables back to parent tables
for (const module of dal.getModules()) {
    if (module.relationships) {
        for (const relationship of module.relationships) {
            const childModule = dal.getModule(relationship.table)
            if (childModule) {
                if (!childModule.parentRelationships) {
                    childModule.parentRelationships = []
                }
                childModule.parentRelationships.push({
                    table: module.name,
                    field: relationship.field
                })
            }
        }
    }
}

export default dal
