import { defineStore } from 'pinia'
import serviceNow from '@/service-now/index.js'

export const useAppStore = defineStore('app', {
    state: () => ({
        syncComplete: true,
        syncStatus: '',
        isLocked: false,
        isLoading: false,
        persist: false,
        csrfToken: ''
    }),
    actions: {
        async setSyncStatus(syncStatus) {
            this.syncComplete = false
            this.syncStatus = syncStatus
        },

        async setSyncComplete() {
            this.syncComplete = true
            this.syncStatus = ''
        },

        async setLoading(loading) {
            this.isLoading = loading
        },

        setLockState(locked) {
            this.isLocked = locked
        },

        setPersist(persist) {
            this.persist = persist
        },

        setCsrfToken(token) {
            this.csrfToken = token
        },

        async fetchToken() {
            let token = await serviceNow.fetchCsrfToken(this.refreshToken)
            this.setCsrfToken(token)
        }
    },
    persist: true
})
