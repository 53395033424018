import {v4 as uuid} from 'uuid'
class Repository {
  constructor() {
    this.modules = {}
  }

  registerModule(module) {
    this.modules[module.name] = module
    if (module.register) module.register(this)
  }

  getModule(name) {
    return this.getModules().find(x => x.name === name)
  }

  getModules() {
    var a = []
    for (var module in this.modules) {
      a.push(this.modules[module])
    }
    return a
  }
  getModuleNames() {
    var a = []
    for (var module in this.modules) {
      a.push(module)
    }
    return a
  }

  clone(obj) {
    var newObject = Object.assign({}, obj) // TODO: change to json stringify / parse?
    newObject.Id = uuid() // new id or else sync will merge objects
    return newObject
  }
}

export default Repository
