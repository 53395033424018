// Only used for Athlete is a field choices to indicate if they are a Para-athlete or a Minor
import BaseModule from './base_module'
import wspChoice from '../../service-now/tables/sn_customerservice_wsp_choice'
import api from '../../service-now/api'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'

class WSPChoice extends BaseModule {
    constructor() {
        super(wspChoice.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: snTestSession.table,
                field: 'u_athlete_is'
            }
        ]
    }

    async fetch() {
        const query = `u_elementINu_athlete_is`
        const { data } = await api.getTableData(wspChoice.table, query, true)
        return [...data.result]
    }
}

const module = new WSPChoice()
export default module
