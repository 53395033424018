<template>

    <v-container v-if="isLoading" fluid class="primary fill-height si-loading">

        <v-card class="mx-auto my-8 user-select-none" elevation="16" min-width="33vw">
            <v-card-item class="justify-center">
                <v-card-title style="margin-bottom:10px;">
                    Syncing Data...
                </v-card-title>

                <v-card-subtitle class="justify-center text-md-center">
                    <v-progress-circular indeterminate :size="40" :width="6" color="#758f01"></v-progress-circular>
                </v-card-subtitle>
            </v-card-item>

            <v-card-text class="justify-center text-md-center">
                {{ syncStatus }}
            </v-card-text>
        </v-card>
    </v-container>

</template>

<script>
import { computed } from 'vue'
import { useAppStore } from '@/stores/app';
export default {
    name: 'SyncOverlay',

    setup() {
        const appStore = useAppStore()
        const syncStatus = computed(() => appStore.syncStatus)
        const isLoading = computed(() => appStore.isLoading)
        return {
            syncStatus,
            isLoading,
        }
    }
}
</script>
<style></style>