<template>
  <form-side-menu :dcfId="dcfId"></form-side-menu>
  <v-container fluid class="si-form-container">
     <main-header></main-header>
     <v-row>
          <v-col cols="12">
              <v-main>
                <div style="min-height: 100vh;">
                    <v-sheet
                      class = "si-form-sheet text-body-2 mx-auto"
                    >
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h2>Samples in my possesion</h2>
                                <DividerWithIcons />   
                            </v-col>
                        </v-row>

                        <v-row>
                           <v-col cols="12" md="12">
                              <v-text-field
                              v-model="search"
                              label="Search"
                              prepend-inner-icon="mdi-magnify"
                              variant="outlined"
                              hide-details
                              single-line
                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" md="12">
                              <v-btn v-if="selectedSamples && selectedSamples.length > 0"> Update Selected (<span>{{selectedSamples.length}}</span>) </v-btn>
                           </v-col>
                           <v-col cols="12" md="12">
                              <v-data-table :headers="sampleHeaders" :items="samplesData" height="auto"
                                 v-model:expanded="expandedItems" expand-on-click show-expand 
                                 :search="search" mobile density="compact"
                                 v-model:sort-by="sortBy" v-model:sort-desc="sortDesc"
                                 item-value="id" v-model="selectedSamples" show-select>  
                                 <template #expanded-row="{ columns, item }">
                                 <tr>
                                    <td :colspan="columns.length" class="si-dcf-notification-row">
                                       <div v-html="item.u_coc_overview"></div>
                                    </td>
                                 </tr>
                                 </template>
                              </v-data-table>

                           </v-col>
                           </v-row>
                           
                      </v-container>
                    </v-sheet>

                    
                    <v-sheet
                      class = "si-form-sheet text-body-2 mx-auto"
                    >
                      <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="12">
                                <h2>Total Samples in my possesion</h2>
                                <DividerWithIcons />   
                            </v-col>
                        </v-row>

                        <v-row>
                           <v-col cols="12" md="12">
                              <v-list lines="one">
                                 <v-list-item
                                    v-for="sample in samplesGroupedData"
                                    :key="sample.id"
                                 >
                                    <v-list-item-content>
                                       {{ sample.count }} - {{ sample.type }} sample{{ sample.count > 1 ? 's' : '' }}
                                    </v-list-item-content>
                                 </v-list-item>
                              </v-list>
                           </v-col>
                        </v-row>
                           
                      </v-container>
                    </v-sheet>

                </div>
              </v-main>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>

import { ref, onMounted, computed } from 'vue'
import FormSideMenu from '@/components/FormSideMenu.vue';
import MainHeader from '@/components/MainHeader.vue';
import DividerWithIcons from '@/components/DividerWithIcons.vue';
import { useUserStore } from '@/stores/user.js'
import sn_customerservice_sample from '@/dal/modules/sn_customerservice_sample';
import sn_customerservice_test_session from '@/dal/modules/sn_customerservice_test_session';
import sn_customerservice_test_mission from '@/dal/modules/sn_customerservice_test_mission';
import customer_contact from '@/dal/modules/customer_contact';
import core_company from '@/dal/modules/core_company';
import alm_hardware from '@/dal/modules/alm_hardware';
import sys_choice from '@/dal/modules/sys_choice';
import { formatDateTime } from '@/utils/utils.js';

export default {
  name: 'SamplesLanding',
  components: {
     FormSideMenu,
     MainHeader,
     DividerWithIcons
  },
  props: {

  },
  setup() {
     const userStore = useUserStore()
     const dcoId = ref(null)
     const search = ref('')
     const sampleList = ref([])
     const sampleExtendedData = ref([])
     const testSessions = ref([])
     const kitHardware = ref([])
     const athletes = ref([])
     const athleteSessionData = ref([])
     const selectedSamples = ref([])
     const companyList = ref([])
     const expandedItems = ref([])
     const missionList = ref([])
     const contactList = ref([])
     const sampleTypes = ref([])
     const sortBy = ref([{ key: 'sampleSealed', order: 'asc' }])   
     const sortDesc = ref(false) 
     const sampleHeaders = ref([
         { title: 'Mission', align: 'start', value: 'missionOrder', sortable: true, type: 'text' },
         { title: 'Session', align: 'start', value: 'session', sortable: true, type: 'text' },
         {
            title: 'Athlete',
            value: 'athleteName',
            sortable: true,
            type: 'text',
            sortRaw: (a, b) => {
               const normalizeName = (name) => name.replace(/[^a-zA-Z]/g, '').toLowerCase()
               const nameA = normalizeName(a.athleteName)
               const nameB = normalizeName(b.athleteName)
               return nameA.localeCompare(nameB)
            }
         },
         { title: 'Test', value: 'testType', sortable: true, type: 'text' },
         { title: 'Custodian', value: 'custodian', sortable: true, type: 'text' },
         {
            title: 'Sample Sealed',
            value: 'sampleSealed',
            sortable: true,
            type: 'date',
            sortRaw: (a, b) => {
               if (!a.sampleSealed) return 1
               if (!b.sampleSealed) return -1
               const dateA = new Date(a.sampleSealed)
               const dateB = new Date(b.sampleSealed)
               return dateA - dateB
            },
         },
         { title: 'Kit No', value: 'kitNumber', sortable: true, type: 'text' },
         { title: 'Storage Location', value: 'storageLocation', sortable: true, type: 'text' },
         { title: 'Laboratory', value: 'lab', sortable: true, type: 'text' },
         ])

     onMounted(async () => {

         dcoId.value = userStore.user_sys_id;

         sampleList.value = await sn_customerservice_sample.select("u_assigned_to", dcoId.value);
         companyList.value = await core_company.getAll();
         sampleTypes.value = await sys_choice.getSampleTypeOptions();
         contactList.value = await customer_contact.getAll();

         if(sampleList.value.length > 0){
            const testSessionIds = sampleList.value.map(sample => sample.u_test_session_id);
            const testSessionsData = await sn_customerservice_test_session.getByKey("id", testSessionIds);
            testSessions.value = Object.values(testSessionsData).flat();

            const missionIds = testSessions.value.map(session => session.u_mission_order);
            const missionData = await sn_customerservice_test_mission.getByKey("id", missionIds);
            missionList.value = Object.values(missionData).flat();

            const kitIds = sampleList.value.map(sample => sample.u_kit_used).filter(kitId => kitId !== '');
            if (kitIds.length > 0) {
               const kitData = await alm_hardware.getByKey("id", kitIds);
               kitHardware.value = Object.values(kitData).flat();
            }

            const athleteIds = testSessions.value.filter(x => x.u_athlete != "" && x.u_athlete != null).map(session => session.u_athlete);

            if (athleteIds && athleteIds.length > 0) {
               const athleteQuery = await customer_contact.getByKey('id', athleteIds);
               athletes.value = Object.values(athleteQuery).flat();
               athleteSessionData.value = athletes.value.map(athlete => {
                  const session = testSessions.value.find(session => session.u_athlete == athlete.id);
                  return {
                  athlete: athlete,
                  session: session,
                  };
               });
            }

            sampleList.value.forEach(sample => {
               const athleteSession = athleteSessionData.value.find(athleteSession => athleteSession.session.id == sample.u_test_session_id);
               let kit = null;

               if (sample.u_kit_used && sample.u_kit_used != '') {
                  kit = kitHardware.value.find(kit => kit.id === sample.u_kit_used);
               }
               sampleExtendedData.value.push({
                  sample: sample,
                  athlete: athleteSession ? athleteSession.athlete : null,
                  session: athleteSession ? athleteSession.session : null,
                  kit: kit,
                  lab: companyList.value.find(company => company.id === sample.u_lab)
               });
            });
         }

     })

      const getTestType = (missionId) => {
         const mission = missionList.value.find(x => x.id === missionId);
         return mission ? mission._display_values.u_test_type : '';
      }

      const getSampleType = (value) => {
         const sampleType = sampleTypes.value.find(type => type.value === value);
         return sampleType ? sampleType.label : '';
      }

      const getCustodianName = (custodianId) => {
         if (!custodianId) return '';
         const custodian = contactList.value.find(x => x.id === custodianId);
         return custodian ? custodian.name : custodianId;
      }

      const samplesData = computed(() => {
         return sampleExtendedData.value.map(data => ({
            ...data.sample,
            missionOrder: (data.session && data.session._display_values) ? data.session._display_values.u_mission_order : '',
            session: data.session ? data.session.case : '',
            athleteName: (data.athlete) ? `${data.athlete.first_name} ${data.athlete.last_name}` : '',
            testType: data.session ? getTestType(data.session.u_mission_order) : '',
            sampleType: getSampleType(data.sample.u_sample_type),
            custodian: getCustodianName(data.sample.u_custodian),
            sampleSealed: formatDateTime(data.sample.u_sample_sealed_time),
            kitNumber: data.kit ? data.kit.display_name : '',
            storageLocation: '', // TODO - marked as field not found - EY to add to service now?
            lab: data.lab ? data.lab.name : '' 

         }));
      });

      const samplesGroupedData = computed(() => {
         const groupedData = sampleExtendedData.value.reduce((acc, data) => {
            const sampleType = getSampleType(data.sample.u_sample_type);
            if (acc[sampleType]) {
               acc[sampleType].count += 1;
            } else {
               acc[sampleType] = {
                  id: sampleType,
                  type: sampleType,
                  count: 1,
               };
            }
            return acc;
         }, {});
         return Object.values(groupedData);
      });

     return {
        tab: null,
        loading: false,
        samplesData,
        samplesGroupedData,
        selectedSamples,
        expandedItems,
        search,
        sortBy,
        sortDesc,
        sampleHeaders
     }
  }
}
</script>

<style></style>
