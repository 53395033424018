<template>
    <v-row>
        <v-col cols="12" style="padding:0 !important">
            <v-main>
                <div v-if="dcoReport" style="min-height: 100vh;">
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h2>DCO Report</h2>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Mission Information</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    1. Was the information on the mission order accurate and sufficient for this mission?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_information_accurate_and_sufficient"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_information_accurate_and_sufficient) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="!isTrue(dcoReport.u_information_accurate_and_sufficient)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments (if no)" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    2. Was the mission conducted according to the mission order?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_mission_conducted_according_to_mission"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_mission_conducted_according_to_mission) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="!isTrue(dcoReport.u_mission_conducted_according_to_mission)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments (if no)" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            
                        </v-container>
                    </v-sheet>  

                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Attempt Details</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    3. Were there any relocations during the mission?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_relocations_during_mission"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_relocations_during_mission) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="isTrue(dcoReport.u_relocations_during_mission)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments (if yes)" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    4. What was the arrival time at the venue?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form-inline">
                                    <v-text-field v-model="venueArrivalDate" label="" density="compact" bg-color="white" required
                                        @update:model-value="updateArrivalDate" type="date"
                                        :error-messages="w$.venueArrivalDate.$errors.map(e => e.$message)" @blur="w$.venueArrivalDate.$touch">
                                    </v-text-field> 
                                    <v-text-field v-model="venueArrivalTime" label="" density="compact" bg-color="white" required
                                        @update:model-value="updateArrivalTime" type="time"
                                        :error-messages="w$.venueArrivalTime.$errors.map(e => e.$message)" @blur="w$.venueArrivalTime.$touch">
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Actions on arrival</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    5. Describe arrival and access to the mission location, including any potential for advance notice to athletes or hazards
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_arrival_and_access_to_the_mission_location" label="Comments" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                    6. Did any third parties assist in locating or contacting the athlete?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_third_parties_assist_in_locating_contact"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_third_parties_assist_in_locating_contact) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="isTrue(dcoReport.u_third_parties_assist_in_locating_contact)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments (if yes)" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-sheet>  

                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Athlete Section</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                7. Describe the method used to select athlete(s)
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-combobox v-model="selectionMethod" label="" density="compact" :items="selectionMethodOptions" item-title="label"
                                    @update:model-value="updateSelectionMethod" :error-messages="w$.selectionMethod.$errors.map(e => e.$message)"
                                    @blur="w$.selectionMethod.$touch"
                                    item-value="id" bg-color="white"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row v-if="selectionMethodOther" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments (other)" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                8. Were the names of any selected athletes revealed to non-SCP prior to their notification?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_athletes_revaled_to_non_scp_prior"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_athletes_revaled_to_non_scp_prior) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="isTrue(dcoReport.u_athletes_revaled_to_non_scp_prior)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Doping Control Station</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                9. Describe the location and facilties of the DCS
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_location_and_facilities" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                10. Were any people not involved in testing present in the DCS at any time after testing procedures started?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_people_not_involved_in_testing_present"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_people_not_involved_in_testing_present) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="isTrue(dcoReport.u_people_not_involved_in_testing_present)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>

                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Procedure and deviation from procedures</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                11. Provide additional details regarding any modifications and irregularities to procedure recorded on DCF's
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_additional_details_regarding_any_modifications_and_irregularities" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                12. Provide details of any incidents or health and safety concerns
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_any_incidents_or_health_and_safety_concerns" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                13. Provide details of any irregularities not recorded on the DCF
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_any_irregulariites_not_recorded_dcf" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                14. Were there any issues with the testing equipment?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_issues_with_testing_equipment"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_issues_with_testing_equipment) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row v-if="isTrue(dcoReport.u_issues_with_testing_equipment)" class="supplementary-question">
                                <v-col cols="6" md="6" class="si-form">
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea label="Comments" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            
                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Samples</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                15. Describe the storage and security of samples (and partial samples) during the mission
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_stroage_and_security_of_samples_during_mission" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                16. Describe the storage and security of samples from the end of the mission to their first place of storage or transfer to another party.
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_storage_and_security_of_samples" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                                </v-row>
                                <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                Comments Section
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_comments_section" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Observation and Feedback</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                17. Report any information gathered during the mission
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_any_information_gathered" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                18. Provide feedback on the SCP that were involved in this mission
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_feedback_on_scp" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                19. Were SCP debriefed on any irregularities, issues, incidents that occurred during the mission?
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-switch v-model="dcoReport.u_scp_debried_any_irregularities"
                                    true-value="true" false-value="false" :color="switchColor"
                                    :label="isTrue(dcoReport.u_scp_debried_any_irregularities) ? 'Yes' : 'No'" hide-details
                                    inset></v-switch>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                Comments Section
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_comments" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h3>Other</h3>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="6" class="si-form">
                                20. Provide any other information you feel is important
                                </v-col>
                                <v-col cols="6" md="6" class="si-form">
                                    <v-textarea v-model="dcoReport.u_any_information" label="" density="compact" bg-color="white">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-sheet>  
                    
                    <v-sheet class="si-form-sheet text-body-2 mx-auto">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h2>Signatures</h2>
                                    <DividerWithIcons />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" md="3" class="si-form">
                                    <span class="si-top-label">Doping Collection Official </span>
                                    <v-combobox v-model="selectedDco" label="" density="compact" :items="dcoList" item-title="name"
                                    @update:model-value="updateDco" :error-messages="w$.selectedDco.$errors.map(e => e.$message)"
                                    @blur="w$.selectedDco.$touch" item-value="id" bg-color="white"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row style="align-items: center;">
                                <v-col cols="6" md="6" class="si-form-signature">
                                    <span class="font-italic si-top-label">Author Signature </span>
                                    <div class="si-signature-field">
                                        <vue-signature-pad
                                            ref='authorSignature'
                                            :max-width='options.maxWidth'
                                            :min-width='options.minWidth'
                                            height="300px"
                                            :options='{
                                            penColor: options.penColor,
                                            backgroundColor: options.backgroundColor,
                                            }'
                                        />
                                    </div>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-btn @click='clearAuthorSignature' class="si-right-action-button" size="large">
                                        <v-icon icon="mdi-undo"></v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-sheet>

                    <v-container class="pa-0" fluid>
                        <v-row class="d-flex justify-end">
                            <v-col cols="3" md="3" class="d-flex">
                                <v-btn @click="goBack" class="si-form-button-2 flex-grow-1" height="48"
                                    variant="tonal">
                                    Back
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" class="d-flex">
                                <v-btn :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                                    variant="tonal">
                                    Save
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                </div>
            </v-main>
        </v-col>
    </v-row>  
    <v-snackbar v-model="validationError">
        Please fill in all required fields.

        <template v-slot:actions>
        <v-btn color="red" variant="text" @click="validationError = false">
            Close
        </v-btn>
        </template>
    </v-snackbar> 
</template>

<script>
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import { ref, onMounted, computed, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import sn_customerservice_dco_report from '@/dal/modules/sn_customerservice_dco_report'
import sys_choice from '@/dal/modules/sys_choice'
import { isTrue, goBack, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import { useMissionStore } from '@/stores/mission'

export default {
    name: 'DcoReportEdit',
    components: {
        DividerWithIcons,
        VueSignaturePad
    },
    props: {
        id: {
            type: String,
            required: true
        },
        missionId: {
            type: String,
            required: true
        },
        sessionId: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const mission = ref()
        const dcoReport = ref({})
        const isLoaded = ref(false)
        const selectionMethodOptions = ref([])
        const selectionMethod = ref(null)
        const selectionMethodOther = ref(false)
        const wasAccurate = ref(false)
        const conductedAccordingToOrder = ref(false)
        const hadRelocations = ref(false)
        const venueArrivalTime = ref(null)
        const venueArrivalDate = ref(null)
        const thirdPartiesAssisted = ref(false)
        const namesRevealed = ref(false)
        const otherPeoplePresent = ref(false)
        const testEquipmentIssues = ref(false)
        const scpDebriefed = ref(false)
        const authorSignature = ref(null)
        const validationError = ref(false)
        const dcoList = ref([])
        const leadDco = ref(null)
        const selectedDco = ref(null)
        const missionStore = useMissionStore()
        const router = useRouter()
        const route = useRoute()

        const options = ref({
            penColor: 'rgb(0,0,0)',
            backgroundColor: 'rgb(255, 255, 255)',
            maxWidth: 2,
            minWidth: 2,
        })

        
        onMounted(async () => {
            try {

                await missionStore.loadMission(props.missionId)
                dcoList.value = missionStore.dcoList
                leadDco.value = missionStore.dco

                selectionMethodOptions.value = await sys_choice.getDcoReportSelectionMethodOptions()

                if (props.id && props.id !== 'new') {
                    dcoReport.value = await sn_customerservice_dco_report.get(props.id)

                    if (dcoReport.value.u_method_used_to_select_athlete != '') {
                        selectionMethod.value = selectionMethodOptions.value.find(x => x.label === dcoReport.value.u_method_used_to_select_athlete)
                    }
                    if (dcoReport.value.u_dco_name != '') {
                        selectedDco.value = dcoList.value.find(x => x.id === dcoReport.value.u_dco_name)
                    }
                    
                    if (dcoReport.value.u_arrival_time) {
                        venueArrivalDate.value = dateTimePickerInitialise('date', dcoReport.value.u_arrival_time)
                        venueArrivalTime.value = dateTimePickerInitialise('time', dcoReport.value.u_arrival_time)
                    }
                    
                } else {
                    dcoReport.value = await sn_customerservice_dco_report.createNew()
                    dcoReport.value.u_number = props.sessionId
                    if (leadDco.value && dcoList.value.length > 0) {
                        selectedDco.value = dcoList.value.find(dco => dco.id === leadDco.value)
                        dcoReport.value.u_dco_name = selectedDco.value ? selectedDco.value.id : ''
                    }
                }

            } catch (error) {
                console.error("Error:", error)
            } finally {
                isLoaded.value = true
            }

        })

        const close = () => {
            emit('close')
        }

        const clearAuthorSignature = () => {
            if (authorSignature.value) {
                authorSignature.value.clearCanvas()
            }
        }

        const updateSelectionMethod = (value) => {
            if (value) {
                selectionMethod.value = value
                selectionMethodOther.value = value.label === 'other' // TODO check this
                dcoReport.value.u_method_used_to_select_athlete = value.label
            }
        }

        const updateDco = (value) => {
            dcoReport.value.u_dco_name = value.id
        }
        
        const updateArrivalDate = (value) => {
            dcoReport.value.u_arrival_time = dateTimePickerUpdate('date', value, dcoReport.value.u_arrival_time)
        }

        const updateArrivalTime = (value) => {
            dcoReport.value.u_arrival_time = dateTimePickerUpdate('time', value, dcoReport.value.u_arrival_time)
        }

        const dcoReportRules = computed(() => {
            const localRules = {
                u_comments: {},
                u_comments_section: {}
            }
            return localRules
        })

        const variableRules = computed(() => {
            const localRules = {
                selectionMethod: { required },
                selectedDco: { required },
                venueArrivalDate: { required, mustBeValidDate: mustBeValidDate() },
                venueArrivalTime: { required, mustBeValidTime: mustBeValidTime() }
            }
            return localRules
        })

        const v$ = useVuelidate(dcoReportRules, { dcoReport })
        const w$ = useVuelidate(variableRules, { venueArrivalTime, selectionMethod, selectedDco, venueArrivalDate })

        async function save() {
            v$.value.$validate()
            w$.value.$validate()
            if (v$.value.$invalid || w$.value.$invalid) {
                validationError.value = true
                return
            }
            let data = toRaw(dcoReport.value)
            await sn_customerservice_dco_report.save(data)
            await dal.commit()
        }

        const handleGoBack = () => {
            goBack(router, route)
        };

        return {
            switchColor: '#2e4d23',
            mission,
            tab: null,
            isLoaded,
            dcoReport,
            close,
            save,
            selectionMethod,
            selectionMethodOptions,
            selectionMethodOther,
            wasAccurate,
            conductedAccordingToOrder,
            hadRelocations,
            venueArrivalTime,
            venueArrivalDate,
            updateArrivalDate,
            updateArrivalTime,
            thirdPartiesAssisted,
            namesRevealed,
            otherPeoplePresent,
            testEquipmentIssues,
            scpDebriefed,
            authorSignature,
            clearAuthorSignature,
            options,
            validationError,
            isTrue,
            goBack: handleGoBack,
            updateSelectionMethod,
            dcoList,
            selectedDco,
            updateDco,
            v$,
            w$

        }
    },

}
</script>

<style></style>
