import BaseModule from './base_module'
import snDCOReport from '../../service-now/tables/sn_customerservice_dco_report'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'

class DCOReport extends BaseModule {
    constructor() {
        super(snDCOReport.table)

        this.sync = true
        this.syncOrder = 15 // After TestMission and TestSession.
    }

    async getByMissionId(missionId) {  
        try{
            const sessions = await dal.modules.sn_customerservice_test_session.getByMissionId(missionId)
            if (sessions && sessions.length > 0) {
                const sessionIds = sessions.map((x) => x.id);
                return await this.repository.db[this.name]
                    .where('u_number')
                    .anyOf(sessionIds)
                    .toArray();
            }
        } catch (error) {
            console.error('Error retrieving DCO reports:', error)
            return []
        }
    }
    
    async fetch() {
        const missions = await dal.modules.sn_customerservice_test_mission.getAll()
        const testSessions = await dal.modules.sn_customerservice_test_session.getAll()

        // Get all the DCO Reports that are related to the loaded Test Missions and Test Sessions.
        const missionData = await pagination.paginateData(
            'u_numberIN',
            missions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snDCOReport.table,
                    `${pageQuery}`
                )
        )

        const testSessionData = await pagination.paginateData(
            'u_numberIN',
            testSessions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snDCOReport.table,
                    `${pageQuery}`
                )
        )

        return [...missionData, ...testSessionData]
    }
}

const module = new DCOReport()
export default module
