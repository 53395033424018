// Only used to give options for selecting the laboratory
import BaseModule from './base_module'
import coreCountry from '../../service-now/tables/core_country'
import api from '../../service-now/api'

class Location extends BaseModule {
    constructor() {
        super(coreCountry.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
        ]
    }

    async fetch() {
        // Get all Active
        const query = `activeINtrue`
        const { data } = await api.getTableData(coreCountry.table, query, true)
        return [...data.result]
    }
}

const module = new Location()
export default module