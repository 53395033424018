function createChange(tableName, type, key, metadata) {
    var change = {}
    change.type = type
    change.createdon = new Date()
    change.key = key
    change.table = tableName
    change.metadata = metadata
    return change
}

function createUpdate(tableName, data, key, relationships, metadata) {
    var update = createChange(tableName, 'u', key, metadata)
    update.data = data
    update.relationships = relationships
    return update
}

function createInsert(tableName, data, key, relationships, metadata) {
    var insert = createChange(tableName, 'c', key, metadata)
    insert.data = data
    insert.relationships = relationships
    return insert
}

function createDelete(tableName, key, addtionalData, metadata) {
    var del = createChange(tableName, 'd', key, metadata)
    del.data = addtionalData // some deletes require addition fields
    return del
}

export { createChange, createUpdate, createInsert, createDelete }
