//Only used to get Athletes details.
import BaseModule from '@/core/dal/modules/base-module'
import customerContact from '../../service-now/tables/customer_contact'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'
import sn_customerservice_sample from '@/service-now/tables/sn_customerservice_sample'
import snDCF from '../../service-now/tables/sn_customerservice_dcf'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

function removeDuplicatesById(array) {
    const map = new Map()
    array.forEach((item) => map.set(item.id, item))
    return Array.from(map.values())
}

class Contact extends BaseModule {
    constructor() {
        super(customerContact.table)
        this.sync = true
        this.syncOrder = 15 // Make sure this is after DCFs and after Samples
        this.relationships = [
            {
                table: snTestSession.table,
                field: 'u_athlete'
            },
            {
                table: snDCF.table,
                field: 'u_athlete'
            },
            {
                table: sn_customerservice_sample.table,
                field: 'u_custodian'
            }
        ]
    }

    async fetch() {
        const test_sessions = await dal.modules.sn_customerservice_test_session.getAll()
        // Get all the Athletes that are related to the loaded Test Sessions.
        const testSessionData = await pagination.paginateData(
            'sys_idIN',
            test_sessions.map((x) => x.u_athlete),
            (pageQuery) => api.getTableData(customerContact.table, `${pageQuery}`)
        )
        // Get all the Athletes that are related to the loaded DCFs.
        const dcfs = await dal.modules.sn_customerservice_dcf.getAll()
        const dcfData = await pagination.paginateData(
            'sys_idIN',
            dcfs.map((x) => x.u_athlete),
            (pageQuery) => api.getTableData(customerContact.table, `${pageQuery}`)
        )
        // Get all custodians based on current samples in possession
        const samples = await dal.modules.sn_customerservice_sample.getAll()
        const sampleCustodianIds = removeDuplicatesById(samples.map((x) => x.u_custodian).filter(x => x != ''))
        const sampleCustodianData = await pagination.paginateData(
            'sys_idIN',
            sampleCustodianIds,
            (pageQuery) => api.getTableData(customerContact.table, `${pageQuery}`)
        )

        return [...testSessionData, ...dcfData, ...sampleCustodianData]
    }
}

const module = new Contact()
export default module
