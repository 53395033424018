import BaseModule from './base_module'
import attachmentTable from '../../service-now/tables/attachment'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'
import { useAppStore } from '@/stores/app.js'
import { v4 as uuid } from 'uuid'

class Attachment extends BaseModule {
    constructor() {
        super(attachmentTable.table)

        this.sync = true

        this.syncOrder = 13 // Make sure this is after document version
        this.relationships = []

        this.cacheDoNotClear = true
    }

    async save(attachment) {
        // save the blob to the changelog db
        attachment.id = uuid()
        await this.repository.changeLogRepository.put(this.name, attachment)
    }

    async getByDocumentVersionIds(documentVersionIds) {
        const attachments = await this.selectByKeys('table_sys_id', documentVersionIds)

        // attachments that are not synced yet
        const offlineAttachments = await this.repository.changeLogRepository.db[this.name]
            .where('table_sys_id')
            .anyOf(documentVersionIds)
            .toArray()

        return [...attachments, ...offlineAttachments]
    }

    async upload() {
        // eslint-disable-next-line no-constant-condition
        while (true) {
            // get single attachment
            let attachments = await this.repository.changeLogRepository.getAll(this.name, 0, 1)

            // Check if complete, no more data to sync...
            if (attachments.length === 0) {
                return
            }

            // upload the attachment
            let attachment = attachments[0]
            let response = null
            try {
                response = await api.uploadAttachment(
                    attachment.table_name,
                    attachment.table_sys_id,
                    attachment.file_name,
                    attachment._blob
                )
            } catch (e) {
                console.error(e)
                // TODO: report error some where
                return
            }

            if (!response?.result.sys_id) {
                // TODO: we seem to hit this if an attachment has already been uploaded
                console.error('Attachment upload failed')
                return
            }

            const newAttachment = response.result
            newAttachment.id = newAttachment.sys_id
            newAttachment._server = true
            newAttachment._blob = attachment._blob

            // save
            await this.repository.db[this.name].put(newAttachment)

            this.repository.changeLogRepository.delete(this.name, attachment.id)
        }
    }

    async fetch() {
        let documentVersions = await dal.modules.ds_document_version.getSysIds()
        const existingAttachmentIds = await this.getSysIds()

        const attachments = await pagination.paginateData('', documentVersions, (pageQuery) =>
            api.getAttachments(`${pageQuery}`)
        )

        const appStore = useAppStore()

        // get the blob for each attachment
        for (const attachment of attachments) {
            if (existingAttachmentIds.includes(attachment.sys_id)) {
                // do nothing we already have this attachment
                continue
            }

            // get the actual file contents
            await appStore.setSyncStatus('fetching file ' + attachment.file_name)
            attachment.id = attachment.sys_id
            attachment._server = true
            const blob = await api.getAttachment(attachment.sys_id)

            // save the blob to the indexeddb
            await this.repository.db[this.name].put({ ...attachment, _blob: blob })

            // do not keep blob in memory
        }

        // existing that are not in the new list should be deleted
        let attachmentIds = attachments.map((x) => x.sys_id)
        for (const sys_id of existingAttachmentIds) {
            if (!attachmentIds.includes(sys_id)) {
                await this.remove(sys_id)
            }
        }

        // already saved attachments
        return []
    }
}

const module = new Attachment()
export default module
