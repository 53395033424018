import BaseModule from '@/core/dal/modules/base-module'

class SIBaseModule extends BaseModule {
    /**
     * Return all records from the table with matching ids
     * @param {*} values
     * @returns
     */
    async getByIds(values) {
        let data = await this.repository.getByIndex(
            this.name,
            this.table().schema.primKey.keyPath,
            true,
            values
        )
        return Object.values(data)
    }

    /**
     * Helper function to get all the sys_ids in the table
     * @returns
     */
    async getSysIds() {
        return await this.table().orderBy('sys_id').keys()
    }

    /**
     * Select by index but only return the primary ids
     * @returns
     */
    async selectKeys(indexName, value) {
        return await this.repository.selectKeys(this.name, indexName, value)
    }

    /**
     * Select by index
     * @returns
     */
    async selectByKeys(indexName, keys) {
        return await this.table().where(indexName).anyOf(keys).toArray()
    }

    /**
     * Return the table object
     * @returns
     */
    table() {
        return this.repository.db[this.name]
    }
}

export default SIBaseModule
