<template>
  <v-row>
    <v-col cols="12" style="padding:0 !important">
      <v-main>
        <div style="min-height: 100vh;">
          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <v-row v-if="mission && missionInfo">
                <v-col cols="12" md="12">
                  <h3>Mission Details</h3>
                  <DividerWithIcons />
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Order</span>
                      <div class="font-weight-bold si-fixed-field">{{ mission.number }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Test Type</span>
                      <div class="font-weight-bold si-fixed-field"> {{ missionInfo.u_test_type }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Test Model</span>
                      <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_test_mission_model }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Event Name</span>
                      <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_event_name }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Sport / Discipline</span>
                      <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_sport }}
                        <span
                          v-if="missionInfo.u_discipline && missionInfo.u_sport && missionInfo.u_sport != missionInfo.u_discipline">
                          / {{ missionInfo.u_discipline }}</span>
                        <span v-else-if="missionInfo.u_discipline && !missionInfo.u_sport">
                          {{ missionInfo.u_discipline }}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Test Window</span>
                      <div class="font-weight-bold si-fixed-field">{{ formatTestingWindow(mission) }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Venue Location</span>
                      <div class="font-weight-bold si-fixed-field">
                        {{ missionInfo.u_venue }} <br>
                        {{ missionInfo.u_venue_address }}
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Location Red flag/s</span>
                      <div class="font-weight-bold si-fixed-field">{{ getLocationRedFlags() }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">No. Athletes to be tested</span>
                      <div class="font-weight-bold si-fixed-field"> {{ mission.u_total_test_sessions }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="12" class="si-form">
                      <span class="font-italic si-top-label">Mission special instructions</span>
                      <div class="si-fixed-field" v-html="missionInstructions" style="white-space: pre-wrap;"></div>
                    </v-col>
                  </v-row>
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Timezone</span>
                      <div class="font-weight-bold si-fixed-field">{{ userTimeZone }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Laboratory</span>
                      <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_lab }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="si-info-field-row">
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Testing Authority</span>
                      <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_testing_authority }}
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Results Management Authority</span>
                      <div class="font-weight-bold si-fixed-field">{{
                        missionInfo.u_results_management_authority }}</div>
                    </v-col>
                    <v-col cols="12" md="4" class="si-form">
                      <span class="font-italic si-top-label">Sample Collection Authority</span>
                      <div class="font-weight-bold si-fixed-field">{{
                        missionInfo.u_sample_collection_authority }}</div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <div class="d-flex justify-space-between align-center ">
                    <h3>Athletes</h3>
                    <v-dialog v-model="showAthleteDialog" transition="dialog-bottom-transition" fullscreen>
                      <template v-slot:activator="{ props: activatorProps }">
                        <v-btn v-bind="activatorProps" class="si-right-add-button">
                          <v-icon icon="mdi-plus"></v-icon>
                        </v-btn>
                      </template>

                      <athlete-edit id="new" :missionNumber="missionNumber" :sport="mission.u_sport"
                        :discipline="mission.u_discipline" @close="showAthleteDialog = false" @created="athleteCreated">
                      </athlete-edit>

                    </v-dialog>

                  </div>
                  <DividerWithIcons />

                  <v-row>
                    <v-col cols="12" md="12">
                      <v-data-table :headers="athleteHeaders" :items="athletesData" height="auto" item-key="id"
                        item-value="id" v-model:expanded="expandedItems" expand-on-click>

                        <template v-slot:[`item.minor`]="{ item }">
                          <v-chip v-if="item.minor === 'Yes'" color="green" variant="flat">
                            {{ item.minor }}
                          </v-chip>
                          <span v-else>
                            {{ item.minor }}
                          </span>
                        </template>

                        <template v-slot:[`item.para`]="{ item }">
                          <v-chip v-if="item.para === 'Yes'" color="primary" variant="flat">
                            {{ item.para }}
                          </v-chip>
                          <span v-else>
                            {{ item.para }}
                          </span>
                        </template>

                        <!-- <template v-slot:[`item.dcf`]="{ item }">
                          <v-menu class="si-dropdown-menu">
                            <template v-slot:activator="{ props }">
                              <v-btn v-bind="props" class="si-right-add-button">
                                <v-icon icon="mdi-plus"></v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="addDCF(item)">
                                <v-list-item-title>Add Doping Control Form</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template> -->

                        <template #expanded-row="{ columns, item }">
                          <tr>
                            <td :colspan="columns.length" class="si-dcf-notification-row">
                              <v-card v-for="dcfItem in item.dcfs" :key="dcfItem.dcf.id" class="mb-6">
                                <v-card-text>
                                  <div class="d-flex justify-space-between align-center" @click="editDCF(dcfItem.dcf)">
                                    <span>DCF-Notification</span>
                                    <v-btn class="si-right-action-button">
                                      <v-icon icon="mdi-chevron-right"></v-icon>
                                    </v-btn>
                                  </div>
                                </v-card-text>
                                <v-progress-linear :model-value="dcfItem.progress" bg-color="grey" :color="progressColor(dcfItem.progress)"></v-progress-linear>
                              </v-card>
                              
                              <v-menu class="si-dropdown-menu">
                                <template v-slot:activator="{ props }">
                                  <v-btn v-bind="props" class="si-right-add-button">
                                    <v-icon icon="mdi-plus"></v-icon>
                                  </v-btn>
                                </template>
                                <v-list>
                                  <v-list-item @click="addDCF(item)">
                                    <v-list-item-title>Add Doping Control Form</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item :to="{ name: 'mission-supplementary-report', params: { id: 'new', athleteId:item.id, missionId: missionId, isMissionType: false } }">
                                   <v-list-item-title>Add Supplementary Report</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item :to="{ name: 'mission-uar', params: { id: 'new', athleteId:item.id, missionId: missionId, isMissionType: false } }">
                                    <v-list-item-title>Add Unsuccessful Attempt Report</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>

                    </v-col>
                  </v-row>


                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container v-if="missionInfo" fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <div class="d-flex justify-space-between align-center ">
                    <h3>Field Staff</h3>
                    <v-dialog v-model="showFieldStaffDialog" transition="dialog-bottom-transition">
                      <template v-slot:activator="{ props: activatorProps }">
                        <v-btn v-bind="activatorProps" class="si-right-add-button">
                          <v-icon icon="mdi-plus"></v-icon>
                        </v-btn>
                      </template>
                      <v-card prepend-icon="mdi-account" title="Add Chaperones" style="margin-left: auto;
                        margin-right: auto;
                        min-width: 600px;">
                        <v-card-text>
                          <v-row dense>
                            <v-col cols="12" md="12">
                              <v-combobox label="" density="compact" :items="chaperoneOptions" item-title="name"
                                item-value="id" bg-color="white" multiple required></v-combobox>
                            </v-col>

                          </v-row>

                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn text="Close" variant="plain" @click="showFieldStaffDialog = false"></v-btn>

                          <v-btn text="Save" variant="tonal" @click="addSelectedFieldStaff"></v-btn>
                        </v-card-actions>
                      </v-card>

                    </v-dialog>

                  </div>
                  <DividerWithIcons />

                  <v-row>
                    <v-col cols="12" md="12">

                    </v-col>
                  </v-row>


                </v-col>
              </v-row>
              <v-row class="si-info-field-row">
                <v-col cols="12" md="4" class="si-form">
                  <span class="font-italic si-top-label">Lead DCO</span>
                  <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_assigned_lead_dco }}</div>
                </v-col>
                <v-col cols="12" md="4" class="si-form">
                  <span class="font-italic si-top-label">DCO's</span>
                  <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_assigned_support_dcos }} </div>
                </v-col>
                <v-col cols="12" md="4" class="si-form">
                  <span class="font-italic si-top-label">BCO's</span>
                  <div class="font-weight-bold si-fixed-field">{{ missionInfo.u_assigned_bcos }}</div>
                </v-col>
              </v-row>

              <v-row class="si-info-field-row">
                <v-col cols="12" md="12" class="si-form">
                  <span class="font-italic si-top-label">Chaperones</span>
                  <div class="font-weight-bold si-fixed-field"> {{ missionInfo.u_assigned_chaperones }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <mission-documents-list :mission="mission"></mission-documents-list>
            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <div class="d-flex justify-space-between align-center ">
                    <h3>Mission Reports</h3>
                    <v-menu class="si-dropdown-menu">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" class="si-right-add-button">
                          <v-icon icon="mdi-plus"></v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item :to="{ name: 'mission-dco-report', params: { id: 'new', missionId: missionId } }">
                          <v-list-item-title>Add DCO Report</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :to="{ name: 'mission-supplementary-report', params: { id: 'new', missionId: missionId, isMissionType: true } }">
                          <v-list-item-title>Add Supplementary Report</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :to="{ name: 'mission-uar', params: { id: 'new', missionId: missionId, isMissionType: true } }">
                          <v-list-item-title>Add Mission UAR</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          :to="{ name: 'mission-pftc', params: { id: 'new', missionId: missionId } }">
                          <v-list-item-title>Add PFTC</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                  <DividerWithIcons />
                  
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-data-table :headers="reportHeaders" :items="reportData" height="auto" item-key="report.id"
                        item-value="report.id">
                        <template v-slot:[`item.reportType`]="{ item }">
                          <a :href="item.editUrl"  style="display: flex;align-items: center;">
                            <v-icon icon="mdi-file-document-outline" style="margin-right: 10px;"></v-icon> {{ item.reportType }}
                          </a>
                        </template>
                      </v-data-table>

                    </v-col>
                  </v-row>



                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

        </div>
      </v-main>
    </v-col>
  </v-row>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { isTrue, formatDateLocal } from '@/utils/utils.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import AthleteEdit from '@/views/Athlete/AthleteEdit.vue'
import sn_customerservice_test_mission from '@/dal/modules/sn_customerservice_test_mission'
import sn_customerservice_test_session from '@/dal/modules/sn_customerservice_test_session'
import sn_customerservice_dco_report from '@/dal/modules/sn_customerservice_dco_report'
import sn_customerservice_supp_report from '@/dal/modules/sn_customerservice_supp_report'
import sn_customerservice_pftc from '@/dal/modules/sn_customerservice_pftc'
import sn_customerservice_unsuccessful_attempt_record from '@/dal/modules/sn_customerservice_unsuccessful_attempt_record'
import core_company from '@/dal/modules/core_company'
import ds_document_references from '@/dal/modules/ds_document_references'
import ds_document from '@/dal/modules/ds_document'
import ds_document_version from '@/dal/modules/ds_document_version'
import sn_customerservice_sports from '@/dal/modules/sn_customerservice_sports'
import cmn_location from '@/dal/modules/cmn_location'
import u_red_flag from '@/dal/modules/u_red_flag'
import customer_contact from '@/dal/modules/customer_contact'
import sys_user from '@/dal/modules/sys_user'
import sys_user_group from '@/dal/modules/sys_user_group'
import sn_customerservice_wsp_choice from '@/dal/modules/sn_customerservice_wsp_choice'
import attachment from '@/dal/modules/attachment'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'
import sn_customerservice_m2m_mission_instruction from '@/dal/modules/sn_customerservice_m2m_mission_instruction'
import MissionDocumentsList from './MissionDocumentsList.vue'
import { useUserStore} from '@/stores/user.js'
import { useMissionStore } from '@/stores/mission'

export default {
  name: 'MissionsDetail',
  components: {
    DividerWithIcons,
    AthleteEdit,
    MissionDocumentsList
  },
  props: {
    missionId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const mission = ref()
    const missionInfo = ref()
    const testSessions = ref([])
    const choices = ref([])
    const athletes = ref([])
    const systemUsers = ref([])
    const athleteSessionData = ref([])
    const redFlags = ref([])
    const venueRedFlags = ref([])
    const documents = ref([])
    const documentVersions = ref([])
    const documentAttachments = ref([])
    const documentOwners = ref([])
    const pools = ref([])
    const sports = ref([])
    const missionInstructions = ref('')
    const isLoaded = ref(false)
    const showAthleteDialog = ref(false)
    const showFieldStaffDialog = ref(false)
    const showDocumentDialog = ref(false)
    const chaperoneOptions = ref([])
    const router = useRouter()
    const userStore = useUserStore()
    const userTimeZone = ref(null)
    const dcoReports = ref([])
    const supplementaryReports = ref([])
    const uarReports = ref([])
    const pftcReports = ref([])
    const missionStore = useMissionStore()

    onMounted(async () => {
      try {

        if (!props.missionId) {
          console.error("No mission ID provided")
          return
        }

        mission.value = missionStore.mission;
        userTimeZone.value = userStore.userTimeZone
        const missionQuery = await sn_customerservice_test_mission.get(props.missionId)
        mission.value = missionQuery
        missionInfo.value = missionQuery._display_values
        testSessions.value = await sn_customerservice_test_session.select("u_mission_order", props.missionId)
        const athleteIds = testSessions.value.filter(x => x.u_athlete != "" && x.u_athlete != null).map(session => session.u_athlete)

        if (athleteIds && athleteIds.length > 0) {
          const athleteQuery = await customer_contact.getByKey('id', athleteIds)
          athletes.value = Object.values(athleteQuery).flat()
          athleteSessionData.value = athletes.value.map(athlete => {
            const session = testSessions.value.find(session => session.u_athlete == athlete.id)
            return {
              athlete: athlete,
              session: session,
            }
          })
        }

        const missionInstructionQuery = await sn_customerservice_m2m_mission_instruction.select("u_test_mission", props.missionId)
        if (missionInstructionQuery.length > 0) {
          missionInstructionQuery.forEach(instruction => {
            missionInstructions.value += "<strong>" + instruction.u_short_instruction + "</strong> - " + instruction.u_special_instruction_message + "<br><br>"
          })
        }

        const missionDocumentReferences = await ds_document_references.select("target_record", props.missionId)
        const documentIds = missionDocumentReferences.map(ref => ref.document)

        if (documentIds && documentIds.length > 0) {
          const documentQuery = await ds_document.getByKey('id', documentIds)
          documents.value = Object.values(documentQuery).flat()

          const documentOwnersIds = documents.value.map(document => document.owner)
          if (documentOwnersIds && documentOwnersIds.length > 0) {
            const documentOwnersQuery = await customer_contact.getByKey('id', documentOwnersIds)
            documentOwners.value = Object.values(documentOwnersQuery).flat()
          }

          const documentVersionIds = documents.value.map(document => document.default_version)

          if (documentVersionIds && documentVersionIds.length > 0) {
            const documentVersionQuery = await ds_document_version.getByKey('id', documentVersionIds)
            documentVersions.value = Object.values(documentVersionQuery).flat()
            const attachmentQuery = await attachment.select("table_sys_id", documentVersionIds)
            documentAttachments.value = Object.values(attachmentQuery).flat()
          }
        }

        sports.value = await sn_customerservice_sports.getAll()
        pools.value = await sys_user_group.getAll()
        redFlags.value = await u_red_flag.getAll()
        choices.value = await sn_customerservice_wsp_choice.getAll()
        systemUsers.value = await sys_user.getAll()

        const venueCompany = await core_company.get(mission.value.u_venue)
        if (venueCompany?.id) {
          const venueLocation = await cmn_location.select("company", venueCompany.id)
          if (venueLocation && venueLocation[0]) {
            const venueRedFlagQuery = await u_red_flag.getByKey("u_location", venueLocation[0].id)
            venueRedFlags.value = Object.values(venueRedFlagQuery).flat()
          }
        }

        // TODO - delete Dummy data
        chaperoneOptions.value = [
          { id: '1', name: 'Aroha' },
          { id: '2', name: 'Jimmy' },
          { id: '3', name: 'Sally' },
          { id: '4', name: 'Tom' }
        ]

        dcoReports.value = await sn_customerservice_dco_report.getByMissionId(props.missionId)
        supplementaryReports.value = await sn_customerservice_supp_report.getByMissionId(props.missionId)
        uarReports.value = await sn_customerservice_unsuccessful_attempt_record.getByMissionId(props.missionId)
        pftcReports.value = await sn_customerservice_pftc.getByMissionId(props.missionId)

      } catch (error) {
        console.error("Error fetching mission:", error)
      } finally {
        isLoaded.value = true
      }

    })


    const getSport = (athleteSession) => {
      if (athleteSession.session && sports.value) {
        const sport = sports.value.find(sport => sport.id == athleteSession.session.u_sport)
        if (sport) {
          return sport.u_name
        }
      }
      return ''
    }

    const getPool = (athlete) => {
      if (!athlete || !athlete.u_pool) {
        return ''
      }
      const result = pools.value.filter(pool => athlete.u_pool.includes(pool.id))
      return result.map(pool => pool.name).join(', ')

    }

    const getMinor = (athleteSession) => {
      if (athleteSession.session && choices.value) {
        const minorChoice = choices.value.find(choice => choice.id == athleteSession.session.u_athlete_is)
        if (minorChoice) {
          return minorChoice.u_label == "Minor" ? "Yes" : "No"
        }
      }
      return 'No'
    }

    const getPara = (athleteSession) => {
      if (athleteSession.session && choices.value) {
        const minorChoice = choices.value.find(choice => choice.id == athleteSession.session.u_athlete_is)
        if (minorChoice) {
          return minorChoice.u_label == "Para-Athlete" ? "Yes" : "No"
        }
      }
      return 'No'
    }


    const getLocationRedFlags = () => {
      if (!venueRedFlags.value) {
        return ''
      }
      const result = venueRedFlags.value.filter(flag => flag.u_red_flag_message != '')
      return result.map(flag => flag.u_red_flag_message).join(', ')
    }

    const getAthleteRedFlags = (athlete) => {
      if (!athlete) {
        return ''
      }
      const result = redFlags.value.filter(flag => flag.u_athlete == athlete.id && flag.u_red_flag_message != '')
      return result.map(flag => flag.u_red_flag_message).join(', ')
    }

    const getAthleteSampleTypes = (athleteSession) => {
      if (!athleteSession.session) {
        return ''
      }
      //TODO lookup - based on childTestRequirements ?
      return ''
    }

    const dcfs = ref({})
    const athletesData = computed(() => {
      return athleteSessionData.value.map(athleteSession => ({
        ...athleteSession.athlete,
        fullName: `${athleteSession.athlete.first_name} ${athleteSession.athlete.last_name}`,
        sport: getSport(athleteSession),
        pool: getPool(athleteSession.athlete),
        minor: getMinor(athleteSession),
        para: getPara(athleteSession),
        sampleTypes: getAthleteSampleTypes(athleteSession),
        redFlags: getAthleteRedFlags(athleteSession.athlete),
        dcfs: dcfs.value[athleteSession.athlete.id] || null,
        session: athleteSession.session
      }))
    })
    // TODO Fix this
    const expandedItems = ref(athletes.value.map(item => item.id))


    // Watch for changes in expand and load dcfs
    watch(expandedItems, async (newVal) => {
      for (let i = 0; i < newVal.length; i++) {
        const athlete = athletesData.value.find(data => data.id == newVal[i])
        if (athlete.dcfs === null) {
          dcfs.value[athlete.id] = await getDcfs(athlete)
        }
      }
    })

    const getUserName = (userName) => {
      if (!userName) {
        return ''
      }
      const user = systemUsers.value.find(user => user.user_name == userName)
      if (user) {
        return `${user.first_name} ${user.last_name}`
      }
      return userName;
    }

    const reportData = computed(() => {
      const reportsCombined = [
        ...dcoReports.value.map(report => ({ 
          report: report, 
          reportType: 'DCO Report',
          route:{ name: 'mission-dco-report', params: { id: report.id, missionId: props.missionId, } }
        })),
        ...supplementaryReports.value.map(report => ({ 
          report: report, 
          reportType: 'Supplementary Report',
          route:{ name: 'mission-supplementary-report', params: { id: report.id, missionId: props.missionId, isMissionType: true } }
        })),
        ...uarReports.value.map(report => ({ 
          report: report, 
          reportType: 'Mission UAR',
          route:{ name: 'mission-uar', params: { id: report.id, missionId: props.missionId, isMissionType: true } }
         })),
        ...pftcReports.value.map(report => ({ 
          report: report, 
          reportType: 'PFTC', 
          route:{ name: 'mission-pftc', params: { id: report.id, missionId: props.missionId, } }
        }))
      ] 
      if (reportsCombined.length === 0) {
        return []
      }
      return reportsCombined.map(x => {
        const url = router.resolve(x.route).href
        return {
          report: x.report,
          reportType: x.reportType,
          createdBy: getUserName(x.report.sys_created_by),
          createdOn: formatDateLocal(x.report.sys_created_on),
          updatedBy: getUserName(x.report.sys_updated_by),
          editUrl: url
        }
      })
    })

    const addSelectedFieldStaff = () => {
      showFieldStaffDialog.value = false
      //TODO add selected field staff
    }

    const formatTestingWindow = (mission) => {
      const start = formatDateLocal(mission.u_scheduled_start_date)
      const end = formatDateLocal(mission.u_scheduled_end_date)
      return `${start} to ${end}`
    }

    const getDcfs = async (athlete) => {
      let sessionIds = await sn_customerservice_test_session.selectKeys("u_mission_order", props.missionId)
      const dcfResults = await sn_customerservice_dcf.getBySessionsAndAthlete(sessionIds, athlete.id)
      const mappedDcfResults = dcfResults.map(dcf => {
        return {
          id: dcf.id,
          dcf: dcf,
          progress: 0,
          medicationCount: 0,
          bloodSampleCount: 0,
          urineSampleCount: 0,
        }
      })  
      
      // Process the progress of the Dcf based on required fields completed at each Stage
      mappedDcfResults.forEach(x => {
        x.progress = 0
        // Notification screen
        // TODO - add signature checks. Signatures not yet clear/confirmed - which fields to map to.
        if (x.dcf.u_notification_date != '' && x.dcf.u_notifying_chaperone !== '') {
          x.progress += 20
        }
        // Athlete Information
        // TODO - check required fields for this section
        if (x.dcf.u_arrival_time_at_dcs != '') {
          x.progress += 20
        }
        // Samples
        // TODO - more advanced logic - determine minimum required samples and check added with Sealed status etc

        // Medication
        // TODO - Not a required section - may be adding a new field Medications or Supplements to declare?  Yes/No
        if (x.medicationCount > 0) {
          x.progress += 20
        }
        // Sign off
        if (isTrue(x.dcf.u_sign_off_complete) || isTrue(x.dcf.u_locked)) {
          x.progress = 100
        }
      })
      return mappedDcfResults
    }

    const editDCF = async (dcf) => {

      router.push({
        name: 'dcf-notification',
        params: {
          'dcfId': dcf.id,
          'missionId': props.missionId
        }
      })
    }

    const addDCF = async (data) => {
      const dcf = await sn_customerservice_dcf.createNew()
      dcf.u_athlete = data.id
      dcf.u_test_session_id = data.session.id
      dcf.u_sport = data.session.u_sport
      dcf.u_discipline = data.session.u_discipline
      await sn_customerservice_dcf.save(dcf)
      await editDCF(dcf)
    }

    const progressColor = (progress) => {
      if (progress < 20) {
        return 'orange'
      } else if (progress < 60) {
        return 'yellow'
      } else if (progress < 80) {
        return 'green'
      } else {
        return 'blue'
      }
    }

    const athleteCreated = async (data) => {
      showAthleteDialog.value = false

      await customer_contact.save(data.customerContact)

      // create a new session for the athlete
      const session = await sn_customerservice_test_session.createNew()
      session.u_mission_order = props.missionId
      session.u_sport = data.sport
      session.u_discipline = data.discipline
      session.u_athlete = data.customerContact.id
      await sn_customerservice_test_session.save(session)

      // Update UI
      athleteSessionData.value.push({
        athlete: data.customerContact,
        session
      })

      athletes.value.push(data.customerContact)
    }

    return {
      mission,
      tab: null,
      isLoaded,
      athletes,
      athletesData,
      expandedItems,
      showAthleteDialog,
      athleteCreated,
      showFieldStaffDialog,
      showDocumentDialog,
      addSelectedFieldStaff,
      chaperoneOptions,
      editDCF,
      missionInfo,
      formatTestingWindow,
      missionInstructions,
      userTimeZone,
      getLocationRedFlags,
      athleteHeaders: [
        { title: 'Name', align: 'start', value: 'fullName' },
        { title: 'Sport/Discipline', value: 'sport' },
        { title: 'Pool', value: 'pool' },
        { title: 'Gender', value: 'gender' },
        { title: 'Age', value: 'u_age' },
        { title: 'Minor', value: 'minor' },
        { title: 'Para', value: 'para' },
        { title: 'Sample type/s', value: 'sampleTypes' },
        { title: 'Athlete red flags', value: 'redFlags' },
        { title: '', value: 'dcf' }
      ],
      reportHeaders: [
        { title: 'Type', value: 'reportType' },
        { title: 'Created By', value: 'createdBy' },
        { title: 'Created On', value: 'createdOn' },
        { title: 'Updated By', value: 'updatedBy' },
        { title: '', value: 'reportActions' },
      ],
      getDcfs,
      addDCF,
      reportData,
      progressColor
    }
  },

}
</script>

<style></style>
