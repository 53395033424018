import '@mdi/font/css/materialdesignicons.css'
import './assets/main.css'
import 'vuetify/styles'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import { en } from 'vuetify/locale'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'
import dal from '@/dal'
import { useAppStore } from './stores/app'

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_ENVIROMENT !== 'development') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        environment: import.meta.env.VITE_SENTRY_ENVIROMENT,
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

function getUserLocale() {
    return navigator.language || navigator.userLanguage || 'en-NZ';
}

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        iconfont: 'md' // Use Material Design Icons
    },
    locale: {
        locale: 'en', // Set the default locale
        fallback: 'en', // Set the fallback locale
        messages: { en } // Add the imported locale
    },
    date: {
        locale: {
            en: getUserLocale()
        }
    }
})

app.use(vuetify)
app.use(pinia)
app.use(router)

dal.open().then(async () => {
    const appStore = useAppStore()
    appStore.setLoading(false) // reset this state in case the app was reloaded while loading

    await appStore.fetchToken()

    app.mount('#app')
})
