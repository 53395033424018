<template>

<v-row>
  <v-col cols="12">
      <v-main>
        <div style="min-height: 100vh;">
          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <v-row style="margin-bottom: 20px;">
                <v-col cols="12" md="12">
                  <h2>Declaration of Medications / Supplements</h2>
                  <DividerWithIcons />
                  <p>
                    Please provide details of any prescription or non-prescription medications or supplements taken in the last seven days, including dosages if known
                  </p>
                </v-col>
              </v-row> 
              <v-row v-for="(medication, index) in medicationData" :key="index">
                <v-col cols="12" md="6" class="si-form">
                    <span class="font-italic si-top-label">Medication / Supplement</span>
                    <v-text-field v-model="medication.u_medication_supplement" label="" density="compact" bg-color="white" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="si-form">
                    <span class="font-italic si-top-label">Dose</span>
                    <v-text-field v-model="medication.u_dose" label="" density="compact" bg-color="white" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" md="12" class="si-form">
                    <span class="font-italic si-top-label">Comments</span>
                    <v-textarea class="no-label-textarea" v-model="medication.u_medication_supplement_comments"  label="" density="compact" bg-color="white">
                    </v-textarea>
                </v-col>
                <hr style="margin-bottom: 50px;">
              </v-row>

              <v-btn class="si-right-add-button" style="margin:30px 0;" @click="addNewMedication">
                <v-icon icon="mdi-plus"></v-icon>
              </v-btn>
              
            </v-container>
          </v-sheet>

          <v-container class="pa-0" fluid>
            <v-row class="d-flex justify-end">
                <v-col cols="12" md="3" class="d-flex">
                  <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
                    Save
                  </v-btn>
                </v-col>
            </v-row>
          </v-container>
        </div>
      </v-main>
  </v-col>
</v-row>

</template>

<script>
import { ref, onMounted, toRaw } from 'vue'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'
import sn_customerservice_declaration_of_medications_supplements from '@/dal/modules/sn_customerservice_declaration_of_medications_supplements'

export default {
  name: 'AthleteInformation',
  components: {
    DividerWithIcons
  },
  props: {
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
        type: String,
        required: true
    }
  },
  setup(props) {
    const dcf = ref({})
    const medicationData = ref([])

    const addNewMedication = async () => {
      try {
        let newMedication = await sn_customerservice_declaration_of_medications_supplements.createNew();
        newMedication.u_dcf_number = props.dcfId;
        medicationData.value.push(newMedication);
      } catch (error) {
        console.error('Error adding new medication:', error);
      }
    };

    onMounted(async () => {
      dcf.value = await sn_customerservice_dcf.get(props.dcfId)
      medicationData.value = await sn_customerservice_declaration_of_medications_supplements.getByDcf(props.dcfId)

    })

    async function save() {
      for (const medication of medicationData.value) { 
        if (medication.u_medication_supplement === '' && medication.u_dose === '' && medication.u_medication_supplement_comments === '') {
          return // Empty entry
        }
        let data = toRaw(medication)
        await sn_customerservice_declaration_of_medications_supplements.save(data)
      }
      await dal.commit()
    }

    return {
      tab: null,
      loading: false,
      addNewMedication,
      medicationData,
      dcf,
      save
    }
  }
}
</script>

<style></style>
