<template>
  <v-card v-if="urineSample">
    <v-toolbar color="#f97316" dark>
      <v-btn icon="mdi-close" @click="close"></v-btn>

      <v-toolbar-title><span v-if="!isEdit">Add</span> Urine Sample</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text="Save" variant="text" @click="save"></v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-sheet class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="6" md="3" class="si-form">
            <span v-if="isPartial">Was a partial sample collected?</span>
            <span v-else>Was a urine sample collected?</span>
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-if="isPartial" v-model="urineSample.u_was_a_partial_sample_collected" :color="switchColor"
              true-value="1" false-value="2"
              :label="isTrue(urineSample.u_was_a_partial_sample_collected) ? 'Yes' : 'No'" hide-details
              inset></v-switch>
            <v-switch v-else v-model="urineSample.u_was_urine_collected" :color="switchColor" true-value="1"
              false-value="2" :label="isTrue(urineSample.u_was_urine_collected) ? 'Yes' : 'No'" hide-details
              inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="!isTrue(urineSample.u_was_urine_collected) && !isPartial" class="supplementary-question">
          <v-col cols="6" md="3" class="si-form">
            Reason why urine sample was not collected?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-textarea v-model="urineSample.u_reason_why_urine_sample_was_not_collected"
              :required="!isTrue(urineSample.u_was_urine_collected) && !isPartial" :error-messages="v$.u_reason_why_urine_sample_was_not_collected.$errors.map(
                (e) => e.$message
              )
                " @blur="v$.u_reason_why_urine_sample_was_not_collected.$touch" label="Reason Details" density="compact"
              bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet v-if="isPartial" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <h2>Witnesses</h2>
            <DividerWithIcons is-compact="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" class="si-form">
            Did the DCO witness the partial sample provision?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-model="dcoWitnessed" :color="switchColor" :label="dcoWitnessed ? 'Yes' : 'No'" hide-details
              inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="dcoWitnessed" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form si-select-left-label">
            Witness Name
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-combobox v-model="selectedWitness" label="" density="compact" :items="witnessOptions" item-title="name"
              item-value="id" bg-color="white" @input="updateWitness"></v-combobox>
          </v-col>
        </v-row>

        <v-row v-if="dcoWitnessed" class="supplementary-question">
          <v-col cols="12" md="12" class="si-form">
            <v-card color="#2e4d23" variant="tonal" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-overline mb-1">Witness agrees:</div>
                  <div class="text-caption" style="font-size: 1rem !important">
                    The sample witnessing was carried our according to standard
                    procedures with any modifications or irregularities noted.
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="dcoWitnessed" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">Witness Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref="witnessSignature" :max-width="options.maxWidth" :min-width="options.minWidth"
                height="300px" :options="{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor
                }" />
            </div>
            <v-btn @click="clearWitnessSignature" class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <hr />

        <v-row>
          <v-col cols="6" md="6" class="si-form">
            Did a second SCP observer witness the sample provision? (Minor/Para athlete)
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-model="secondWitness" :color="switchColor" :label="secondWitness ? 'Yes' : 'No'" hide-details
              inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="secondWitness" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form"> Name / Role </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-text-field v-model="urineSample.u_scp_second_observer_name" style="padding-top: 20px" label=""
              density="compact" bg-color="white" type="text">
            </v-text-field>
          </v-col>
        </v-row>

        <hr />

        <v-row>
          <v-col cols="6" md="6" class="si-form">
            Did an additional second observer witness the sample provision (Minor/Para
            athlete)
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-model="additionalSecondWitness" :color="switchColor"
              :label="additionalSecondWitness ? 'Yes' : 'No'" hide-details inset></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="additionalSecondWitness" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form"> Name / Role </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-text-field v-model="urineSample.u_additional_observers" style="padding-top: 20px" label=""
              density="compact" bg-color="white" type="text">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <h2>Details</h2>
            <DividerWithIcons is-compact="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form"> Volume Collected (mls) </v-col>
          <v-col cols="6" md="3" class="si-form">
            <v-text-field v-if="isPartial" v-model="urineSample.u_partial_sample_volume_mls" style="padding-top: 20px" label=""
              density="compact" bg-color="white" type="number">
            </v-text-field>
            <v-text-field v-else v-model="urineSample.u_volume_of_urine_collected_ml" style="padding-top: 20px" label=""
              density="compact" bg-color="white" type="number">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            <span v-if="isPartial">Partial Sample Kit Number</span>
            <span v-else>Kit Used</span>
          </v-col>
          <v-col cols="4" md="3" class="si-form">
            <v-combobox v-model="selectedKit" label="" density="compact" :items="kitOptions" item-title="serial_number"
              @update:model-value="updateKit" item-value="id" bg-color="white"></v-combobox>
          </v-col>
          <v-col cols="2" md="2" class="si-form si-select-left-label" v-if="isLoading">
            <v-btn class="si-right-action-button" @click="handleOnCanStop" size="large">
              <v-icon icon="mdi-stop-circle"></v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2" md="2" class="si-form si-select-left-label" v-else>
            <v-btn class="si-right-action-button" @click="handleOnCanPlay" size="large">
              <v-icon icon="mdi-barcode-scan"></v-icon>
            </v-btn>
            <v-btn v-if="decode" class="si-right-action-button" @click="handleOnReset" size="large">
              <v-icon icon="mdi-refresh"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <StreamQrcodeBarcodeReader ref="refCamera" capture="shoot" show-on-stream @onloading="onLoading"
          @result="onResult" />
        <v-bottom-sheet v-model="showScanError" inset>
          <v-card class="text-center" height="200">
            <v-card-text>
              <v-btn class="mt-6" style="background-color: #758f01; color: white !important"
                @click="showScanError = !showScanError">
                Close
              </v-btn>
              <br />
              <br />
              <div>
                {{ scanErrorDetails }}
              </div>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
        <v-row>
          <v-col cols="6" md="3" class="si-form"> Manufacturer </v-col>
          <v-col cols="6" md="3" class="si-form">
            <v-text-field readonly style="padding-top: 20px" label="" density="compact" bg-color="white" type="text">
              {{ getManufacturer(selectedKit) }}
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form si-select-left-label">
            Sealed Time
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <div style="display: flex">
              <v-text-field v-model="sealedDate" label="" density="compact" bg-color="white" type="date" required
                :error-messages="w$.sealedDate.$errors.map(e => e.$message)" @blur="w$.sealedDate.$touch"
                @input="updateSealedDate">
              </v-text-field>
              <v-text-field v-model="sealedTime" label="" density="compact" bg-color="white" type="time" required
                :error-messages="w$.sealedTime.$errors.map(e => e.$message)" @blur="w$.sealedTime.$touch"
                @input="updateSealedTime">
              </v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!isPartial">
          <v-col cols="6" md="3" class="si-form"> Specific Gravity </v-col>
          <v-col cols="6" md="3" class="si-form">
            <div style="display: flex">
              <div class="si-specifc-gravity-prefix">
                <div>
                  <span class="si-specifc-gravity-digit">1</span>
                </div>
                <div>
                  <span class="si-specifc-gravity-digit">.</span>
                </div>
              </div>
              <v-otp-input v-model="specificGravity" length="3" color="white" variant="underlined"></v-otp-input>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!isPartial && specificGravity && underGravity(specificGravity)">
          <v-col cols="12" md="12" class="si-form">
            <v-card color="primary" variant="elevated" class="mx-auto">
              <v-card-item>
                <div>
                  <div class="text-caption" style="font-size: 1.2rem !important">
                    If the specific gravity number is under
                    <strong>1.005</strong> the athlete needs to provide another
                    sample, <strong>UNLESS</strong> the "Volume (ml)" is greater
                    than <strong>150</strong> ml <strong>AND</strong> the
                    specific gravity is greater than <strong>1.003</strong>, in
                    which case an additional sample is not required.
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <h2>Modifications / Irregularities / Comments</h2>
            <DividerWithIcons is-compact="true" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" class="si-form">
            Were there any modifications or irregularities to standard procedures?
          </v-col>
          <v-col cols="6" md="6" class="si-form">
            <v-switch v-if="isPartial"
              v-model="urineSample.u_were_there_any_modifications_or_irregularities_to_standard_partial_sample_proc"
              true-value="1" false-value="2" :color="switchColor"
              :label="isTrue(urineSample.u_were_there_any_modifications_or_irregularities_to_standard_partial_sample_proc) ? 'Yes' : 'No'"
              hide-details inset></v-switch>
            <v-switch v-else v-model="urineSample.u_were_any_modifications_made_in_collecting_the_sample" true-value="1"
              false-value="2" :color="switchColor"
              :label="isTrue(urineSample.u_were_any_modifications_made_in_collecting_the_sample) ? 'Yes' : 'No'"
              hide-details inset></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="si-form">
            <v-textarea v-if="isPartial" v-model="urineSample.u_partial_sample_comments" label="Comments"
              density="compact" bg-color="white">
            </v-textarea>
            <v-textarea v-else v-model="urineSample.u_urine_sample_comments"
              :required="isTrue(urineSample.u_were_any_modifications_made_in_collecting_the_sample)" :error-messages="v$.u_urine_sample_comments.$errors.map(
                (e) => e.$message
              )
                " @blur="v$.u_urine_sample_comments.$touch" label="Comments" density="compact" bg-color="white">
            </v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet v-if="isPartial" class="si-form-sheet si-fullscreen-modal sample-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <h2>DCO</h2>
            <DividerWithIcons />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="3" class="si-form">
            <span class="si-top-label">DCO Name </span>
            <v-combobox v-model="selectedDco" label="" density="compact" :items="dcoList" item-title="name"
              @update:model-value="updateDco" :error-messages="w$.selectedDco.$errors.map(e => e.$message)"
              @blur="w$.selectedDco.$touch"
              item-value="id" bg-color="white"></v-combobox>
          </v-col>
        </v-row>
        <v-row class="supplementary-question" style="align-items: center">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">DCO Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref="dcoSignature" :max-width="options.maxWidth" :min-width="options.minWidth"
                height="300px" :options="{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor
                }" />
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn @click="clearDCOSignature" class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isPartial">
          <v-col cols="12" md="12">
            <h2>Athlete</h2>
            <DividerWithIcons />
          </v-col>
        </v-row>
        <v-row v-if="isPartial" class="supplementary-question">
          <v-col cols="6" md="6" class="si-form-signature">
            <span class="font-italic si-top-label">Athlete Signature </span>
            <div class="si-signature-field">
              <vue-signature-pad ref="athleteSignature" :max-width="options.maxWidth" :min-width="options.minWidth"
                height="300px" :options="{
                  penColor: options.penColor,
                  backgroundColor: options.backgroundColor
                }" />
            </div>
          </v-col>
          <v-col cols="6" md="6">
            <v-btn @click="clearAthleteSignature" class="si-right-action-button" size="large">
              <v-icon icon="mdi-undo"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    
    <v-container class="pa-0" fluid>
      <v-row class="d-flex justify-end">
        <v-col cols="12" md="3" class="d-flex">
          <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import { StreamQrcodeBarcodeReader } from 'vue3-barcode-qrcode-reader'
import { playBeep } from '@/utils/utils.js'
import { useMissionStore } from '@/stores/mission'
import { useUrineSampleStore } from '@/stores/urineSample'
import sn_customerservice_urine_samples from '@/dal/modules/sn_customerservice_urine_samples'
import cmdb_model from '@/dal/modules/cmdb_model'
import core_company from '@/dal/modules/core_company'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'

export default {
  name: 'UrineSampleEdit',
  components: {
    DividerWithIcons,
    VueSignaturePad,
    StreamQrcodeBarcodeReader
  },
  props: {
    id: {
      type: String,
      required: true
    },
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
      type: String,
      required: true
    },
    testType: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const dcf = ref()
    const urineSample = ref()
    const isLoaded = ref(false)
    const isPartial = ref(false)
    const dcoWitnessed = ref(false)
    const dcoList = ref([])
    const chaperoneList = ref([])
    const leadDco = ref(null)
    const selectedWitness = ref(null)
    const witnessSignature = ref(null)
    const athleteSignature = ref(null)
    const selectedDco = ref(null)
    const dcoSignature = ref(null)
    const secondWitness = ref(false)
    const additionalSecondWitness = ref(false)
    const sealedDate = ref(null)
    const sealedTime = ref(null)
    const decodedText = ref('')
    const specificGravity = ref('')
    const permittedToShower = ref(false)
    const modificationsOrIrregularities = ref(false)
    const kitModelList = ref([])
    const kitManufacturerList = ref([])
    const kitOptions = ref([])
    const selectedKit = ref(null)
    const showScanError = ref(false)
    const scanErrorDetails = ref('Scan Error. Please try again')
    const missionStore = useMissionStore()
    const urineSampleStore = useUrineSampleStore()
    const validationError = ref(false)
    const specificGravityError = ref(false)

    const options = ref({
      penColor: 'rgb(0,0,0)',
      backgroundColor: 'rgb(255, 255, 255)',
      maxWidth: 2,
      minWidth: 2
    })

    function convertSpecificGravity(value) {
      // Check value is a string and starts with "1."
      if (typeof value === 'string' && value.startsWith('1.')) {
        const digits = value.slice(2).padEnd(3, '0').slice(0, 3)
        return digits.split('');
      }
      return ['0', '0', '0'];
    }

    onMounted(async () => {
      try {
        if (props.testType === 'partial') {
          isPartial.value = true
        }

        await missionStore.loadMission(props.missionId)
        chaperoneList.value = missionStore.chaperones
        dcoList.value = missionStore.dcoList
        leadDco.value = missionStore.dco

        await urineSampleStore.loadHardware()
        kitOptions.value = urineSampleStore.hardwareList
        kitOptions.value = kitOptions.value.filter(
          (kit) => kit.serial_number !== '' && kit.serial_number !== null
        )
        kitModelList.value = await cmdb_model.getAll()
        kitManufacturerList.value = await core_company.select(
          'u_type',
          'test_kit_manufacturer'
        )

        if (props.id && props.id !== 'new') {
          urineSample.value = await sn_customerservice_urine_samples.get(props.id)

          if(urineSample.value.u_urine_kit_used ) {
            selectedKit.value = kitOptions.value.find(x => x.id === urineSample.value.u_urine_kit_used)
          }

          if (isPartial.value) {

            if (urineSample.value.u_partial_sample_sealed_time) {
              sealedDate.value = dateTimePickerInitialise('date', urineSample.value.u_partial_sample_sealed_time)
                sealedTime.value = dateTimePickerInitialise('time', urineSample.value.u_partial_sample_sealed_time)
            }


          } else { // Full Sample

            if (urineSample.value.u_urine_sealed_time) {
              sealedDate.value = dateTimePickerInitialise('date', urineSample.value.u_urine_sealed_time)
                sealedTime.value = dateTimePickerInitialise('time', urineSample.value.u_urine_sealed_time)
            }

            if (urineSample.value.u_specific_gravity && urineSample.value.u_specific_gravity.length > 2) {
              specificGravity.value = convertSpecificGravity(urineSample.value.u_specific_gravity)
            }

          }
          
          // set boolean fields that don't exist in the table at this stage - based on having a value
          if (urineSample.value.u_scp_second_observer_name != '') {
            secondWitness.value = true
          }
          if (urineSample.value.u_additional_observers != '') {
            additionalSecondWitness.value = true
          }
          if (urineSample.value.u_witness_name != '') {
            selectedWitness.value = witnessOptions.value.find(x => x.id === urineSample.value.u_witness_name)
          }
          if (urineSample.value.u_partial_sample_dco != '') {
            selectedDco.value = dcoList.value.find(x => x.id === urineSample.value.u_partial_sample_dco)
          }

        } else {
          // create new Urine Sample
          urineSample.value = await sn_customerservice_urine_samples.createNew()
          urineSample.value.u_dcf_number = props.dcfId

        }
      } catch (error) {
        console.error('Error:', error)
      } finally {
        isLoaded.value = true
      }
    })

    const updateWitness = (value) => {
      selectedWitness.value = value
      urineSample.value.u_witness_name = value.id
    }

    const updateDco = (value) => {
      if (isPartial.value) {
        urineSample.value.u_partial_sample_dco = value.id
      } else {
        urineSample.value.u_dco_name_urine = value.id
      }
      selectedDco.value = value
    }
    
    const updateSealedDate = (value) => {
      if (isPartial.value) {
        urineSample.value.u_partial_sample_sealed_time = dateTimePickerUpdate('date', value, urineSample.value.u_partial_sample_sealed_time)
      } else {
        urineSample.value.u_urine_sealed_time = dateTimePickerUpdate('date', value, urineSample.value.u_urine_sealed_time)
      }
    }

    const updateSealedTime = (value) => {
      if (isPartial.value) {
        urineSample.value.u_partial_sample_sealed_time = dateTimePickerUpdate('time', value, urineSample.value.u_partial_sample_sealed_time)
      } else {
        urineSample.value.u_urine_sealed_time = dateTimePickerUpdate('time', value, urineSample.value.u_urine_sealed_time)
      }
    }

    const updateKit = (value) => {
      selectedKit.value = value
      if (isPartial.value) {
        urineSample.value.u_blood_passport_kit_used = value.id
      } else {
        urineSample.value.u_urine_kit_used = value.id
      }
    }

    const getManufacturer = (selectedKit) => {
      if (selectedKit) {
        const model = kitModelList.value.find((model) => model.id === selectedKit.model)
        if (model) {
          const manufacturer = kitManufacturerList.value.find(
            (manufacturer) => manufacturer.id === model.manufacturer
          )
          urineSample.value.u_urine_manufacturer = manufacturer.id
          return manufacturer ? manufacturer.name : ''
        }
      }
      return ''
    }

    const clearWitnessSignature = () => {
      if (witnessSignature.value) {
        witnessSignature.value.clearCanvas()
      }
    }

    const clearDCOSignature = () => {
      if (dcoSignature.value) {
        dcoSignature.value.clearCanvas()
      }
    }

    const clearAthleteSignature = () => {
      if (athleteSignature.value) {
        athleteSignature.value.clearCanvas()
      }
    }

    const underGravity = (value) => {
      if (value.length == 3) {
        const combinedValue = parseFloat(`1.${value}`)
        urineSample.value.u_specific_gravity = `1.${value}`
        return (combinedValue < 1.005 && urineSample.value.u_volume_of_urine_collected_ml < 150
          || combinedValue < 1.003 && urineSample.value.u_volume_of_urine_collected_ml > 150)
      } else {
        return false
      }
    }

    const decode = ref(undefined)
    const isLoading = ref(false)

    function onResult(data) {
      if (data) {
        playBeep()
        const scannedBarcode = data
        const selectedKit = kitOptions.value.find(
          (kit) => kit.serial_number === scannedBarcode
        )
        if (!selectedKit) {
          scanErrorDetails.value =
            'The code scanned: ' + data + ' was not found. Please try again.'
          showScanError.value = true
          return
        }
        decode.value = data
      }
    }

    function onLoading(loading) {
      isLoading.value = loading
    }

    // define ref for component
    const refCamera = ref(null)

    function handleOnCanPlay() {
      refCamera.value?.onCanPlay()
    }

    function handleOnReset() {
      refCamera.value?.onReset()
    }

    function handleFacemode() {
      refCamera.value?.onChangeFacemode()
    }

    function handleOnCanStop() {
      refCamera.value?.onCanStop()
    }

    const witnessOptions = computed(() => {
      return dcoWitnessed.value ? dcoList.value : chaperoneList.value
    })

    const urineSampleRules = computed(() => {
      const localRules = {
        u_reason_why_urine_sample_was_not_collected: {},
        u_urine_sample_comments: {},
        u_scp_second_observer_name: {},
        u_additional_observers: {},
        u_volume_of_urine_collected_ml: {},
        u_partial_sample_volume_mls: {}
      }
      if(isPartial.value) {
        // Partial Urine Sample
        localRules.u_partial_sample_volume_mls = {
          required
        }
        if (secondWitness.value) {
          localRules.u_scp_second_observer_name = {
            required
          }
        }
        if (additionalSecondWitness.value) {
          localRules.u_additional_observers = {
            required
          }
        }
      } else {
        // Full Urine Sample
        localRules.u_volume_of_urine_collected_ml = {
          required
        }
        if (urineSample.value && !isTrue(urineSample.value.u_was_urine_collected)) {
          localRules.u_reason_why_urine_sample_was_not_collected = {
            required
          }
        }
        if (urineSample.value && isTrue(urineSample.value.u_were_any_modifications_made_in_collecting_the_sample)) {
          localRules.u_urine_sample_comments = {
            required
          }
        }
      }
      return localRules
    })

    const variableRules = computed(() => {
      const localRules = {
        specificGravity: {},
        selectedDco: { },
        selectedKit: { required },
        sealedDate: { required, mustBeValidDate: mustBeValidDate() },
        sealedTime: { required, mustBeValidTime: mustBeValidTime() }
      }
      if (!isPartial.value) {
        localRules.specificGravity = {
          required
        }
        localRules.selectedDco = {
          required
        }
      }
      return localRules
    })

    const v$ = useVuelidate(urineSampleRules, { urineSample })
    const w$ = useVuelidate(variableRules, { specificGravity, selectedKit, sealedDate, sealedTime, selectedDco })


    const close = () => {
      emit('close')
    }

    async function save() {
      v$.value.$validate()
      w$.value.$validate()
      if (v$.value.$invalid || w$.value.$invalid) {
        validationError.value = true
        return
      }
      let data = toRaw(urineSample.value)
      await sn_customerservice_urine_samples.save(data)
      await dal.commit()
    }

    return {
      switchColor: '#2e4d23',
      dcf,
      tab: null,
      isLoaded,
      isPartial,
      close,
      save,
      dcoWitnessed,
      chaperoneList,
      dcoList,
      witnessOptions,
      selectedWitness,
      witnessSignature,
      clearWitnessSignature,
      athleteSignature,
      clearAthleteSignature,
      dcoSignature,
      clearDCOSignature,
      options,
      updateWitness,
      secondWitness,
      additionalSecondWitness,
      selectedDco,
      updateDco,
      sealedDate,
      sealedTime,
      updateSealedDate,
      updateSealedTime,
      decodedText,
      decode,
      isLoading,
      onResult,
      onLoading,
      refCamera,
      handleOnCanPlay,
      handleOnReset,
      handleFacemode,
      handleOnCanStop,
      specificGravity,
      underGravity,
      permittedToShower,
      modificationsOrIrregularities,
      selectedKit,
      kitOptions,
      updateKit,
      showScanError,
      scanErrorDetails,
      getManufacturer,
      isTrue,
      validationError,
      specificGravityError,
      urineSample,
      v$,
      w$
    }
  }
}
</script>

<style></style>
