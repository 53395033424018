<template>
  <v-row>
    <v-col cols="12">
      <v-main>
        <div v-if="dcf" style="min-height: 100vh;">
          <v-sheet class="si-form-sheet si-questionnaire text-body-2 mx-auto">
            <v-container fluid>
              <v-row style="margin-bottom: 20px;">
                <v-col cols="12" md="12">
                  <h2>Consent for research</h2>
                  <DividerWithIcons />
                  <p>
                    In order to help combat doping in sport, I agree that my sample(s) may be used for anti-doping
                    research purposes. When all analyses have been completed and my sample(s) would otherwise be
                    discarded, it (they) may be used by any WADA-Accredited Lab for anti-doping research of any type,
                    provided that it (they) can no longer be identified as my sample(s).
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="3" class="si-form">
                  Consent for research
                </v-col>
                <v-col cols="6" md="6" class="si-form">
                  <v-switch v-model="dcf.u_consent_for_research"
                    true-value="1" false-value="2" :color="switchColor"
                    :label="isTrue(dcf.u_consent_for_research) ? 'Yes' : 'No'" hide-details
                    inset></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet si-questionnaire text-body-2 mx-auto">
            <v-container fluid>
              <v-row style="margin-bottom: 20px;">
                <v-col cols="12" md="12">
                  <h2>Athlete Privacy Policy</h2>
                  <DividerWithIcons />
                  <p>I understand and agree that:
                  <ol style="padding: 30px;">
                    <li>A sample is biological material collected for the purposes of Doping Control. Doping Control
                      means all steps and processes from test distribution planning through to ultimate disposition of
                      any appeal including all steps and processes in between such as provision of whereabouts
                      information, Sample Collection and handling, laboratory analysis, therapeutic use exemptions,
                      results management and hearings.</li>
                    <li>My sample may be analysed to detect Prohibited Substances and Prohibited Methods identified on
                      the Prohibited List, and other substances as may be directed by WADA pursuant to Article 4.5 of
                      the World Anti-Doping Code, or to assist the authorised Anti-Doping Organisation in profiling
                      relevant parameters in my urine, blood or other matrix, including DNA or genomic profiling, or for
                      any other legitimate anti-doping purpose.</li>
                    <li>The authorised Anti-Doping Organisation will receive: the analytical results of my Sample
                      Collection; any reports from my doping control officer indicating my possible refusal or failure
                      to submit to Sample Collection including providing the required information; other information
                      relating to any possible anti-doping rule violation and other documentation from my sample
                      collection. </li>
                    <li>The authorised Anti-Doping Organisation may collect, store, process, or disclose information
                      related to doping control, including but not limited to, laboratory results and possible
                      sanctions, where necessary and appropriate to conduct its anti-doping activities under the Sports
                      Anti-Doping Rules and the World Anti-Doping Code. The information may be shared with WADA, other
                      Anti-Doping Organisations, International Federations, National Governing Bodies of Sports, the New
                      Zealand Olympic Committee or Paralympics New Zealand and any other relevant bodies for anti-doping
                      purposes. Disclosures may be outside New Zealand. The information may be provided to other
                      agencies if required by law.</li>
                    <li>I have rights of access to and correction of personal information as provided by the Privacy Act
                      2020. I also have rights with respect of the information as provided under the International
                      Standards for the Protection of Privacy and Personal Information (ISPPPI) including the procedure
                      for submitting complaints pursuant to Article 11.5. Any compliant should be submitted to
                      privacy@drugfreesport.org.nz </li>
                    <li>Any such collection, storage, processing or disclosure will comply with the Sports Anti-Doping
                      Rules (as applicable), the World Anti-Doping Code (including the International Standard for
                      Testing and Investigations and the International Standard for the Protection of Privacy and
                      Personal Information) and the applicable law (including the Privacy Act 2020).</li>
                    <li>I acknowledge that I have had the opportunity to read the Athlete Privacy Policy - Athlete
                      Privacy Policy | Drug Free Sport New Zealand.</li>
                  </ol>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" md="6" class="si-form">
                  I have been given the opportunity to read the Sport Integrity Commission Sample Declaration
                </v-col>
                <v-col cols="6" md="6" class="si-form">
                  <v-switch v-model="dcf.u_i_have_read_the_dfsnz_sample_collection_declaration"
                  true-value="true" false-value="false" :color="switchColor"
                  :label="isTrue(dcf.u_i_have_read_the_dfsnz_sample_collection_declaration) ? 'Yes' : 'No'" hide-details
                  inset></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
              <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="12">
                        <h2>Comments</h2>
                        <DividerWithIcons is-compact="true" />
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="si-form-inline" >
                        <v-textarea v-model="dcf.u_athlete_comments" label="Athlete comments" density="compact" bg-color="white">
                        </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="si-form-inline" >
                        <v-textarea v-model="dcf.u_dco_comments" label="DCO comments" density="compact" bg-color="white">
                        </v-textarea>
                    </v-col>
                  </v-row>
              </v-container>
            </v-sheet>

            <v-sheet class="si-form-sheet si-questionnaire text-body-2 mx-auto">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <h2>Signatures and sign off</h2>
                    <DividerWithIcons is-compact="true" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Is there an Athlete representative?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_is_there_an_athlete_representative"
                    true-value="true" false-value="false" :color="switchColor"
                    :label="isTrue(dcf.u_is_there_an_athlete_representative) ? 'Yes' : 'No'" hide-details
                    inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_is_there_an_athlete_representative)" class="supplementary-question">
                  <v-col cols="12" md="6" class="si-form">
                      <span class="font-italic si-top-label">Athlete Representative Name</span>
                      <div class="si-form-inline">
                        <v-text-field v-model="dcf.u_athlete_representative_first_name" label="First Name" density="compact"
                          :required="isTrue(dcf.u_is_there_an_athlete_representative)"
                          :error-messages="v$.u_athlete_representative_first_name.$errors.map(e => e.$message)"
                          @blur="v$.u_athlete_representative_first_name.$touch" bg-color="white" type="text"></v-text-field>
                        <v-text-field v-model="dcf.u_athlete_representative_last_name" label="Surname" density="compact"
                          :required="isTrue(dcf.u_is_there_an_athlete_representative)"
                          :error-messages="v$.u_athlete_representative_last_name.$errors.map(e => e.$message)"
                          @blur="v$.u_athlete_representative_last_name.$touch" bg-color="white" type="text"></v-text-field>
                      </div>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <span class="font-italic si-top-label">Athlete Representative Role</span>
                    <v-text-field v-model="dcf.u_athlete_representative_role" label="" density="compact" bg-color="white"
                      :required="isTrue(dcf.u_is_there_an_athlete_representative)"
                      :error-messages="v$.u_athlete_representative_last_name.$errors.map(e => e.$message)"
                      @blur="v$.u_athlete_representative_last_name.$touch" 
                    type="text"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_is_there_an_athlete_representative)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form-signature">
                    <span class="font-italic si-top-label">Athlete Representative Signature </span>
                    <div class="si-signature-field">
                        <vue-signature-pad
                            ref='athleteRepSignature'
                            :max-width='options.maxWidth'
                            :min-width='options.minWidth'
                            height="300px"
                            :options='{
                            penColor: options.penColor,
                            backgroundColor: options.backgroundColor,
                            }'
                        />
                    </div>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn @click='clearAthleteRepSignature' class="si-right-action-button" size="large">
                      <v-icon icon="mdi-undo"></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="si-form-gap"></div>
                <v-row>
                  <v-col cols="6" md="3" class="si-form">
                    <span class="si-top-label">Doping Collection Official </span>
                    <v-combobox v-model="selectedDco" label="" density="compact" :items="dcoList" item-title="name"
                      @update:model-value="updateDco" :error-messages="w$.selectedDco.$errors.map(e => e.$message)"
                      @blur="w$.selectedDco.$touch" item-value="id" bg-color="white"></v-combobox>
                  </v-col>
                </v-row>
                <v-row class="supplementary-question"  style="align-items: center;">
                  <v-col cols="6" md="6" class="si-form-signature">
                    <span class="font-italic si-top-label">Doping Control Officer Signature </span>
                    <div class="si-signature-field">
                        <vue-signature-pad
                            ref='dcoSignature'
                            :max-width='options.maxWidth'
                            :min-width='options.minWidth'
                            height="300px"
                            :options='{
                            penColor: options.penColor,
                            backgroundColor: options.backgroundColor,
                            }'
                        />
                    </div>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-btn @click='clearDCOSignature' class="si-right-action-button" size="large">
                        <v-icon icon="mdi-undo"></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                
                <v-row>
                  <v-col cols="12" md="12" class="si-form-inline">
                      <p>
                        Copy of DCF to be emailed to Athelete?
                      </p>
                      <v-checkbox v-model="dcf.u_email_a_copy_of_dcf_to_athlete" 
                        true-value="true" false-value="false" density="compact" bg-color="white" label="">
                      </v-checkbox>
                  </v-col>
                </v-row>
                <div class="si-form-gap"></div>
                <v-row>
                  <v-col cols="12" md="12" class="si-form-inline">
                    <p class="">I declare that, subject to any comments made above, I am satisfied with….. </p>
                  </v-col>
                  <v-col cols="12" md="12" class="si-form-inline">
                    <br>
                    <v-btn @click="showAthletePolicy = true" variant="outlined">
                      View Athlete Privacy Policy
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="si-form-gap"></div>
              <v-row>
                <v-col cols="6" md="6" class="si-form-signature">
                  <span class="font-italic si-top-label">Athlete Signature </span>
                  <div class="si-signature-field">
                    <vue-signature-pad ref='athleteSignature' :max-width='options.maxWidth'
                      :min-width='options.minWidth' height="300px" :options='{
                        penColor: options.penColor,
                        backgroundColor: options.backgroundColor,
                      }' />
                  </div>
                </v-col>
                <v-col cols="6" md="6">
                  <v-btn @click='clearAthleteSignature' class="si-right-action-button" size="large">
                    <v-icon icon="mdi-undo"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="3" class="si-form si-select-left-label">
                  Date and Time DCF Completed
                </v-col>
                <v-col cols="6" md="6" class="si-form">
                  <div style="display: flex;">
                    <v-text-field v-model="dcfCompletedDate" label="" density="compact" bg-color="white" type="date"
                      :error-messages="w$.dcfCompletedDate.$errors.map(e => e.$message)" @blur="w$.dcfCompletedDate.$touch"
                      @input="updateDcfCompletedDate">
                    </v-text-field>
                    <v-text-field v-model="dcfCompletedTime" label="" density="compact" bg-color="white" type="time"
                      :error-messages="w$.dcfCompletedTime.$errors.map(e => e.$message)" @blur="w$.dcfCompletedTime.$touch"
                      @input="updateDcfCompletedTime">
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" class="si-form-inline">
                  <p>
                    Sign off complete?
                  </p>
                  <v-checkbox v-model="dcf.u_sign_off_complete" 
                    true-value="true" false-value="false" density="compact" bg-color="white" label="">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="6" class="si-form-inline">
                  <p>
                    Locked?
                  </p>
                  <v-checkbox v-model="dcf.u_locked" 
                    true-value="true" false-value="false" density="compact" bg-color="white" label="">
                  </v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12" class="si-form">
                  <span class="font-italic si-top-label">DCF unlocked comments </span>
                  <v-textarea class="no-label-textarea" v-model="dcf.u_sign_off_comments" label="" density="compact" bg-color="white">
                  </v-textarea>
                </v-col>
              </v-row>

            </v-container>
          </v-sheet>
          
          <v-container class="pa-0" fluid>
            <v-row class="d-flex justify-end">
                <v-col cols="12" md="3" class="d-flex">
                  <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                      variant="tonal">
                      Save
                  </v-btn>
                </v-col>
            </v-row>
          </v-container>

        </div>
      </v-main>
    </v-col>
  </v-row>
  <v-snackbar
    v-model="validationError"
  >
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn
        color="red"
        variant="text"
        @click="validationError = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  
  <v-dialog v-model="showAthletePolicy" transition="dialog-bottom-transition">
      <v-card title="Athlete Privacy Policy" style="margin-left: auto;
        margin-right: auto;
        min-width: 80vw;">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" class="si-athlete-policy">
               <p>I understand and agree that:</p>
               <p>
                  <ol>
                     <li>
                           A sample is biological material collected for the purposes of Doping Control. Doping Control means all steps and processes from test distribution planning through to ultimate disposition of any appeal including all steps and processes in between such as provision of whereabouts information, Sample Collection and handling, laboratory analysis, therapeutic use exemptions, results management and hearings.
                     </li>
                     <li>
                           My sample may be analysed to detect Prohibited Substances and Prohibited Methods identified on the Prohibited List, and other substances as may be directed by WADA pursuant to Article 4.5 of the World Anti-Doping Code, or to assist the authorised Anti-Doping Organisation in profiling relevant parameters in my urine, blood or other matrix, including DNA or genomic profiling, or for any other legitimate anti-doping purpose.
                     </li>
                     <li>
                           The authorised Anti-Doping Organisation will receive: the analytical results of my Sample Collection; any reports from my doping control officer indicating my possible refusal or failure to submit to Sample Collection including providing the required information; other information relating to any possible anti-doping rule violation and other documentation from my sample collection.
                     </li>
                     <li>
                           The authorised Anti-Doping Organisation may collect, store, process, or disclose information related to doping control, including but not limited to, laboratory results and possible sanctions, where necessary and appropriate to conduct its anti-doping activities under the Sports Anti-Doping Rules and the World Anti-Doping Code. The information may be shared with WADA, other Anti-Doping Organisations, International Federations, National Governing Bodies of Sports, the New Zealand Olympic Committee or Paralympics New Zealand and any other relevant bodies for anti-doping purposes. Disclosures may be outside New Zealand. The information may be provided to other agencies if required by law.
                     </li>
                     <li>
                           I have rights of access to and correction of personal information as provided by the Privacy Act 2020. I also have rights with respect of the information as provided under the International Standards for the Protection of Privacy and Personal Information (ISPPPI) including the procedure for submitting complaints pursuant to Article 11.5. Any compliant should be submitted to privacy@drugfreesport.org.nz.
                     </li>
                     <li>
                           Any such collection, storage, processing or disclosure will comply with the Sports Anti-Doping Rules (as applicable), the World Anti-Doping Code (including the International Standard for Testing and Investigations and the International Standard for the Protection of Privacy and Personal Information) and the applicable law (including the Privacy Act 2020).
                     </li>
                     <li>
                           I acknowledge that I have had the opportunity to read the Athlete Privacy Policy - <a href="https://drugfreesport.org.nz/athlete-privacy-policy" target="_blank">Athlete Privacy Policy | Drug Free Sport New Zealand</a>.
                     </li>
                  </ol>
               </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Close" variant="plain" @click="showAthletePolicy = false"></v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>

import { ref, onMounted, computed, toRaw } from 'vue'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf';
import { useDcfStore } from '@/stores/dcf'
import { useMissionStore } from '@/stores/mission'
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'

export default {
  name: 'AthleteInformation',
  components: {
    DividerWithIcons,
    VueSignaturePad
  },
  props: {
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
      type: String,
      required: true
    }
  },
  setup(props) {

    const dcfStore = useDcfStore()
    const dcf = ref({})
    const missionStore = useMissionStore()
    const isLocked = computed(() => dcfStore.isLocked)

    const hasConsented = ref(false)
    const haveReadDeclaration = ref(false)
    const athleteRepPresent = ref(false)
    const athleteRepSignature = ref(null)
    const dcoSignature = ref(null)
    const selectedDco = ref(null)
    const athleteSignature = ref(null)
    const dcfCompletedDate = ref(null)
    const dcfCompletedTime = ref(null)
    const emailCopy = ref(false)
    const signOffComplete = ref(false)
    const showAthletePolicy = ref(false)
    const validationError = ref(false)
    const dcoList = ref([])
    
    const options = ref({
      penColor: 'rgb(0,0,0)',
      backgroundColor: 'rgb(255, 255, 255)',
      maxWidth: 2,
      minWidth: 2,
    })

    onMounted(async () => {

      dcf.value = await sn_customerservice_dcf.get(props.dcfId)
      
      await missionStore.loadMission(props.missionId)
      dcoList.value = missionStore.dcoList

      // TODO: once completed set, 10 minute countdown to unlock and edit.
      if (dcf.value.u_date_and_time_completed) {
          dcfCompletedDate.value = dateTimePickerInitialise('date', dcf.value.u_date_and_time_completed)
          dcfCompletedTime.value = dateTimePickerInitialise('time', dcf.value.u_date_and_time_completed)
      }
      
      if (dcf.value.u_dco_name != '') {
          selectedDco.value = dcoList.value.find(x => x.id === dcf.value.u_dco_name)
      }

      if (dcf.value.u_athlete_representative_signature != '') {
        athleteRepSignature.value.fromDataURL(dcf.value.u_athlete_representative_signature);
      }
      if (dcf.value.u_dco_signature != '') {
        dcoSignature.value.fromDataURL(dcf.value.u_dco_signature);
      }
      if (dcf.value.u_athlete_signature != '') {
        athleteSignature.value.fromDataURL(dcf.value.u_athlete_signature);
      }

    })

    const saveAthleteRepSignature = () => {
      if (athleteRepSignature.value) {
        const signatureDataUrl = athleteRepSignature.value.saveSignature()
        dcf.value.u_athlete_representative_signature = signatureDataUrl
      }
      else {
        dcf.value.u_athlete_representative_signature = '';
      }
    }

    const clearAthleteRepSignature = () => {
      if (athleteRepSignature.value) {
        athleteRepSignature.value.clearCanvas()
      }
    }

    const saveDCOSignature = () => {
      if (dcoSignature.value) {
        const signatureDataUrl = dcoSignature.value.saveSignature();
        dcf.value.u_dco_signature = signatureDataUrl;
      } else {
        dcf.value.u_dco_signature = '';
      }
    }

    const clearDCOSignature = () => {
      if (dcoSignature.value) {
        dcoSignature.value.clearCanvas()
      }
    }

    const saveAthleteSignature = () => {
      if (athleteSignature.value) {
        const signatureDataUrl = athleteSignature.value.saveSignature();
        dcf.value.u_athlete_signature = signatureDataUrl;
      } else {
        dcf.value.u_athlete_signature = '';
      }
    }

    const clearAthleteSignature = () => {
      if (athleteSignature.value) {
        athleteSignature.value.clearCanvas()
      }
    }

    const updateDco = (value) => {
        dcf.value.u_dco_name = value.id
    }
        
    // TODO: once completed set, 10 minute countdown to unlock and edit.

    const updateDcfCompletedDate = (value) => {
      dcf.value.u_date_and_time_completed = dateTimePickerUpdate('date', value, dcf.value.u_date_and_time_completed)
    }

    const updateDcfCompletedTime = (value) => {
      dcf.value.u_date_and_time_completed = dateTimePickerUpdate('time', value, dcf.value.u_date_and_time_completed)
    }
        
    const dcfRules = computed(() => {
        const localRules = {
          u_athlete_representative_first_name: { },
          u_athlete_representative_last_name: { },
          u_athlete_representative_role: { },
        }
        if (isTrue(dcf.value.u_is_there_an_athlete_representative)) {
          localRules.u_athlete_representative_first_name = {
              required
          }
          localRules.u_athlete_representative_last_name = {
              required
          }
          localRules.u_athlete_representative_role = {
              required
          }
        }
        return localRules
    })

    const variableRules = computed(() => {
        const localRules = {
            selectedDco: { required },
            dcfCompletedDate: { required, mustBeValidDate: mustBeValidDate() },
            dcfCompletedTime: { required, mustBeValidTime: mustBeValidTime() },
        }
        return localRules
    })
        
    const v$ = useVuelidate(dcfRules, dcf)
    const w$ = useVuelidate(variableRules, { selectedDco, dcfCompletedDate, dcfCompletedTime })

    async function save() {

      v$.value.$validate()
      w$.value.$validate()
      if (v$.value.$invalid || w$.value.$invalid) {
        validationError.value = true;
        return
      }

      // TO DO - fix signature save process
      // Could not push changes', error on sync
      const avoid = true

      if (!avoid) {
        saveAthleteRepSignature()
        saveDCOSignature()
        saveAthleteSignature()
      }

      let data = toRaw(dcf.value)
      await sn_customerservice_dcf.save(data)
      await dal.commit()

    }

    return {
      tab: null,
      loading: false,
      hasConsented,
      haveReadDeclaration,
      athleteRepPresent,
      switchColor: '#2e4d23',
      options,
      athleteRepSignature,
      clearAthleteRepSignature,
      dcoList,
      selectedDco,
      dcoSignature,
      clearDCOSignature,
      updateDco,
      dcfCompletedDate,
      dcfCompletedTime,
      updateDcfCompletedDate,
      updateDcfCompletedTime,
      emailCopy,
      clearAthleteSignature,
      athleteSignature,
      signOffComplete,
      isLocked,
      dcf,
      validationError,
      showAthletePolicy,
      save,
      isTrue,
      v$,
      w$

    }
  }
}
</script>

<style></style>
