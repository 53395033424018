import api from '../service-now/api'
import pagination from '@/service-now/utils/pagination'
import dateFromFilter from '@/service-now/utils/dateFromFilter'
import dal from '@/dal'

async function getDeletes(tables, fromDate) {
    const data = await pagination.paginateData('tablenameIN', tables, (pageQuery) =>
        api.getTableData(
            'sys_audit_delete',
            `sysparm_fields=sys_id,tablename^${pageQuery}^${dateFromFilter.apply(fromDate)}`
        )
    )
    return data
}

async function deleteFromApi(tables, fromDate) {
    const deletes = await getDeletes(tables, fromDate)

    for (const del of deletes) {
        let module = dal.getModule(del.tablename)
        if (module) {
            await dal.remove(module.name, del.sys_id)
        }
    }

    return deletes
}

export default {
    deleteFromApi
}
