<template>
    <v-navigation-drawer v-model="drawer" class="bg-si-black" theme="dark" width="80px">

        <v-divider></v-divider>

        <v-list v-if="!isLocked" density="compact" :scrim="false" nav>
            <v-list-item :to="{ name: 'home-portal' }" title="" value="home" class="si-main-nav-item text-center">
                <v-icon icon="mdi-home"></v-icon>
            </v-list-item>

            <v-list-item v-if="isMission" :to="{ name: 'missions-dashboard' }" title="Missions Dashboard"
                class="si-main-nav-item">
            </v-list-item>
            <v-list-item v-if="isMission && missionId" :to="{ name: 'mission-detail', params: { missionId: missionId } }" title="Mission Detail"
                class="si-main-nav-item">
            </v-list-item>

            <v-list-item v-if="isSamplesLanding" :to="{ name: 'samples-shipping' }" title="Samples / Shipping"
                class="si-main-nav-item">
            </v-list-item>

            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-information', params: routerParams }" title="DCF"
                class="si-main-nav-item" value="dcf">
            </v-list-item>
            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-notification', params: routerParams }" title="Notification"
                class="si-main-nav-item" value="notification">
            </v-list-item>
            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-athlete-information', params: routerParams }"
                class="si-main-nav-item" title="Athelete Information" value="athlete"></v-list-item>
            <v-list-item v-if="isDcfForm && (hasBloodQuestions || hasHgh)"
                :to="{ name: 'dcf-blood-questionnaire', params: routerParams }" class="si-main-nav-item"
                title="Blood Questionnaire" value="blood"></v-list-item>
            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-samples', params: routerParams }" class="si-main-nav-item"
                title="Samples" value="samples"></v-list-item>
            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-medications', params: routerParams }"
                class="si-main-nav-item" title="Medication" value="medication"></v-list-item>
            <v-list-item v-if="isDcfForm" :to="{ name: 'dcf-sign-off', params: routerParams }" class="si-main-nav-item"
                title="Sign Off" value="signoff"></v-list-item>
            <v-list-item v-if="isLockable" @click="setLockState" class="si-main-nav-item text-center"
                title="Lock Menu" value="lockmenu">
                <v-icon icon="mdi-lock-open" style="margin-top:10px;"></v-icon>
            </v-list-item>

            <v-list-item v-if="isHomePortal" title="Sync" @click="sync" class="si-main-nav-item text-center">
                <v-icon icon="mdi-sync" style="margin-top:10px;"></v-icon>
            </v-list-item>



        </v-list>
        <v-list v-else density="compact" :scrim="false" nav>
            <v-list-item title="Open" @click="unlockScreen" class="si-main-nav-item text-center">
                <v-icon icon="mdi-lock" style="margin-top:10px;"></v-icon>
            </v-list-item>
        </v-list>

        <v-dialog v-model="showUnlockDialog" max-width="50vw">
            <template v-slot:default="{}">
                <v-sheet class="py-8 px-6 mx-auto ma-4 text-center" elevation="4" max-width="500" rounded="lg"
                    width="100%">
                    <h3 class="text-h5">Unlock Code</h3>
                    <div class="text-subtitle-2 font-weight-light mb-3">Please enter DCO code to unlock form</div>
                    <v-otp-input v-model="unlockPass" class="mb-8" divider="•" length="4" variant="outlined"
                        ref="passInput"></v-otp-input>
                    <div class="text-caption">
                        <v-btn class="mt-6" style="background-color: #758f01;" height="40" text="Validate" width="135"
                            rounded @click="validatePassCode"></v-btn>
                    </div>
                </v-sheet>
            </template>
        </v-dialog>
        <v-bottom-sheet v-model="showPassCodeError" inset>
            <v-card class="text-center" height="200">
                <v-card-text>
                    <v-btn class="mt-6" style="background-color: #758f01;"
                        @click="showPassCodeError = !showPassCodeError">
                        Close
                    </v-btn>
                    <br>
                    <br>
                    <div>
                        {{ passCodeErrorDetails }}
                    </div>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>

        <v-dialog v-model="showSetPasscodeDialog" max-width="50vw">
            <template v-slot:default="{}">
                <v-sheet class="py-8 px-6 mx-auto ma-4 text-center" elevation="4" max-width="500" rounded="lg"
                    width="100%">
                    <h3 class="text-h5">Unlock Code</h3>
                    <div class="text-subtitle-2 font-weight-light mb-3">Please set a temporary lock code</div>
                    <v-otp-input v-model="unlockPass" class="mb-8" divider="•" length="4" variant="outlined"
                        ref="passInput"></v-otp-input>
                    <div class="text-caption">
                        <v-btn class="mt-6" style="background-color: #758f01;" height="40" text="Secure" width="135"
                            rounded @click="savePassCode"></v-btn>
                    </div>
                </v-sheet>
            </template>
        </v-dialog>

    </v-navigation-drawer>
</template>

<script>
import { ref, onMounted, computed, watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user.js'

export default {
    name: 'FormSideMenu',
    props: {
        dcfId: {
            type: String,
            required: false
        },
        missionId: {
            type: String,
            required: false
        },
        hasBloodPassport: {
            type: Boolean,
            default: false
        },
        hasHgh: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const route = useRoute()
        const router = useRouter()
        const appStore = useAppStore()
        const userStore = useUserStore()
        const isLockable = ref(false)
        const isLocked = computed(() => appStore.isLocked)
        const unlockPass = ref('')
        const showPassCodeError = ref(false)
        const isHomePortal = ref(false)
        const isDcfForm = ref(false)
        const isSamplesLanding = ref(false)
        const showUnlockDialog = ref(false)
        const showSetPasscodeDialog = ref(false)
        const passInput = ref(null)
        const passCodeErrorDetails = ref('The code you entered is incorrect. Please try again.')
        const isMission = ref(false)
        const loaded = ref(false)

        const setLockState = () => {
            if (userStore.tempPassCode !== null && userStore.tempPassCode !== '') {
                appStore.setLockState(true)
            } else {
                setPassCode()
            }
        }

        const unlockScreen = () => {
            showUnlockDialog.value = true
        }

        const setPassCode = () => {
            showSetPasscodeDialog.value = true
        }

        const sync = async () => {
            router.push('/status')
        }

        const validatePassCode = () => {
            if (unlockPass.value === userStore.tempPassCode) {
                appStore.setLockState(false)
                showUnlockDialog.value = false
            } else {
                passCodeErrorDetails.value = 'The code you entered is incorrect. Please try again.'
                showPassCodeError.value = true
            }
            unlockPass.value = ''
        }

        const savePassCode = () => {
            // check if passcode is valid- 4 digits
            if (unlockPass.value.length !== 4) {
                passCodeErrorDetails.value = 'The code must be 4 digits long. Please try again.'
                showPassCodeError.value = true
                unlockPass.value = ''
                return
            } else {
                userStore.setPassCode(unlockPass.value)
                appStore.setLockState(true)
                showSetPasscodeDialog.value = false
                unlockPass.value = ''
            }
        }

        const routerParams = computed(() => {
            return {
                dcfId: props.dcfId
            }
        });

        // Watch for changes in showUnlockDialog and set focus on the input when the dialog is opened
        watch(showUnlockDialog, (newVal) => {
            if (newVal) {
                nextTick(() => {
                    passInput.value.focus()
                })
            }
        })

        watch(() => route.path,
            () => {
                const matchedRoutes = route.matched
                isLockable.value = route.matched.some(route => route.meta.isLockable)
                isMission.value = matchedRoutes.some(route => route.name == 'mission-information' || route.name == 'missions-dashboard')
                isDcfForm.value = matchedRoutes.some(route => route.name == 'dcf-information')
                isSamplesLanding.value = matchedRoutes.some(route => route.name == 'samples-shipping')
            },
            { immediate: true }
        )

        onMounted(async () => {
            try {
                const matchedRoutes = route.matched
                isHomePortal.value = matchedRoutes.some(route => route.name == 'home-portal')
                isLockable.value = route.matched.some(route => route.meta.isLockable)
                isMission.value = matchedRoutes.some(route => route.name == 'mission-information' || route.name == 'missions-dashboard')
                isDcfForm.value = matchedRoutes.some(route => route.name == 'dcf-information')
                isSamplesLanding.value = matchedRoutes.some(route => route.name == 'samples-shipping')

            } catch (error) {
                console.error("Error:", error)
            } finally {
                loaded.value = true
            }
        })

        return {
            drawer: true,
            isLocked,
            isDcfForm,
            isHomePortal,
            isLockable,
            routerParams,
            setLockState,
            unlockScreen,
            unlockPass,
            showPassCodeError,
            validatePassCode,
            showUnlockDialog,
            passInput,
            passCodeErrorDetails,
            setPassCode,
            showSetPasscodeDialog,
            savePassCode,
            sync,
            isMission,
            isSamplesLanding,
            loaded

        }
    },
}
</script>

<style>
.bg-si-black {
    background-color: #1a0800;
}

.si-main-nav-item {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 80px;
    width: 120px;
    border-bottom: 1px solid white;
    border-radius: 0;
}

.v-list-item-title {
    white-space: normal;
    overflow-wrap: break-word;
    text-align: center;
    font-weight: 700 !important;
}
</style>