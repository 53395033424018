import BaseModule from './base_module'
import snMissionInstruction from '../../service-now/tables/sn_customerservice_m2m_mission_instruction'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'

class MissionInstruction extends BaseModule {
    constructor() {
        super(snMissionInstruction.table)
        this.sync = true
        this.syncOrder = 10 // After Test Mission
    }

    async fetch() {
        // Only the special instructions relating to test missions that have been loaded into the IndexDB should be fetched.
        // This is done by retrieving all the ids of the loaded test missions.
        const missions = await dal.modules.sn_customerservice_test_mission.getAll()

        return await pagination.paginateData(
            'u_test_missionIN',
            missions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snMissionInstruction.table,
                    `${pageQuery}`
                )
        )
    }
}

const module = new MissionInstruction()
export default module
