const instanceUrl = import.meta.env.VITE_SERVICE_NOW_URL
import displayValues from './utils/displayValues.js'
import { fetch } from './fetch.js'

/**
 * Get data table from service now
 * @param {*} tableName - service now table name
 * @param {*} query - service now filter
 * @param {*} getDisplayValues - only use this on tables we do not edit, remaps the display value as _{field}_display_name
 * @returns
 */
async function getTableData(tableName, query, getDisplayValues = false) {
    var tableUrl = `${instanceUrl}/api/now/table/${tableName}?sysparm_limit=1`
    if (typeof query !== 'undefined') {
        const searchParams = new URLSearchParams()
        searchParams.append('sysparm_query', query)

        if (getDisplayValues) {
            searchParams.append('sysparm_display_value', 'all')
        }

        tableUrl = `${instanceUrl}/api/now/table/${tableName}?${searchParams}`
    }

    var response = await fetch(tableUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const data = await response.json()
    const hash = response.headers.get('X-Content-Hash')

    displayValues.resolve(data.result)

    return {
        data,
        hash
    }
}

/**
 * Get attachment data for a record by the record's sys_id.
 * @param {*} recordSysId - The sys_id's (as comma separated values) of the records to get the attachments for.
 * @returns All the attachment records where the table_sys_id field matches the recordSysId.
 */
async function getAttachments(recordSysId) {
    var apiUrl = `${instanceUrl}/api/now/attachment?sysparm_query=table_sys_idIN${recordSysId}`

    var response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    const data = await response.json()
    const hash = response.headers.get('X-Content-Hash')

    displayValues.resolve(data.result)

    return {
        data,
        hash
    }
}

/**
 * Upload a file to a record as an attachment.
 * @param {*} tableName
 * @param {*} id
 * @param {*} fileName
 * @param {*} blob
 * @returns
 */
async function uploadAttachment(tableName, id, fileName, blob) {
    var apiUrl = `${instanceUrl}/api/now/attachment/upload` ///api/now/attachment/file?table_name=${table_name}&table_sys_id=${table_sys_id}&file_name=${fileName}`

    var formData = new FormData()
    formData.append('table_name', tableName)
    formData.append('table_sys_id', id)
    formData.append('uploadFile', blob, fileName)

    var response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            //'Content-Type': 'multipart/form-data'
        },
        body: formData
    })

    let json = await response.json()

    displayValues.resolve([json.result])

    return json
}

/**
 * Get attachment data for a record by the record's sys_id.
 * @param {*} recordSysId - The sys_id's (as comma separated values) of the records to get the attachments for.
 * @returns All the attachment records where the table_sys_id field matches the recordSysId.
 */
async function getAttachment(recordSysId) {
    console.debug('download file ::', recordSysId)
    var apiUrl = `${instanceUrl}/api/now/attachment/${recordSysId}/file`
    var response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return await response.blob()
}

/**
 * Updates the specified record with the name-value pairs included in the request body.
 * @param {*} tableName
 * @param {*} data
 * @returns
 */
async function patch(tableName, id, data, hash) {
    var tableUrl = `${instanceUrl}/api/now/table/${tableName}/${id}`

    var response = await fetch(tableUrl, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'X-SYS-ID': hash
        },
        body: JSON.stringify(data)
    })

    const json = await response.json()

    displayValues.resolve([json.result])

    return json.result
}

/**
 * Inserts one record in the specified table. Multiple record insertion is not supported by this method.
 * @param {*} tableName
 * @param {*} data
 * @returns
 */
async function post(tableName, data, hash) {
    var tableUrl = `${instanceUrl}/api/now/table/${tableName}?sysparm_input_display_value=true`

    var response = await fetch(tableUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-SYS-ID': hash
        },
        body: JSON.stringify(data)
    })

    const json = await response.json()

    displayValues.resolve([json.result])

    return json.result
}

/**
 * Deletes the specified record.
 * @param {*} tableName
 * @param {*} sysId
 * @returns
 */
async function deleteRecord(tableName, sysId, hash) {
    var tableUrl = `${instanceUrl}/api/now/table/${tableName}/${sysId}`
    var response = await fetch(tableUrl, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'X-SYS-ID': hash
        }
    })

    return await response.json()
}

export default {
    getTableData,
    getAttachment,
    getAttachments,
    patch,
    post,
    deleteRecord,
    uploadAttachment
}
