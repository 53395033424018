import BaseModule from './base_module'
import snDCF from '../../service-now/tables/sn_customerservice_dcf'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'
import snBloodSamples from '../../service-now/tables/sn_customerservice_blood_samples'
import snUrineSamples from '../../service-now/tables/sn_customerservice_urine_samples'
import snDeclarationOfMedicationOrSupplements from '../../service-now/tables/sn_customerservice_declaration_of_medications_supplements'

class DCF extends BaseModule {
    constructor() {
        super(snDCF.table)
        this.sync = true
        this.syncOrder = 11 // After Test Session
        this.relationships = [
            {
                table: snBloodSamples.table,
                field: 'u_dcf_number'
            },
            {
                table: snDeclarationOfMedicationOrSupplements.table,
                field: 'u_dcf_number'
            },
            {
                table: snUrineSamples.table,
                field: 'u_dcf_number'
            }
            // TODO: add all child tables here
        ]
    }

    async getBySessionsAndAthlete(test_session_ids, athleteId) {
        return await this.repository.db[this.name]
            .where('[u_test_session_id+u_athlete]')
            .anyOf(test_session_ids.map((x) => [x, athleteId]))
            .toArray()
    }

    async fetch() {
        const test_sessions = await dal.modules.sn_customerservice_test_session.getAll()
        // Get all the DCF records that are related to the loaded Test Sessions.
        return await pagination.paginateData(
            'u_test_session_idIN',
            test_sessions.map((x) => x.sys_id),
            (pageQuery) => api.getTableData(snDCF.table, `${pageQuery}`)
        )
    }
}

const module = new DCF()
export default module
