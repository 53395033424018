import BaseModule from './base_module'
import snBloodSample from '../../service-now/tables/sn_customerservice_blood_samples'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class BloodSample extends BaseModule {
    constructor() {
        super(snBloodSample.table)
        this.sync = true
        this.syncOrder = 12 // Loaded after DCF
    }

    async getBloodPassportSamplesByDcf(dcfId) {   // type 1 - hGH Blood Passport Sample
        return await this.repository.db[this.name]
            .where('[u_dcf_number+u_blood_sample_type]')
            .equals([dcfId, "1"])
            .toArray()
    }
    
    async getBloodSamplesByDcf(dcfId) {   // type 2 - hGH Blood Sample
        return await this.repository.db[this.name]
            .where('[u_dcf_number+u_blood_sample_type]')
            .equals([dcfId, "2"])
            .toArray()
    }
    async getDbsSamplesByDcf(dcfId) {   // type 3 - Dried Blood Spot Sample
        return await this.repository.db[this.name]
            .where('[u_dcf_number+u_blood_sample_type]')
            .equals([dcfId, "3"])
            .toArray()
    }

    async fetch() {
        const dcfNumbers = await dal.modules.sn_customerservice_dcf.getAll()
        // Get all the Blood Sample records that are related to the loaded DCF numbers.
        return await pagination.paginateData(
            'u_dcf_numberIN',
            dcfNumbers.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(snBloodSample.table, `${pageQuery}`)
        )
    }
}

const module = new BloodSample()
export default module