<template>
   <v-container fluid class="si-login-container">
      <v-row align="center" justify="center">
         <v-col cols="12" md="6" class="si-home-col">
            <div class="si-logo-1">
               <img :src="logo" alt="Sport Integrity Commission" />

            </div>
         </v-col>
         <v-col cols="12" md="6" class="si-home-col">
            <div style="min-width: 25vw;">
               <v-form v-model="valid" ref="form" v-if="isOnline">


                  <div v-if="sso">
                     <v-btn @click="ssoLogin" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
                        Login
                     </v-btn>
                  </div>

                  <div v-else>
                     <v-text-field label="Enter your e-mail address" v-model="email" :rules="emailRules"
                        :readonly="locked" :disabled="locked" required></v-text-field>
                     <v-text-field label="Enter your password" v-model="password" min="8"
                        :append-icon="e1 ? 'mdi-eye-off' : 'mdi-eye'" :append-icon-cb="() => (e1 = !e1)"
                        :type="e1 ? 'password' : 'text'" :rules="passwordRules" counter required
                        @click:append="e1 = !e1"></v-text-field>
                     <v-layout justify-space-between>
                        <v-btn @click="submit" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
                           Login
                        </v-btn>
                     </v-layout>
                     <a class="si-text-link" href="">Forgot Password</a>
                  </div>

               </v-form>
               <v-alert color="warning" icon="mdi-alert" elevation="2" v-else>
                  <strong>Warning:</strong> You must be online to login
               </v-alert>
            </div>
         </v-col>
         <v-snackbar v-model="hasError">
            {{ errorText }}
            <template v-slot:actions>
               <v-btn color="pink" variant="text" @click="hasError = false">
                  Close
               </v-btn>
            </template>
         </v-snackbar>
      </v-row>
   </v-container>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import dal from '@/dal/index.js'
import logo from '@/assets/logosi.svg'

import { useRouter } from 'vue-router'
import HttpError from '@/service-now/HttpError'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useOnline } from '@vueuse/core'

export default {
   name: 'LoginView',
   props: {
      source: String
   },
   setup() {
      const router = useRouter()
      const valid = ref(false)
      const email = ref('')
      const password = ref('')
      const e1 = ref(true)
      const errorText = ref('')
      const hasError = ref(false)
      const locked = ref(false)
      const isOnline = useOnline()
      const sso = ref(true)

      const userStore = useUserStore()
      const appStore = useAppStore()

      const isLoading = computed(() => appStore.isLoading)
      const userName = computed(() => userStore.username)

      const emailRules = [
         (v) => !!v || 'E-mail is required',
         (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]

      const passwordRules = [
         (v) => !!v || 'Password is required',
         (v) => v.length >= 8 || 'Password must be at least 8 characters'
      ]

      const toggleVisibility = () => {
         e1.value = !e1.value
      }

      onMounted(async () => {
         const changeLogCount = await dal.changeLogRepository.count('changeLog')

         // if there are unsaved changes lock the email field
         email.value = userName.value

         if (changeLogCount > 0 && userName.value) {
            hasError.value = true
            errorText.value = 'There are unsaved changes, please sync before logging in'
            locked.value = true
         }

         if (await userStore.checkCookieTokenIsValid()) {

            try {
               await dal.syncAll()
               router.push({ name: 'home-portal' })
            } catch (e) {
               hasError.value = true
               errorText.value = 'Error syncing data'
               throw e;
            }
         }
      });

      const submit = async () => {

         appStore.setLoading(true)
         hasError.value = false

         if (!email.value) {
            return;
         }
         try {
            await userStore.login(email.value, password.value)
         } catch (e) {
            hasError.value = true
            errorText.value = 'Error logging in, please check your credentials'
            throw e
         } finally {
            appStore.setLoading(false)
         }

         try {
            await dal.syncAll()
            router.push({ name: 'home-portal' })
         } catch (e) {
            console.error(e)
            if (e instanceof HttpError) {
               // specific error
               if (e.code === 401) {
                  hasError.value = true
                  console.warn(e.message)
                  errorText.value = 'Error logging in, please check your credentials'
                  return
               }
            }
            throw e;
         }
      }

      const ssoLogin = async () => {
         window.location.replace('/auth/login')
      }

      return {
         valid,
         email,
         password,
         e1,
         emailRules,
         passwordRules,
         toggleVisibility,
         submit,
         logo,
         isLoading,
         errorText,
         hasError,
         locked,
         userName,
         isOnline,
         sso,
         ssoLogin
      }
   }
}
</script>
<style></style>
