import { defineStore } from 'pinia'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'
import customer_contact from '@/dal/modules/customer_contact'
import sn_customerservice_blood_samples from '@/dal/modules/sn_customerservice_blood_samples'

export const useDcfStore = defineStore('dcf', {
    state: () => ({
        dcf: null,
        athlete: null,
        hasBloodPassport: false,
        hasHgh: false,
    }),
    getters: {
        isLocked() {
            // dcf is locked if there is a date and time completed
            return false // !!this.dcf?.u_date_and_time_completed
        },
        canUnlock() {
            // can unlock if the date is less than 10 mins ago
            const dateCompleted = new Date(this.dcf?.u_date_and_time_completed)
            const now = new Date()
            const diff = now - dateCompleted
            return diff < 600000
        }
    },
    actions: {
        async loadDcf(id) {
            this.dcf = await sn_customerservice_dcf.get(id)

            if (!this.dcf) {
                return
            }

            if (this.dcf.u_athlete) {
                this.athlete = await customer_contact.get(this.dcf.u_athlete)
            }
            const bloodPassportSamples = await sn_customerservice_blood_samples.getBloodPassportSamplesByDcf(id)
            if (bloodPassportSamples.length > 0) {
                this.hasBloodPassport = true
            }    
            const bloodHghSamples = await sn_customerservice_blood_samples.getBloodSamplesByDcf(id)
            if (bloodHghSamples.length > 0) {
                this.hasHgh = true
            }

        },
        async save() {
            await sn_customerservice_dcf.save(this.dcf)
        },
        async unlock(comment) {
            if (!this.canUnlock) {
                return false
            }

            // comment is required
            if (!comment) {
                return false
            }

            this.u_sign_off_comments = comment

            this.dcf.u_date_and_time_completed = null

            // TODO: Clear the signatures, NOT all of these....
            this.dcf.u_athlete_representative_signature = ''
            this.dcf.u_athlete_signature = ''
            this.dcf.u_dco_signature = ''
            // this.dcf.u_notifying_chaperone_signature = ''
            this.dcf.u_signature = ''
            // this.dcf.u_witness_chaperone_signature = ''

            await this.save()

            return true
        }
    }
})
