import BaseModule from './base_module'
import snNationality from '../../service-now/tables/sn_customerservice_nationality'
import api from '../../service-now/api'
import customerContact from '../../service-now/tables/customer_contact'

class Nationality extends BaseModule {
    constructor() {
        super(snNationality.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: customerContact.table,
                field: 'u_nationality'
            }
        ]
    }

    async fetch() {
        const query = ''
        const { data } = await api.getTableData(snNationality.table, query, true)
        return data.result
    }
}

const module = new Nationality()
export default module
