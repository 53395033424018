<template>
    <v-row>
      <v-col cols="12" style="padding:0 !important">
        <v-main>
          <div style="min-height: 100vh;">

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2>Potential Failure to Comply Report</h2>
                            <DividerWithIcons />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Potential Failure Time
                        </v-col>
                        <v-col cols="6" md="6" class="si-form-inline">
                            <div style="display: flex">
                                <v-text-field v-model="selectedDate" label="" density="compact" bg-color="white" type="date" required
                                    :error-messages="w$.selectedDate.$errors.map(e => e.$message)" @blur="w$.selectedDate.$touch"
                                    @input="updateDate">
                                </v-text-field>
                                <v-text-field v-model="selectedTime" label="" density="compact" bg-color="white" type="time" required
                                    :error-messages="w$.selectedTime.$errors.map(e => e.$message)" @blur="w$.selectedTime.$touch"
                                    @input="updateTime">
                                </v-text-field>
                            </div>
                        </v-col>
                    </v-row>

                    
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Location
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-text-field v-model="pftc.u_location" label="" 
                            density="compact" bg-color="white" required type="text"
                            :error-messages="v$.u_location.$errors.map(e => e.$message)" @blur="v$.u_location.$touch">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>

                    
            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    
                    <v-row>
                        <v-col cols="12" md="12">
                            <h3>Athlete Comments</h3>
                            <DividerWithIcons />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" md="12" class="si-form">
                            <v-textarea v-model="pftc.u_comments" label="Comments" density="compact" bg-color="white">
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form-inline">
                            <p>Email a Copy</p>
                            <v-checkbox v-model="pftc.u_email_a_copy" 
                                true-value="true" false-value="false" density="compact" bg-color="white" label="">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" md="12" class="si-athlete-policy">
                            <p>I am aware that refusing or otherwise failing to comply with the sample collection procedures, without compelling reason – or providing information which is later found to be false, misleading, inaccurate or incomplete – may be a violation of the relevant anti-doping rules, which carry a possible four-year period of ineligibility and other potential circumstances.  I further understand that refusal or failure to submit to sample collections and the consequences of the violation may be publicly disclosed.  
                            </p>
                        </v-col>
                    </v-row>
                    <div class="si-form-gap"></div>


                </v-container>
            </v-sheet>  
            

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2>Signatures</h2>
                            <DividerWithIcons is-compact="true" />
                        </v-col>
                    </v-row>
                    
                    <v-row style="align-items: center;">
                        <v-col cols="6" md="6" class="si-form-signature">
                            <span class="font-italic si-top-label">Athlete Signature </span>
                            <div class="si-signature-field">
                                <vue-signature-pad
                                    ref='authorSignature'
                                    :max-width='options.maxWidth'
                                    :min-width='options.minWidth'
                                    height="300px"
                                    :options='{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor,
                                    }'
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-btn @click='clearAuthorSignature' class="si-right-action-button" size="large">
                                <v-icon icon="mdi-undo"></v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-sheet>
            
            <v-container class="pa-0" fluid>
                <v-row class="d-flex justify-end">
                    <v-col v-if="!isLocked" cols="3" md="3" class="d-flex">
                        <v-btn @click="goBack" class="si-form-button-2 flex-grow-1" height="48"
                            variant="tonal">
                            Back
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex">
                        <v-btn :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                            variant="tonal">
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>


          </div>
        </v-main>
    </v-col>
</v-row>
  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_pftc from '@/dal/modules/sn_customerservice_pftc'
import { isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime, goBack } from '@/utils/utils.js'
import { useMissionStore } from '@/stores/mission'
import { useDcfStore } from '@/stores/dcf'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import dal from '@/dal/index.js'

export default {
    name: 'PftcReportEdit',
    components: {
        VueSignaturePad,
        DividerWithIcons
    },
    props: {
        id: {
            type: String,
            required: true
        },
        missionId: {
            type: String,
            required: false
        },
    },
    setup(props, { emit }) {
        const mission = ref()
        const athlete = ref({})
        const pftc = ref({})
        const isLoaded = ref(false)
        const authorSignature = ref(null)
        const missionStore = useMissionStore()
        const validationError = ref(false)
        const router = useRouter()
        const route = useRoute()
        const dcoList = ref([])
        const leadDco = ref(null)
        const selectedDco = ref(null)
        const selectedDate = ref(null)
        const selectedTime = ref(null)
        
        const dcfStore = useDcfStore()
        const isLocked = computed(() => dcfStore.isLocked)

        const options = ref({
            penColor: 'rgb(0,0,0)',
            backgroundColor: 'rgb(255, 255, 255)',
            maxWidth: 2,
            minWidth: 2,
        })

        onMounted(async () => {
            try {

                await missionStore.loadMission(props.missionId)
                dcoList.value = missionStore.dcoList
                leadDco.value = missionStore.dco

                if (props.id && props.id !== 'new') {
                    pftc.value = await sn_customerservice_pftc.get(props.id)

                    if (pftc.value.u_potential_failure_time) {
                        selectedDate.value = dateTimePickerInitialise('date', pftc.value.u_potential_failure_time)
                        selectedTime.value = dateTimePickerInitialise('time', pftc.value.u_potential_failure_time)
                    }

                    // TODO Load assigned DCO - field doesn't exist yet

                } else {
                    pftc.value = await sn_customerservice_pftc.createNew()
                    pftc.value.u_number = props.sessionId
                    
                    if (leadDco.value && dcoList.value.length > 0) {
                        selectedDco.value = dcoList.value.find(dco => dco.id === leadDco.value)
                        //pftc.value.u_dco_name = selectedDco.value ? selectedDco.value.id : ''
                    }
                }

            } catch (error) {
                console.error("Error:", error)
            } finally {
                isLoaded.value = true
            }

        })


        const close = () => {
            emit('close')
        }

        const clearAuthorSignature = () => {
            if (authorSignature.value) {
                authorSignature.value.clearCanvas()
            }
        }

        const updateDate = (value) => {
            pftc.value.u_potential_failure_time = dateTimePickerUpdate('date', value, pftc.value.u_potential_failure_time)
        }

        const updateTime = (value) => {
            pftc.value.u_potential_failure_time = dateTimePickerUpdate('time', value, pftc.value.u_potential_failure_time)
        }
        
        const supplementaryReportRules = computed(() => {
            const localRules = {
                u_location: { required },
            }
            return localRules
        })

        const variableRules = computed(() => {
            const localRules = {
                selectedDco: { },
                selectedDate: { required, mustBeValidDate: mustBeValidDate() },
                selectedTime: { required, mustBeValidTime: mustBeValidTime() },
            }
            return localRules
        })

        const v$ = useVuelidate(supplementaryReportRules, { pftc })
        const w$ = useVuelidate(variableRules, { selectedDco })

        async function save() {
            v$.value.$validate()
            w$.value.$validate()
            if (v$.value.$invalid || w$.value.$invalid) {
                validationError.value = true
                return
            }
            let data = toRaw(pftc.value)
            await sn_customerservice_pftc.save(data)
            await dal.commit()
        }

        const handleGoBack = () => {
            goBack(router, route)
        };

        return {
            switchColor: '#2e4d23',
            mission,
            athlete,
            tab: null,
            isLoaded,
            isLocked,
            pftc,
            close,
            save,
            authorSignature,
            clearAuthorSignature,
            options,
            selectedDco,
            dcoList,
            selectedDate,
            selectedTime,
            updateDate,
            updateTime,
            isTrue,
            goBack: handleGoBack,
            validationError,
            v$,
            w$
            
        }
    },

}
</script>

<style></style>
