import BaseModule from './base_module'
import dsDocumentReference from '../../service-now/tables/ds_document_references'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'


class DocumentReference extends BaseModule {
    constructor() {
        super(dsDocumentReference.table)
        this.sync = true
        this.syncOrder = 12 // Make sure this is after ds_document
    }

    async fetch() {
        const documents = await dal.modules.ds_document.getAll()
        // Get the document references for the loaded documents.
        return await pagination.paginateData(
            'documentIN',
            documents.map((x) => x.sys_id),
            (pageQuery) => api.getTableData(dsDocumentReference.table, `${pageQuery}`)
        )
    }
}

const module = new DocumentReference()
export default module