import BaseModule from './base_module'
import snSport from '../../service-now/tables/sn_customerservice_sports'
import api from '../../service-now/api'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'

class Sport extends BaseModule {
    constructor() {
        super(snSport.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: snTestMission.table,
                field: 'u_sport'
            },
            {
                table: snTestSession.table,
                field: 'u_sport'
            }
        ]
    }

    async getAll() {
        const data = await this.table().orderBy('u_name').toArray()
        return data.filter((x) => x.u_name)
    }

    async fetch() {
        const query = ''
        const { data } = await api.getTableData(snSport.table, query, true)
        return data.result
    }
}

const module = new Sport()
export default module
