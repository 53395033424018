export default {
    table: 'sn_customerservice_test_mission',
    description: 'Test Mission',
    write: true,
    // state=1 is New
    // state=2 is Offered
    // state=3 is Accepted
    // state=4 is Confirmed
    // state=5 is In-Mission
    // state=6 is Post Mission
    // state=7 is Results
    // state=8 is Completed
    // state=9 is Cancelled
    defaultQuery: 'state!=1^state!=6^state!=7^state!=8^state!=9',
    states: {
        new: 1,
        offered: 2,
        accepted: 3,
        confirmed: 4,
        inMission: 5,
        postMission: 6,
        results: 7,
        completed: 8,
        cancelled: 9
    },
    testTypes: {
        inCompetition: 1,
        outOfCompetitionCamp: 2,
        outOfCompetition: 3
    }
}
