import BaseModule from './base_module'
import sysUser from '../../service-now/tables/sys_user'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '@/dal'
import snDCOReport from '../../service-now/tables/sn_customerservice_dco_report'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'
import snDCF from '../../service-now/tables/sn_customerservice_dcf'
import snUrineSample from '../../service-now/tables/sn_customerservice_urine_samples'
import snBloodSample from '../../service-now/tables/sn_customerservice_blood_samples'
import snUAR from '../../service-now/tables/sn_customerservice_unsuccessful_attempt_record'
import snSample from '../../service-now/tables/sn_customerservice_sample'
import snChainOfCustody from '../../service-now/tables/sn_customerservice_chain_of_custody'

class SysUser extends BaseModule {
    constructor() {
        super(sysUser.table)
        this.sync = true
        this.syncOrder = 1
        this.relationships = [
            {
                table: snDCOReport.table,
                field: 'u_dco_name'
            },
            {
                table: snTestMission.table,
                field: 'u_assigned_lead_dco'
            },
            {
                table: snTestMission.table,
                field: 'u_assigned_support_dcos'
            },
            {
                table: snTestMission.table,
                field: 'u_assigned_chaperones'
            },
            {
                table: snTestMission.table,
                field: 'u_soc'
            },
            {
                table: snTestMission.table,
                field: 'u_assigned_bcos'
            },
            {
                table: snDCF.table,
                field: 'u_notifying_chaperone'
            },
            {
                table: snUrineSample.table,
                field: 'u_scp_second_observer_name'
            },
            {
                table: snUrineSample.table,
                field: 'u_additional_observers'
            },
            {
                table: snBloodSample.table,
                field: 'u_blood_dco_name'
            },
            {
                table: snBloodSample.table,
                field: 'u_bco_dco_name'
            },
            {
                table: snDCF.table,
                field: 'u_bco_name'
            },
            {
                table: snDCF.table,
                field: 'u_dco_name'
            },
            {
                table: snUAR.table,
                field: 'u_author'
            },
            {
                table: snSample.table,
                field: 'u_custodian'
            },
            {
                table: snChainOfCustody.table,
                field: 'u_custodian'
            },
            {
                table: snTestSession.table,
                field: 'closed_by'
            },
            {
                table: snTestSession.table,
                field: 'assigned_to'
            },
            {
                table: snTestSession.table,
                field: 'resolved_by'
            },
            {
                table: snTestSession.table,
                field: 'u_opened_by'
            }
        ]
    }

    async fetch() {

        const missions = await dal.modules.sn_customerservice_test_mission.getAll()
        const documents = await dal.modules.ds_document.getAll()

        let userIds = [
            ...missions.map((mission) => mission.u_assigned_chaperones?.split(',')).flat(),
            ...missions.map((mission) => mission.u_assigned_lead_dco?.value),
            ...missions.map((mission) => mission.u_assigned_support_dcos?.split(',')).flat(),
            ...missions.map((mission) => mission.u_soc?.value),
            ...missions.map((mission) => mission.u_assigned_bcos?.split(',')).flat(),
            ...documents.map((document) => document.owner?.value)
        ]

        return await pagination.paginateData('sys_idIN', userIds, (pageQuery) =>
            api.getTableData(sysUser.table, `${pageQuery}`, true)
        )
    }
}

const module = new SysUser()
export default module
