<template>
    <div>
        <form-side-menu></form-side-menu>
        <v-container fluid class="si-form-container">
            <main-header></main-header>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn :to="{ name: 'missions-dashboard' }" size="x-large">Missions</v-btn>
                </v-col>
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn :to="{ name: 'samples-shipping' }" size="x-large">Samples / Shipping</v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn href="https://sportintegrity.service-now.com/login.do" target="_blank" size="x-large">Portal</v-btn>
                </v-col>
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn size="x-large">Notifications</v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn size="x-large">Whereabouts</v-btn>
                </v-col>
                <v-col cols="12" md="6" class="si-home-col">
                    <v-btn size="x-large">Sample Collection Manual</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue';
import FormSideMenu from '@/components/FormSideMenu.vue';

export default {
    name: 'HomePortal',   
    components: {
        MainHeader,
        FormSideMenu
    },  
}
</script>

<style></style>
