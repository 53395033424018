<template>
    <v-container class="si-login-container  align-start">
        <form-side-menu></form-side-menu>
        <v-row direction="column">
            <v-col v-if="isOnline" cols="12">
                <v-btn @click="sync" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
                    Sync
                </v-btn>
            </v-col>
            <v-col cols="12">

                <v-table>
                    <tbody>
                        <tr>
                            <td>Username</td>
                            <td>{{ username }}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{{ displayName }}</td>
                        </tr>
                        <tr>
                            <td>User Id</td>
                            <td>{{ user_sys_id }}</td>
                        </tr>
                        <tr>
                            <td>Sync Complete</td>
                            <td>{{ syncComplete }}</td>
                        </tr>
                        <tr>
                            <td>Unsaved Data</td>
                            <td>{{ unsavedCount }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <v-table density="compact">
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Table
                                            </th>
                                            <th class="text-left">
                                                Sync Date
                                            </th>
                                            <th class="text-left">
                                                Rows
                                            </th>
                                        </tr>
                                    </thead>
                    <tbody>
                        <tr v-for="synclog in syncLogs" :key="synclog.id">
                            <td>{{ synclog.module }}</td>
                            <td>{{ date.format(synclog.TimeStamp, 'keyboardDateTime24h') }}</td>
                            <td>{{ synclog.count }}</td>
                        </tr>
                    </tbody>
                </v-table>
                </td>
                </tr>
                </tbody>
                </v-table>
            </v-col>

            <v-col v-if="isOnline" cols="12">
                <v-btn @click="resetDialog = true" class="si-form-button-1 flex-grow-1" height="48" variant="tonal">
                    Reset
                </v-btn>
                <v-dialog v-model="resetDialog" max-width="400">

                    <v-card prepend-icon="mdi-alert"
                        text="This will download all data from the server and replace the local database."
                        title="Reset the local database?">
                        <template v-slot:actions>
                            <v-spacer></v-spacer>

                            <v-btn @click="resetDialog = false">
                                Cancel
                            </v-btn>

                            <v-btn @click="reset">
                                Reset
                            </v-btn>
                        </template>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-snackbar v-model="hasError">
            {{ errorText }}
            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="hasError = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>

import FormSideMenu from '@/components/FormSideMenu.vue';
import { computed, ref, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app';
import dal from '@/dal/index.js'
import { useOnline } from '@vueuse/core'
import { useDate } from 'vuetify'


export default {
    name: 'AppStatusView',
    props: {
        source: String
    },
    components: {
        FormSideMenu
    },
    setup() {
        const userStore = useUserStore()
        const appStore = useAppStore()
        const isOnline = useOnline()

        const username = computed(() => userStore.username)
        const user_sys_id = computed(() => userStore.user_sys_id)
        const displayName = computed(() => userStore.displayName)
        const syncComplete = computed(() => appStore.syncComplete)
        const syncLogs = ref([])
        const unsavedCount = ref(0)
        const resetDialog = ref(false)
        const hasError = ref(false)
        const errorText = ref('')
        const date = useDate()

        onMounted(async () => {
            updateStats();
        });

        const updateStats = async () => {
            syncLogs.value = await dal.changeLogRepository.getAll('syncLog');
            unsavedCount.value = await dal.changeLogRepository.count('changeLog');

            syncLogs.value.forEach(async (syncLog) => {
                syncLog.count = await dal.getModule(syncLog.module)?.count();
            });
        }

        const sync = async () => {
            try {
                await dal.syncAll()
                updateStats()
            } catch (e) {
                hasError.value = true
                errorText.value = 'Error syncing data'
                throw e;
            }
        }

        const reset = async () => {
            try {
                resetDialog.value = false
                await dal.reset();
                updateStats();
            } catch (e) {
                hasError.value = true
                errorText.value = 'Error syncing data'
                throw e;
            }
        }
        return {
            username,
            user_sys_id,
            displayName,
            syncComplete,
            syncLogs,
            unsavedCount,
            sync,
            reset,
            resetDialog,
            isOnline,
            date
        }
    }
}
</script>
<style></style>
