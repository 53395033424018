import { defineStore } from 'pinia'
import almHardware from '@/dal/modules/alm_hardware'
import cmdbModel from '@/dal/modules/cmdb_model'
import cmdbModelCategory from '@/dal/modules/cmdb_model_category'

export const useUrineSampleStore = defineStore('urineSample', {
    state: () => ({
        hardwareList: [],
    }),
    actions: {
        async loadHardware() {
            const modelTypes = await cmdbModelCategory.select('name', 'Urine');
            if (modelTypes) {
                const allModels = await cmdbModel.getAll();
                const modelList = allModels.filter(model => modelTypes.some(modelType => modelType.id === model.cmdb_model_category));
                const allHardware = await almHardware.getAll();
                this.hardwareList = allHardware.filter(hardware => modelList.some(model => model.id === hardware.model));
            }
        },
    }
})