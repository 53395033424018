import BaseModule from './base_module'
import snALMHardware from '../../service-now/tables/alm_hardware'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'
import snBloodSample from '../../service-now/tables/sn_customerservice_blood_samples'
import snUrineSample from '../../service-now/tables/sn_customerservice_urine_samples'
import snSample from '../../service-now/tables/sn_customerservice_sample'

class ALMHardware extends BaseModule {
    constructor() {
        super(snALMHardware.table)

        this.sync = true

        this.syncOrder = 10 // Make sure this is after test_mission

        this.relationships = [
            {
                table: snBloodSample.table,
                field: 'u_blood_passport_kit_used'
            },
            {
                table: snBloodSample.table,
                field: 'u_blood_kit_used'
            },
            {
                table: snBloodSample.table,
                field: 'u_dbs_kit_used'
            },
            {
                table: snSample.table,
                field: 'u_kit_used'
            },
            {
                table: snUrineSample.table,
                field: 'u_urine_kit_used'
            }
        ]
    }

    async fetch() {
        const models = await dal.modules.cmdb_model.getAll()

        return await pagination.paginateData(
            'modelIN',
            models.map((x) => x.sys_id),
            (pageQuery) => api.getTableData(snALMHardware.table, `${pageQuery}`, false)
        )
    }
}

const module = new ALMHardware()
export default module