<template>
    <v-row style="height: 80px; margin-bottom: 40px;" class="flex align-center">
        <v-col cols="4">
        <div class="si-logo-1">
            <img src="@/assets/logosi.svg" alt="Sport Integrity Commission" />
        </div>
        </v-col>
        <v-col cols="4">
        </v-col>
        <v-col cols="4" align="right">
            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn
                    variant="flat"
                    prepend-icon="mdi-account-circle"
                    class="si-login-info"
                    v-bind="props"
                    >
                    <template v-slot:prepend>
                        <v-icon color="#2e4d23"></v-icon>
                    </template>

                    <div>
                        <div>  
                            {{ userName }}
                        </div>
                        <div style="font-size: 0.8rem;">  
                            {{ currentDateTime }}
                        </div>
                    </div>

                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="logOutDialog">
                    <v-list-item-title>Log Out</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
        <v-dialog v-model="showLogOutDialog" max-width="400px">
            <template v-slot:default="{ isActive }">
                <v-card
                prepend-icon="mdi-logout"
                text="Please confirm you would like to sign out."
                title="Log Out"
                >
                <template v-slot:actions>
                    <div class="flex justify-space-between">
                        <v-btn
                        class="ml-auto"
                        text="Log Out"
                        @click="confirmLogOut"
                        ></v-btn>
                        <v-btn
                        class="ml-auto"
                        text="Cancel"
                        @click="isActive.value = false"
                        ></v-btn>
                    </div>
                </template>
                </v-card>
            </template>
        </v-dialog>
    </v-row>
</template>
  
<script>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useUserStore } from '@/stores/user.js'
import { useDate } from 'vuetify'

export default {
    name: 'MainHeader',
    setup() {
        const appStore = useAppStore()
        const userStore = useUserStore()
        const router = useRouter()
        const userName = ref('')
        const showLogOutDialog = ref(false)
        const currentDateTime = ref(new Date());
        const date = useDate()

        const updateDateTime = () => {
            currentDateTime.value = new Date();
            requestAnimationFrame(updateDateTime);
        };

        onMounted(() => {
            updateDateTime();
            userName.value = userStore.displayName
        });

        onUnmounted(() => {
            cancelAnimationFrame(updateDateTime);
        });

        const formattedDateTime = computed(() => {
            const now = currentDateTime.value;
            return date.format(now, 'keyboardDateTime12h')
        });

        const logOutDialog = () => {
            showLogOutDialog.value = true;
        };
        
        const confirmLogOut = () => {
            showLogOutDialog.value = false;
            userStore.logout();
            appStore.setLockState(false);
            router.push('/');
        };

        return {
            userStore,
            appStore,
            userName,
            showLogOutDialog,
            currentDateTime: formattedDateTime,
            confirmLogOut,
            logOutDialog
        }
        
    }
};
</script>

<style scoped>
</style>

