/**
 * Resolve waiting change log entries
 * @param {*} db
 * @param {*} changeLog
 * @param {*} localId
 * @param {*} serverId
 * @param {*} relationships
 */
async function changeLog(db, changeLog, localId, serverId, relationships) {
    await db.mapping.put({ table: changeLog.table, id: localId, sys_id: serverId })

    // update any pending change log entries
    console.debug(
        `Change Log Resolve :: updating ${changeLog.table} where key equals ${localId} to ${serverId}`
    )
    let result = await db.changeLog
        .where({ table: changeLog.table, key: localId })
        .modify({ key: serverId })

    console.debug(
        `Change Log Resolve :: ${result}, updating ${changeLog.table} where key equals ${localId} to ${serverId}`
    )

    result = await db.attachment
        .where({ table_name: changeLog.table, table_sys_id: localId })
        .modify({ table_sys_id: serverId })

    console.debug(
        `Attachment Document Version Resolve :: ${result}, updating relationships in changelog "${localId}" to "${serverId}"`
    )

    if (relationships) {
        for (let relationship of relationships) {
            let { table, field } = relationship

            const modification = {}
            modification[`data.${field}`] = serverId

            // update any pending change log entries
            result = await db.changeLog
                .where('relationships')
                .equals(`${changeLog.table}:${field}:${localId}`)
                .filter((x) => x.table === table) // only update the relationship table TODO: might not need this filter...?
                .modify(modification)

            // TODO: should cache these changes so we can reapply them if required...

            console.debug(
                `Change Log Resolve :: ${result}, updating relationships in changelog "${changeLog.table}:${field}:${localId}" to "${changeLog.table}:${field}:${serverId}"`,
                modification
            )
        }
    }
}

/**
 * Resolve data records
 * @param {*} db
 * @param {*} localId
 * @param {*} serverId
 * @param {*} table
 * @param {*} relationships
 */
async function data(db, localId, serverId, table, relationships) {
    // main record
    await db[table].where('id').equals(localId).modify({ id: serverId })

    if (relationships) {
        for (let relationship of relationships) {
            let { table, field } = relationship

            // update the local data
            const modification = {}
            modification[field] = serverId
            let result = await db[table].where(field).equals(localId).modify(modification)

            console.debug(
                `Data Resolve :: ${result}, updating ${table} where ${field} equals ${localId} to ${serverId}`,
                modification
            )
        }
    }
}

export default {
    changeLog,
    data
}
