import BaseModule from './base_module'
import sysChoice from '../../service-now/tables/sys_choice'
import api from '../../service-now/api'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import snSample from '../../service-now/tables/sn_customerservice_sample'
import snUrineSample from '../../service-now/tables/sn_customerservice_urine_samples'
import snBloodSample from '../../service-now/tables/sn_customerservice_blood_samples'
import snDCF from '../../service-now/tables/sn_customerservice_dcf'
import snDCOReport from '../../service-now/tables/sn_customerservice_dco_report'
import snSupReport from '../../service-now/tables/sn_customerservice_supp_report'
import snChainOfCustody from '../../service-now/tables/sn_customerservice_chain_of_custody'

class SYSChoice extends BaseModule {
    constructor() {
        super(sysChoice.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: snTestMission.table,
                value: 'u_test_type'
            },
            {
                table: snTestMission.table,
                value: 'u_test_mission_model'
            },
            {
                table: snTestSession.table,
                value: 'u_test_type'
            },
            {
                table: snSample.table,
                value: 'u_test_type'
            },
            {
                table: snUrineSample.table,
                value: 'u_was_athlete_permitted_to_shower_before_providing_this_sample'
            },
            {
                table: snUrineSample.table,
                value: 'u_were_any_modifications_made_in_collecting_the_sample'
            },
            {
                table: snBloodSample.table,
                value: 'u_was_a_blood_passport_sample_collected'
            },
            {
                table: snBloodSample.table,
                value: 'u_any_modifications_or_irregularities_to_standard_blood_collection_procedures_bl'
            },
            {
                table: snBloodSample.table,
                value: 'u_was_a_blood_sample_collected'
            },
            {
                table: snBloodSample.table,
                value: 'u_any_modifications_or_irregularities_to_standard_blood_collection_procedures'
            },
            {
                table: snBloodSample.table,
                value: 'u_was_dbs_sample_collected'
            },
            {
                table: snBloodSample.table,
                value: 'u_were_there_any_modifications_or_irregularities_to_standard_procedures_dbs'
            },
            {
                table: snDCF.table,
                value: 'u_consent_for_research'
            },
            {
                table: snDCF.table,
                value: 'u_address_type'
            },
            {
                table: snDCOReport.table,
                value: 'u_method_used_to_select_athlete'
            },
            {
                table: snSupReport.table,
                value: 'u_reason_for_the_report'
            },
            {
                table: snChainOfCustody.table,
                value: 'u_status'
            }
        ]
    }

    async getDcoReportSelectionMethodOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dco_report", "u_method_used_to_select_athlete"])
        .toArray()
    }

    async getDcoReportPipetteOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dco_report", "u_pipettes_used"])
        .toArray()
    }

    async getDcoReportBeakerOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dco_report", "u_beakers_used"])
        .toArray()
    }
    
    async getSupplementaryReportReasonOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_supp_report", "u_reason_for_the_report"])
        .toArray()
    }

    async getSampleTypeOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_sample", "u_sample_type"])
        .toArray()
    }

    async getNumberTubesOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_blood_samples", "u_blood_of_tubes"])
        .toArray()
    }

    async getDcfAddressTypes () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dcf", "u_address_type"])
        .toArray()
    }

    async getDcfDelayReasonOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dcf", "u_reason_for_delay"])
        .toArray()
    }

    async getIdentificationTypeOptions () {
        return await this.repository.db[this.name]
        .where('[name+element]')
        .equals(["sn_customerservice_dcf", "u_identification_document"])
        .toArray()
    }

    // Temp - fetch all and can retrieve the data ok
    // async fetch() {
    //     const query = ''
    //     const { data } = await api.getTableData(sysChoice.table, query, true)
    //     return data.result
    // }

    
    async fetch() {
        var tables = [
            'sn_customerservice_dcf',
            'sn_customerservice_urine_samples',
            'sn_customerservice_blood_samples',
            'sn_customerservice_dco_report',
            'sn_customerservice_supp_report',
            'sn_customerservice_sample',
            'sn_customerservice_chain_of_custody',
        ]

        var query = `nameIN${tables.join(',')}`
        var { data } = await api.getTableData(sysChoice.table, query, true)
        return [...data.result]
    }
}

const module = new SYSChoice()
export default module
