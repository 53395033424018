import BaseModule from './base_module'
import snUrineSample from '../../service-now/tables/sn_customerservice_urine_samples'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class UrineSample extends BaseModule {
    constructor() {
        super(snUrineSample.table)
        this.sync = true
        this.syncOrder = 12 // Loaded after DCF
    }

    async getByDcf(dcfId) {
        return await this.repository.db[this.name]
            .where('u_dcf_number')
            .equals(dcfId)
            .toArray()
    }

    async fetch() {
        const dcfNumbers = await dal.modules.sn_customerservice_dcf.getAll()
        // Get all the Blood Sample records that are related to the loaded DCF numbers.
        return await pagination.paginateData(
            'u_dcf_numberIN',
            dcfNumbers.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(snUrineSample.table, `${pageQuery}`)
        )
    }
}

const module = new UrineSample()
export default module