<template>
    <div class="document-viewer-container">

        <div v-if="pdfData" class="text-xs-center">
            <pdf-viewer :data="pdfData"></pdf-viewer>
        </div>
        <div v-else-if="imageData" class="text-xs-center">
            <img :src="imageData" alt="Attachment" style="max-width: 100%" />
        </div>
        
        <v-container class="pa-0" fluid>
            <v-row class="d-flex justify-end">
                <v-col cols="3" md="3" class="d-flex">
                    <v-btn @click="goBack" class="si-form-button-2 flex-grow-1" height="48"
                        variant="tonal">
                        Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-alert color="error" icon="mdi-alert" elevation="2" v-if="errorMessage">
            {{ errorMessage }}
        </v-alert>
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router';
import ds_document_version from '@/dal/modules/ds_document_version.js'
import attachment from '@/dal/modules/attachment';
import PdfViewer from '@/components/PdfViewer.vue'

const pdfData = ref(null)
const imageData = ref(null)
const errorMessage = ref('')
const router = useRouter()

const props = defineProps({
    documentId: String,
    content: Object,
    contentType: String
})

async function resolveDocument(documentId) {

    errorMessage.value = ''

    if (!documentId) {
        //pdfData.value = null
        return
    }

    const versions = await ds_document_version.select('document', documentId)

    if (versions.length === 0) {
        errorMessage.value = 'No versions found for this document'
        pdfData.value = null
        return
    }

    versions.sort((a, b) => parseFloat(b.version_number) - parseFloat(a.version_number))
    let file = await attachment.selectFirst('table_sys_id', versions[0].id)

    if (!file) {
        // clear the data
        errorMessage.value = 'No attachment found for this document'
        pdfData.value = null
        return
    }

    render(file.content_type, file._blob)
}

function render(contentType, content) {
    switch (contentType) {
        case "application/pdf":
            pdfData.value = content
            break
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
            imageData.value = URL.createObjectURL(content)
            break
        default:
            errorMessage.value = 'Attachment is not a PDF or image'
            // clear the data
            pdfData.value = null
            break
    }
}

function goBack() {
    router.go(-1)
}

onMounted(async () => {
    if (props.documentId) {
        await resolveDocument(props.documentId)
    }
    if (props.content && props.contentType) {
        render(props.contentType, props.content)
    }
})

// Watch the data prop
watch(() => props.documentId, async (documentId) => {
    await resolveDocument(documentId)
})

watch(() => props.content, async () => {
    render(props.contentType, props.content)
})

</script>
