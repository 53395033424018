/**
 * Fields that are server side incremental fields that should be ignored by the sync
 */
const serverIncrementalFields = ['sys_mod_count', 'sys_updated_on']

/**
 * Refactor the display names of the given records
 */
function resolve(data) {
    if (!Array.isArray(data) || data.length === 0) {
        return
    }

    for (let record of data) {
        // loop over the serverIncrementalFields and rename them so sync will ignore them
        for (let key in serverIncrementalFields) {
            let field = serverIncrementalFields[key]
            if (Object.prototype.hasOwnProperty.call(record, field)) {
                record[`_${field}`] = record[field]
                delete record[field]
            }
        }

        // loop over every property of the record
        for (let key in record) {
            let field = record[key]

            // if field has value and display_value or link properties even if they are empty
            if (
                Object.prototype.hasOwnProperty.call(field, 'value') &&
                (Object.prototype.hasOwnProperty.call(field, 'display_value') ||
                    Object.prototype.hasOwnProperty.call(field, 'link'))
            ) {
                record[key] = field.value

                // if the value is different from the display value stash it
                if (
                    Object.prototype.hasOwnProperty.call(field, 'display_value') &&
                    field.value !== field.display_value
                ) {
                    record[`_display_values`] = record[`_display_values`] || {}
                    record[`_display_values`][key] = field.display_value
                }

                // if field has link property
                if (Object.prototype.hasOwnProperty.call(field, 'link') && field.link) {
                    record[`_links`] = record[`_links`] || {}
                    record[`_links`][key] = field.link
                }
            }
        }
    }
}

export default {
    resolve
}
