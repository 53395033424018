import BaseModule from './base_module'
import snSample from '../../service-now/tables/sn_customerservice_sample'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class Sample extends BaseModule {
    constructor() {
        super(snSample.table)
        this.sync = true
        this.syncOrder = 11 // After Test Session
    }

    async fetch() {
        const testSessions = await dal.modules.sn_customerservice_test_session.getAll()
        // Get all the Sample records that are related to the loaded Test Sessions.
        return await pagination.paginateData(
            'u_test_session_idIN',
            testSessions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(snSample.table, `${pageQuery}`)
        )
    }
}

const module = new Sample()
export default module
