import { defineStore } from 'pinia'
import sn_customerservice_test_mission from '@/dal/modules/sn_customerservice_test_mission'

function removeDuplicatesById(array) {
    const map = new Map()
    array.forEach((item) => map.set(item.id, item))
    return Array.from(map.values())
}

export const useMissionStore = defineStore('mission', {
    state: () => ({
        mission: null,
        chaperones: [],
        soc: null,
        dco: null, // assigned Lead DCO
        bcoList: [], // List of assigned BCOs
        dcoList: [], // List of assigned DCOs
        scpList: [], // List of assigned SCPs
        notificationList: [],
        assignedSupportDcos: []
    }),
    actions: {
        async loadMission(id) {
            this.mission = await sn_customerservice_test_mission.get(id)
            if (!this.mission) {
                return
            }

            if (this.mission.u_assigned_lead_dco) {
                this.dco = {
                    id: this.mission.u_assigned_lead_dco,
                    name: this.mission._display_values.u_assigned_lead_dco
                }
                this.dcoList.push(this.dco)
                this.dcoList = removeDuplicatesById(this.dcoList)
            }
            this.soc = this.mission.u_soc
                ? { id: this.mission.u_soc, name: this.mission._display_values.u_soc }
                : null

            if (this.mission.u_assigned_chaperones) {
                const chaperoneIds = this.mission.u_assigned_chaperones.split(',')
                const chaperoneNames = this.mission._display_values.u_assigned_chaperones.split(',')
                this.chaperones = chaperoneIds.map((id, index) => ({
                    id: id,
                    name: chaperoneNames[index]
                }))
            } else {
                this.chaperones = []
            }

            if (this.mission.u_assigned_support_dcos.value) {
                const supportDcoIds = this.mission.u_assigned_support_dcos.split(',')
                const supportDcoNames =
                    this.mission._display_values.u_assigned_support_dcos.split(',')
                this.assignedSupportDcos = supportDcoIds.map((id, index) => ({
                    id: id,
                    name: supportDcoNames[index]
                }))
                this.dcoList = removeDuplicatesById([...this.dcoList, ...this.assignedSupportDcos])
            } else {
                this.assignedSupportDcos = []
            }

            if (this.mission.u_assigned_bcos) {
                const bcoIds = this.mission.u_assigned_bcos.split(',')
                const bcoNames = this.mission._display_values.u_assigned_bcos.split(',')
                this.bcoList = bcoIds.map((id, index) => ({ id: id, name: bcoNames[index] }))
            } else {
                this.bcoList = []
            }

            this.notificationList = removeDuplicatesById([...this.dcoList, ...this.chaperones]);
            this.scpList = removeDuplicatesById([...this.dcoList, ...this.bcoList, ...this.chaperones]);
        }
    }
})
