import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import TestView from '../views/TestView.vue'
import HomePortal from '@/views/Home/HomePortal.vue'
import MissionsDashboard from '@/views/Missions/MissionsDashboard.vue'
import MissionDetail from '@/views/Missions/MissionDetail.vue'
import DcfInformation from '@/views/DCF/DcfInformation.vue'
import DcfNotification from '@/views/DCF/Notification.vue'
import AthleteInformation from '@/views/DCF/AthleteInformation.vue'
import BloodQuestionnaire from '@/views/DCF/BloodQuestionnaire.vue'
import SamplesAll from '@/views/DCF/SamplesAll.vue'
import Medications from '@/views/DCF/Medications.vue'
import SignOff from '@/views/DCF/SignOff.vue'
import SamplesLanding from '@/views/Samples/SamplesLanding.vue'
import DocumentWindow from '@/views/Reports/DocumentWindow.vue'

import { useUserStore } from '@/stores/user.js'
import { useAppStore } from '@/stores/app.js'

import AppStatus from '@/views/AppStatus.vue'
import MissionInformation from '@/views/Missions/MissionInformation.vue'
import DcoReportEdit from '@/views/Reports/DcoReportEdit.vue'
import MissionUarEdit from '@/views/Reports/MissionUarEdit.vue'
import SupplementaryReportEdit from '@/views/Reports/SupplementaryReportEdit.vue'
import ViewDocument from '@/views/Missions/ViewDocument.vue'
import PftcReportEdit from '@/views/Reports/PftcReportEdit.vue'

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: LoginView,
            meta: { requiresAuth: false, syncComplete: true }
        },
        {
            path: '/home-portal',
            name: 'home-portal',
            component: HomePortal,
            meta: { requiresAuth: true }
        },
        {
            path: '/missions-dashboard',
            name: 'missions-dashboard',
            component: MissionsDashboard,
            meta: { requiresAuth: true }
        },
        {
            path: '/mission/:missionId',
            component: MissionInformation,
            name: 'mission-information',
            props: true,
            meta: { requiresAuth: true },
            children: [
                {
                    path: 'mission-detail',
                    component: MissionDetail,
                    name: 'mission-detail',
                    props: true,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'dco-report-edit/:id/',
                    component: DcoReportEdit,
                    name: 'mission-dco-report',
                    meta: { requiresAuth: true },
                    props: true
                },
                {
                    path: 'supplementary-report-edit/:id/:isMissionType/',
                    component: SupplementaryReportEdit,
                    name: 'mission-supplementary-report',
                    meta: { requiresAuth: true, isLockable: true },
                    props: true
                },
                {
                    path: 'uar-edit/:id/:isMissionType/',
                    component: MissionUarEdit,
                    name: 'mission-uar',
                    meta: { requiresAuth: true },
                    props: true
                },
                {
                    path: 'pftc-edit/:id/',
                    component: PftcReportEdit,
                    name: 'mission-pftc',
                    meta: { requiresAuth: true, isLockable: true },
                    props: true
                }
            ]
        },
        {
            path: '/document/view/:documentId',
            name: 'document-view',
            component: ViewDocument,
            meta: { requiresAuth: true },
            props: true
        },
        {
            path: '/test',
            name: 'test',
            component: TestView,
            meta: { requiresAuth: true }
        },
        {
            path: '/status',
            name: 'status',
            component: AppStatus,
            meta: { requiresAuth: true, syncComplete: true }
        },
        {
            path: '/:missionId/dcf/:dcfId',
            component: DcfInformation,
            name: 'dcf-information',
            props: true,
            meta: { requiresAuth: true },
            children: [
                {
                    path: 'notification',
                    component: DcfNotification,
                    name: 'dcf-notification',
                    props: true
                },
                {
                    path: 'athlete-information',
                    component: AthleteInformation,
                    name: 'dcf-athlete-information',
                    props: true
                },
                {
                    path: 'blood-questionnaire',
                    component: BloodQuestionnaire,
                    name: 'dcf-blood-questionnaire',
                    props: true
                },
                {
                    path: 'samples',
                    component: SamplesAll,
                    name: 'dcf-samples',
                    props: true
                },
                {
                    path: 'medications',
                    component: Medications,
                    name: 'dcf-medications',
                    props: true,
                    meta: { isLockable: true }
                },
                {
                    path: 'sign-off',
                    component: SignOff,
                    name: 'dcf-sign-off',
                    props: true
                }
            ]
        },
        {
            path: '/samples-shipping',
            name: 'samples-shipping',
            component: SamplesLanding,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/document-viewer/:documentId',
            name: 'document-viewer',
            component: DocumentWindow,
            props: true,
            meta: { requiresAuth: true }
        },

        // 404 redirect
        {
            path: '/:catchAll(.*)',
            redirect: '/home-portal'
        }
    ]
})

// This is a global navigation guard that checks if the user is authenticated
// before navigating to a route that requires authentication. Or checks if app is locked.

router.beforeEach((to, from, next) => {
    const userStore = useUserStore()
    const appStore = useAppStore()

    if (to.name === 'home' && userStore.accessToken) {
        next('/home-portal') // redirect to home portal if user is already logged in
    } else if (to.meta.requiresAuth && !userStore.accessToken) {
        next('/') // redirect to login if user is not authenticated
    } else if (!to.meta.syncComplete && !appStore.syncComplete) {
        next('/status') // if sync has failed redirect to status page
    } else if (appStore.isLocked && !to.meta.isLockable) {
        // Prevent navigation if the screen is locked and the target route is not the medications screen
        next(false)
    } else {
        next()
    }
})

export default router
