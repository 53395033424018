<template>
    <v-row>
      <v-col cols="12" style="padding:0 !important">
        <v-main>
          <div v-if="missionUar" style="min-height: 100vh;">

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2><span v-if="missionType">Mission</span><span v-else>Athlete</span> Unsuccessful Attempt Report</h2>
                            <DividerWithIcons />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            <span class="font-italic si-left-label">Arrival date and time</span>
                            <div style="display: flex">
                                <v-text-field v-model="arrivalDate" label="" density="compact" bg-color="white" type="date" required
                                    :error-messages="w$.arrivalDate.$errors.map(e => e.$message)" @blur="w$.arrivalDate.$touch"
                                    @input="updateArrivalDate">
                                </v-text-field>
                                <v-text-field v-model="arrivalTime" label="" density="compact" bg-color="white" type="time" required
                                    :error-messages="w$.arrivalTime.$errors.map(e => e.$message)" @blur="w$.arrivalTime.$touch"
                                    @input="updateArrivalTime">
                                </v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <span class="font-italic si-left-label">Departure date and time</span>
                            <div style="display: flex">
                                <v-text-field v-model="departureDate" label="" density="compact" bg-color="white" type="date" required
                                    :error-messages="w$.departureDate.$errors.map(e => e.$message)" @blur="w$.departureDate.$touch"
                                    @input="updateDepartureDate">
                                </v-text-field>
                                <v-text-field v-model="departureTime" label="" density="compact" bg-color="white" type="time" required
                                    :error-messages="w$.departureTime.$errors.map(e => e.$message)" @blur="w$.departureTime.$touch"
                                    @input="updateDepartureTime">
                                </v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <span class="font-italic si-left-label">Within 60 minute time slot?</span>
                           <v-checkbox v-model="missionUar.u_within_60_minute_time_slot" 
                              true-value="true" false-value="false" density="compact" bg-color="white" label="">
                           </v-checkbox>
                        </v-col>
                    </v-row>
                    <div class="si-form-gap"></div>
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Spoke with
                        </v-col>
                        <v-col cols="6" md="3" class="si-form">
                            <v-text-field v-model="missionUar.u_spoke_with" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Relationship/Role
                        </v-col>
                        <v-col cols="6" md="3" class="si-form">
                            <v-text-field v-model="missionUar.u_role" label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Location/Venue Name
                        </v-col>
                        <v-col cols="6" md="3" class="si-form">
                            <v-text-field label="" density="compact" bg-color="white" type="text"></v-text-field>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Address
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-combobox v-model="selectedAddress" label="" density="compact" :items="addressList" item-title="name"
                            @update:model-value="updateAddress" 
                            item-value="id" bg-color="white"></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="3" class="si-form">
                            Comments
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-textarea v-model="missionUar.u_comments" label="Comments" density="compact" bg-color="white">
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <div class="si-form-gap"></div>
                    <hr>
                    <div class="si-form-gap"></div>
                    <v-row style="align-items: center;">
                        <v-col cols="6" md="3" class="si-form">
                            <span class="si-top-label">DCO Name </span>
                            <v-combobox v-model="selectedDco" label="" density="compact" :items="dcoList" item-title="name"
                            @update:model-value="updateDco" 
                            item-value="id" bg-color="white"></v-combobox>
                        </v-col>
                        </v-row>
                        <v-row class="supplementary-question" style="align-items: center;">
                        <v-col cols="6" md="6" class="si-form-signature">
                            <span class="font-italic si-top-label">DCO Signature </span>
                            <div class="si-signature-field">
                                <vue-signature-pad
                                    ref='dcoSignature'
                                    :max-width='options.maxWidth'
                                    :min-width='options.minWidth'
                                    height="300px"
                                    :options='{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor,
                                    }'
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-btn @click='clearDCOSignature' class="si-right-action-button" size="large">
                                <v-icon icon="mdi-undo"></v-icon>
                            </v-btn>
                        </v-col>
                        </v-row>


                </v-container>
            </v-sheet>  


            <v-sheet v-if="!missionType" class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2>Signatures</h2>
                            <DividerWithIcons is-compact="true" />
                        </v-col>
                    </v-row>
                    
                    <v-row style="align-items: center;">
                        <v-col cols="6" md="6" class="si-form-signature">
                            <span class="font-italic si-top-label">Author Signature </span>
                            <div class="si-signature-field">
                                <vue-signature-pad
                                    ref='authorSignature'
                                    :max-width='options.maxWidth'
                                    :min-width='options.minWidth'
                                    height="300px"
                                    :options='{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor,
                                    }'
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-btn @click='clearAuthorSignature' class="si-right-action-button" size="large">
                                <v-icon icon="mdi-undo"></v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-sheet>
            
            <v-container class="pa-0" fluid>
                <v-row class="d-flex justify-end">
                    <v-col cols="3" md="3" class="d-flex">
                        <v-btn @click="goBack" class="si-form-button-2 flex-grow-1" height="48"
                            variant="tonal">
                            Back
                        </v-btn>
                    </v-col>
                    <v-col cols="3" md="3" class="d-flex">
                        <v-btn :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                            variant="tonal">
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>


          </div>
            </v-main>
        </v-col>
    </v-row>
  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, computed, toRaw} from 'vue'
import { useRouter, useRoute } from 'vue-router';
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import sn_customerservice_unsuccessful_attempt_record from '@/dal/modules/sn_customerservice_unsuccessful_attempt_record'
import { goBack, isTrue, dateTimePickerInitialise, dateTimePickerUpdate, mustBeValidDate, mustBeValidTime } from '@/utils/utils.js'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import { useMissionStore } from '@/stores/mission'
import cmn_location from '@/dal/modules/cmn_location';

export default {
    name: 'MissionUarEdit',
    components: {
        DividerWithIcons,
        VueSignaturePad
    },
    props: {
        id: {
            type: String,
            required: true
        },
        missionId: {
            type: String,
            required: false
        },
        athleteId: {
            type: String,
            required: false
        },
        sessionId: {
            type: String,
            required: true
        },
        isMissionType: {
            type: Boolean,
            required: true,
        }

    },
    setup(props, { emit }) {
        const mission = ref()
        const missionUar = ref({})
        const isLoaded = ref(false)
        const authorSignature = ref(null)
        const dcoSignature = ref(null)
        const arrivalDate = ref(null)
        const arrivalTime = ref(null)
        const departureDate = ref(null)
        const departureTime = ref(null)
        const withinTimeSlot = ref(null)
        const selectedDco = ref(null)
        const addressList = ref([])
        const selectedAddress = ref(null)
        const validationError = ref(false)
        const router = useRouter()
        const route = useRoute()
        const dcoList = ref([])
        const leadDco = ref(null)
        const missionStore = useMissionStore()
        const missionType = ref(false)

        const options = ref({
            penColor: 'rgb(0,0,0)',
            backgroundColor: 'rgb(255, 255, 255)',
            maxWidth: 2,
            minWidth: 2,
        })

        onMounted(async () => {

            if (props.isMissionType) {
                missionType.value = true
            }
            
            await missionStore.loadMission(props.missionId)
            dcoList.value = missionStore.dcoList
            leadDco.value = missionStore.leadDco

            addressList.value = await cmn_location.getAll()

            try {
                if (props.id && props.id !== 'new') {
                    missionUar.value = await sn_customerservice_unsuccessful_attempt_record.get(props.id)
                    if (missionUar.value.u_name != '') {
                        selectedDco.value = dcoList.value.find(dco => dco.name === missionUar.value.u_name)
                    }
                    if (missionUar.value.u_address != ''){
                        selectedAddress.value = addressList.value.find(address => address.id === missionUar.value.u_address)
                    }
                    if (missionUar.value.u_arrival_date_and_time) {
                        arrivalDate.value = dateTimePickerInitialise('date', missionUar.value.u_arrival_date_and_time)
                        arrivalTime.value = dateTimePickerInitialise('time', missionUar.value.u_arrival_date_and_time)
                    }
                    if (missionUar.value.u_departure_date_and_time) {
                        departureDate.value = dateTimePickerInitialise('date', missionUar.value.u_departure_date_and_time)
                        departureTime.value = dateTimePickerInitialise('time', missionUar.value.u_departure_date_and_time)
                    }

                } else {
                    missionUar.value = await sn_customerservice_unsuccessful_attempt_record.createNew()
                    missionUar.value.u_number = props.sessionId

                    if (leadDco.value && dcoList.value.length > 0) {
                        selectedDco.value = dcoList.value.find(dco => dco.id === leadDco.value)
                        missionUar.value.u_name = selectedDco.value ? selectedDco.value.name : ''
                    }
                }


            } catch (error) {
                console.error("Error:", error)
            } finally {
                isLoaded.value = true
            }

        });

        const close = () => {
            emit('close')
        };

        const clearDCOSignature = () => {
            if (dcoSignature.value) {
                dcoSignature.value.clearCanvas()
            }
        }

        const clearAuthorSignature = () => {
            if (authorSignature.value) {
                authorSignature.value.clearCanvas()
            }
        }
        
        const updateArrivalDate = (value) => {
            missionUar.value.u_arrival_date_and_time = dateTimePickerUpdate('date', value, missionUar.value.u_arrival_date_and_time)
        }

        const updateArrivalTime = (value) => {
            missionUar.value.u_arrival_date_and_time = dateTimePickerUpdate('time', value, missionUar.value.u_arrival_date_and_time)
        }

        const updateDepartureDate = (value) => {
            missionUar.value.u_departure_date_and_time = dateTimePickerUpdate('date', value, missionUar.value.u_departure_date_and_time)
        }

        const updateDepartureTime = (value) => {
            missionUar.value.u_departure_date_and_time = dateTimePickerUpdate('time', value, missionUar.value.u_departure_date_and_time)
        }

        const updateDco = (value) => {
            missionUar.value.u_name = value.name
        }

        const updateAddress = (value) => {
            missionUar.value.u_address = value.id
        }
        
        const missionUarRules = computed(() => {
            const localRules = {
                u_name: {  },
            }
            return localRules
        })

        const variableRules = computed(() => {
            const localRules = {
                arrivalDate: { required, mustBeValidDate: mustBeValidDate() },
                arrivalTime: { required, mustBeValidTime: mustBeValidTime() },
                departureDate: { required, mustBeValidDate: mustBeValidDate() },
                departureTime: { required, mustBeValidTime: mustBeValidTime() }
            }
            return localRules
        })

        const v$ = useVuelidate(missionUarRules, { missionUar })
        const w$ = useVuelidate(variableRules, { arrivalDate, arrivalTime, departureDate, departureTime })

        async function save() {
            v$.value.$validate()
            w$.value.$validate()
            if (v$.value.$invalid || w$.value.$invalid) {
                validationError.value = true
                return
            }
            let data = toRaw(missionUar.value)
            await sn_customerservice_unsuccessful_attempt_record.save(data)
            await dal.commit()
        }

        const handleGoBack = () => {
            goBack(router, route)
        };


        return {
            switchColor: '#2e4d23',
            mission,
            tab: null,
            isLoaded,
            missionUar,
            close,
            save,
            authorSignature,
            clearAuthorSignature,
            options,
            dcoSignature,
            clearDCOSignature,
            arrivalDate,
            arrivalTime,
            departureDate,
            departureTime,
            updateArrivalDate,
            updateArrivalTime,
            updateDepartureDate,
            updateDepartureTime,
            withinTimeSlot,
            dcoList,
            selectedDco,
            updateDco,
            addressList,
            selectedAddress,
            updateAddress,
            isTrue,
            goBack: handleGoBack,
            validationError,
            missionType,
            v$,
            w$
        }
    },

}
</script>

<style></style>
