import { v4 as uuid } from 'uuid'
import IndexDBRepository from './indexeddb-repository'
import resolver from './resolver'
import * as changeLib from './change.js'
import * as diffLib from './diff.js'
import auditDelete from './audit-delete.js'
import { useAppStore } from '@/stores/app.js'

var changelogTableName = 'changeLog'
var synclogTableName = 'syncLog'

class Repository extends IndexDBRepository {
    constructor(api, dbName, schema) {
        super(dbName, schema)
        this.api = api

        this.changeLogRepository = new IndexDBRepository('changes', [
            {
                version: 1,
                changes: {
                    changeLog: '++id,table,key,type,*relationships',
                    syncLog: 'id,table',
                    attachment: 'id, sys_id, table_name, table_sys_id',
                    mapping: '[table+id]'
                }
            }
        ])
    }

    _getSyncModules() {
        var modules = []
        var self = this
        this.getModules().forEach(function (module) {
            if (self._isModuleSync(module)) {
                modules.push(module)
            }
        })
        return modules
    }
    _logPull(modules, timeStamp) {
        var changeLogRepository = this.changeLogRepository

        // save data in correct tables
        var promises = []
        modules.forEach(function (module) {
            promises.push(
                (async function () {
                    await changeLogRepository.put(synclogTableName, {
                        id: module,
                        TimeStamp: timeStamp,
                        LocalTime: new Date(),
                        module: module
                    })
                })()
            )
        })
        return Promise.all(promises)
    }

    _markDataAsServer(data) {
        data.forEach(function (item) {
            item.id = item.id || item.sys_id
            item._server = true
        })
    }

    async _saveData(response, all) {
        console.log('Saving data => ' + response.module)

        if (!Array.isArray(response.data)) {
            console.warn('could not save data, no array found')
            return
        }

        // mark all objects as from server...
        this._markDataAsServer(response.data)

        // Clear and save data
        if (all) {
            await super.putAll(response.module, response.data)
        } else {
            await super.putArray(response.module, response.data)
        }

        return
    }

    async _push() {
        var self = this

        var savedChanges = {}

        async function pushChunk() {
            // get the oldest change
            let changeLogs = await self.changeLogRepository.getAll(changelogTableName, 0, 1)

            // Check if complete, no more data to sync...
            if (changeLogs.length === 0) {
                return
            }

            const changeLog = changeLogs[0]
            savedChanges[changeLog.table] = savedChanges[changeLog.table] || {}

            switch (changeLog.type) {
                case 'u':
                case 'update':
                    {
                        // TODO: if an update and no sys_id then need to look that up first
                        let data = await self.api.patch(
                            changeLog.table,
                            changeLog.key,
                            changeLog.data,
                            changeLog.metadata?.hash
                        )

                        savedChanges[changeLog.table][changeLog.key] = data

                        validateData(changeLog, data)
                    }
                    break

                case 'c':
                case 'create':
                case 'i':
                case 'insert':
                    {
                        let data = await self.api.post(
                            changeLog.table,
                            changeLog.data,
                            changeLog.metadata?.hash
                        )

                        validateData(changeLog, data, ['id'])

                        savedChanges[changeLog.table][changeLog.key] = data

                        const module = self.getModule(changeLog.table)

                        // resolve the pk and relationships
                        await self.resolveChangeLog(
                            changeLog,
                            changeLog.key,
                            data.sys_id,
                            module.relationships
                        )

                        // update any local data with the new sys_id
                        await self.resolveData(
                            changeLog.key,
                            data.sys_id,
                            changeLog.table,
                            module.relationships
                        )
                    }
                    break

                case 'd':
                case 'delete':
                    await self.api.deleteRecord(changeLog.table, changeLog.key)
                    // make sure its not recreatedby update/create before the delete is pushed
                    delete savedChanges[changeLog.table][changeLog.key]
                    break

                default:
                    throw new Error(`Unknown change type: ${changeLog.type}`)
            }

            await self.changeLogRepository.delete(changelogTableName, changeLog.id)

            await pushChunk()
        }

        if (!navigator.locks) {
            navigator.locks.request('sync-push-changelog', pushChunk)
        } else {
            console.warn('WebLockAPI not available, using async not thread safe')
            await pushChunk()
        }

        // Update the local db after all changes have been pushed
        for (let table in savedChanges) {
            let savedTableChanges = savedChanges[table]
            for (let key in savedTableChanges) {
                let data = savedTableChanges[key]
                this._markDataAsServer([data])
                await super.put(table, data)
            }
        }

        // update attachments as last step
        await this.getModuleByTableName('attachment').upload()

        /**
         * Check all changes have been pushed and the data is correct
         * @param {*} changeLog
         * @param {*} data
         */
        function validateData(changeLog, data, ignoreKeys = []) {
            let error = []
            for (let key in changeLog.data) {
                if (ignoreKeys && ignoreKeys.includes(key)) {
                    continue
                }

                if (changeLog.data[key] !== data[key]) {
                    const errorMessage = `${key} ${changeLog.data[key]} !== ${data[key]}`
                    console.assert(
                        changeLog.data[key] === data[key],
                        `Data not updated: ${changeLog.table} ${errorMessage}`
                    )
                    error.push(errorMessage)
                }
            }
            if (error.length > 0) {
                throw new Error(`Data not updated: ${changeLog.table}, ${error.join(', ')}`)
            }
        }
    }

    async _deleteCache() {
        var promises = []
        var putAll = super.putAll.bind(this)

        this._getSyncModules().forEach(function (module) {
            promises.push(
                (async function () {
                    await putAll(module.name, [])
                })()
            )
        })

        await Promise.all(promises)
    }

    // Get all data
    async _pull() {
        const appStore = useAppStore()
        // TDOD: This date should be set to start of the current financial year.
        let startDate = new Date(2024, 0, 1)

        let modules = this._getSyncModules()
        let syncLogs = await this.changeLogRepository.getAll(synclogTableName)

        modules.sort((a, b) => a.syncOrder - b.syncOrder)

        for (let module of modules) {
            let syncDate = new Date()

            await appStore.setSyncStatus(`fetching ${module.name}`)
            console.debug('Sync => fetching', module.syncOrder, module.name)

            let syncLog = syncLogs.find((x) => x.module === module.name)

            let dateFrom = syncLog ? syncLog.TimeStamp : startDate

            const data = await module.fetch(dateFrom)

            await appStore.setSyncStatus('saving ' + module.name)
            await this._saveData(
                {
                    module: module.name,
                    data
                },
                syncLog?.TimeStamp === null,
                !module.cacheDoNotClear // clear the table
            )

            await this._logPull([module.name], syncDate)
        }

        // No deletes for now
        // await this._pullDeletes(syncLogs, startDate)

        return
    }

    async _pullDeletes(syncLogs, startDate) {
        let syncLog = syncLogs.find((x) => x.module === '_delete')
        let syncDate = new Date()
        let dateFrom = syncLog ? syncLog.TimeStamp : startDate
        await auditDelete.deleteFromApi(this.getModuleNames(), dateFrom)
        await this._logPull(['_delete'], syncDate)
    }

    _isModuleSync(module) {
        return module.sync || module.syncSingle
    }

    _isModuleMedia(module) {
        return module.syncSingle
    }

    _isModuleMediaByName(name) {
        var module = this.getModule(name)
        return this._isModuleMedia(module)
    }

    async _getPk(module, data) {
        let pk = await super.getPrimaryKey(module)
        return data[pk]
    }

    async save(name, data) {
        if (Array.isArray(data)) return this.putArray(name, data)
        return this.put(name, data)
    }

    async put(name, data) {
        var module = this.getModule(name)
        if (this._isModuleSync(module)) {
            await this._put(name, data)
        } else {
            return super.put(name, data)
        }
    }
    async _put(name, data) {
        var key = await this._getPk(name, data)
        var existing = key ? await super.get(name, key) : null

        // new object assign an id
        if (!key) {
            key = data.id = uuid()
        }

        // create relationship map for changelog
        var module = this.getModule(name)
        var relationships = []
        if (module.parentRelationships) {
            for (let relationship of module.parentRelationships) {
                if (Object.prototype.hasOwnProperty.call(data, relationship.field)) {
                    relationships.push(
                        `${relationship.table}:${relationship.field}:${data[relationship.field]}`
                    )
                }
            }
        }

        await super.put(name, data)

        let changeLog = null

        if (existing) {
            const diff = diffLib.getObjectDiff(data, existing)

            if (!diffLib.isEmpty(diff)) {
                changeLog = changeLib.createUpdate(name, diff, key, relationships, {
                    hash: existing._sys_id
                })
            }
        } else {
            changeLog = changeLib.createInsert(name, data, key, relationships)
        }

        if (changeLog) {
            await this.changeLogRepository.putChangeLog(changeLog)
        }
    }

    async putArray(name, data) {
        var module = this.getModule(name)
        var self = this
        if (this._isModuleSync(module)) {
            // TODO: this could be optimised by bulk adding the _putSync
            var promises = []
            data.forEach(function (item) {
                promises.push(
                    (async function () {
                        await self._put(name, item)
                    })()
                )
            })
            await Promise.all(promises)
        }
    }

    async delete(name, keys) {
        var module = this.getModule(name)
        var self = this
        if (this._isModuleSync(module)) {
            // Single delete
            if (!Array.isArray(keys)) return this._delete(name, keys)

            // Empty array
            if (keys.length === 0) return

            // Multi delete
            var promises = []
            keys.forEach(function (key) {
                promises.push(
                    (async function () {
                        await self._delete(name, key)
                    })()
                )
            })
            await Promise.all(promises)
        }
        return super.delete(name, keys)
    }

    /**
     * Delete a record from the database no changelog
     * @param {*} name
     * @param {*} keys
     * @returns
     */
    async remove(name, keys) {
        return super.delete(name, keys)
    }

    async _delete(name, key) {
        var existing = await super.get(name, key)

        // on server send delete request
        if (existing._server) {
            const del = changeLib.createDelete(name, key, null, {
                hash: existing._sys_id
            })
            await this.changeLogRepository.put(changelogTableName, del)
        }

        await super.delete(name, key)
    }

    async select(name, indexName, value, offset, take) {
        var results = await super.select(name, indexName, value, offset, take)

        if (this._isModuleMediaByName(name)) {
            var unsynced = await this.changeLogRepository.select(
                name,
                indexName,
                value,
                offset,
                take
            )
            results = results.concat(unsynced)
        }

        return results
    }

    async open() {
        await this.changeLogRepository.open()
        await super.open()
    }

    async reset() {
        if (!navigator.onLine) {
            return
        }

        const appStore = useAppStore()
        await appStore.setSyncStatus('pushing changes')

        await this._push() // Push data changes

        // clear all the tables
        for (var module of this.getModules()) {
            await super.clear(module.name)
        }

        // clear the sync log
        await this.changeLogRepository.clear(synclogTableName)

        // sync again
        await this.syncAll()
    }

    async syncAll() {
        if (!navigator.onLine) {
            return
        }

        const appStore = useAppStore()
        console.log('SYNC => Sync all starting')
        await appStore.setLoading(true)
        try {
            await this.changeLogRepository.open()
            await this.open()

            await appStore.setSyncStatus('pushing changes')

            await this._push() // Push data changes

            await this._pull() // Pull changes

            console.log('SYNC => Sync all finished')
            await appStore.setSyncComplete()
        } finally {
            await appStore.setLoading(false)
        }
    }

    async clear() {
        for (var module of this.getModules()) {
            await super.clear(module.name)
        }

        await this.changeLogRepository.clear(changelogTableName)
        await this.changeLogRepository.clear(synclogTableName)
    }

    async downloadChanges() {
        await this.changeLogRepository.open()
        var changes = await this.changeLogRepository.getAll('changeLog')
        window.App.util.saveTextFile(JSON.stringify([changes]), 'backup.json')
    }

    async commit() {
        if (navigator.onLine) {
            try {
                await this._push()
                return true
            } catch (e) {
                console.error('Could not push changes', e)
                return false
            }
        }
        return true
    }

    resolveChangeLog(changeLog, localId, serverId, relationships) {
        return resolver.changeLog(
            this.changeLogRepository.db,
            changeLog,
            localId,
            serverId,
            relationships
        )
    }

    resolveData(localId, serverId, table, relationships) {
        return resolver.data(this.db, localId, serverId, table, relationships)
    }

    getModuleByTableName(name) {
        return this.getModules().find((x) => x.name === name)
    }

    /**
     * Clone an object and assign a new id
     * @param {*} obj
     * @returns
     */
    clone(obj) {
        var newObject = Object.assign({}, obj)
        newObject.id = null
        return newObject
    }
}

export default Repository
