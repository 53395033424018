<template>
   <form-side-menu :dcfId="dcfId" :hasBloodPassport="hasBloodPassport" :hasHgh="hasHgh"></form-side-menu>
   <v-container fluid class="si-form-container">
      <main-header></main-header>
      <v-alert color="warning" icon="mdi-lock" elevation="2" v-if="isLocked" class="mb-4">
         This DCF is locked.

         <span v-if="canUnlock">Please unlock to make changes.</span>

         <v-btn v-if="canUnlock" @click="unlock" class="float-right">
            Unlock
         </v-btn>
      </v-alert>
      <v-card v-if="athlete && missionInfo" variant="flat" class="si-form-info-bar mt-4" rounded="0">
         <v-row dense>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Athlete Name</span>
                  <span class="si-info-subheading">Te Ingoa Kaipara</span>
                  <span class="si-info-value">{{ athlete.name }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">DOB</span>
                  <span class="si-info-subheading">Te Rā Whānau</span>
                  <span class="si-info-value">{{ formatDate(athlete.u_dob) }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Sport</span>
                  <span class="si-info-subheading">Te Rā Whānau</span>
                  <span v-if="missionInfo" class="si-info-value">{{ missionInfo.u_sport }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Discipline</span>
                  <span class="si-info-subheading">Te Rā Whānau</span>
                  <span v-if="missionInfo" class="si-info-value">{{ missionInfo.u_discipline }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Sample type/s</span>
                  <span class="si-info-subheading">Ngā Momo Tīpako</span>
                  <span v-if="mission" class="si-info-value"> {{ getSampleTypes(mission) }}</span>
               </div>
            </v-col>
         </v-row>
      </v-card>
      <fieldset :disabled="isLocked" class="position-relative">
         <v-overlay v-model="isLocked" contained></v-overlay>
         <RouterView />
      </fieldset>
      <v-dialog v-model="showUnlockDialog" max-width="600">
         <v-card prepend-icon="mdi-account" title="Unlock DCF">
            <v-card-text>
               <v-row dense>
                  <v-col cols="12">
                     <v-textarea v-model="unlockReason" label="Reason for unlock" required
                        :error-messages="unlockValidator.unlockReason.$errors.map(e => e.$message)"></v-textarea>
                  </v-col>
               </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
               <v-spacer></v-spacer>

               <v-btn text="Close" variant="plain" @click="showUnlockDialog = false"></v-btn>

               <v-btn color="primary" text="Unlock" variant="tonal" @click="unlockConfirm"></v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-container>
</template>

<script>

import { ref, onMounted, computed } from 'vue'
import FormSideMenu from '@/components/FormSideMenu.vue'
import MainHeader from '@/components/MainHeader.vue'
import { formatDate } from '@/utils/utils.js'
import { useDcfStore } from '@/stores/dcf'
import { useMissionStore } from '@/stores/mission'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import sn_customerservice_blood_samples from '@/dal/modules/sn_customerservice_blood_samples.js'

export default {
   name: 'DcfInformation',
   components: {
      FormSideMenu,
      MainHeader,
   },
   props: {
      dcfId: {
         type: String,
         required: true
      },
      missionId: {
         type: String,
         required: true
      }
   },
   setup(props) {

      const dcfStore = useDcfStore()
      const athlete = computed(() => dcfStore.athlete)
      const isLocked = computed(() => dcfStore.isLocked)
      const canUnlock = ref(false)

      const missionStore = useMissionStore()
      const mission = computed(() => missionStore.mission)
      const missionInfo = ref(null)

      const hasBloodPassport = ref(false)
      const hasHgh = ref(false)

      const showUnlockDialog = ref(false)
      const unlockReason = ref('')
      const unlockRules = computed(() => ({
         unlockReason: { required },
      }))
      const unlockValidator = useVuelidate(unlockRules, { unlockReason })

      onMounted(async () => {

         await dcfStore.loadDcf(props.dcfId)
         await missionStore.loadMission(props.missionId)
         const bloodPassportSamples = await sn_customerservice_blood_samples.getBloodPassportSamplesByDcf(props.dcfId)
         if (bloodPassportSamples.length > 0) {
            hasBloodPassport.value = true
            dcfStore.hasBloodPassport = true
         }    
         const bloodHghSamples = await sn_customerservice_blood_samples.getBloodSamplesByDcf(props.dcfId)
         if (bloodHghSamples.length > 0) {
            hasHgh.value = true
            dcfStore.hasHgh = true
         }

         missionInfo.value = mission.value._display_values

         // track isLocked value as its current date time based
         setInterval(async () => {
            canUnlock.value = dcfStore.canUnlock
         }, 500)
      })

      const getSampleTypes = (mission) => {
         let sampleTypes = [];
         if (mission.u_blood_tests_required != "" && mission.u_blood_tests_required != "0") {
            sampleTypes.push("Blood")
         }
         if (mission.u_urine_tests_required != "" && mission.u_urine_tests_required != "0") {
            sampleTypes.push("Urine")
         }
         return sampleTypes.join(", ")
      }

      const unlock = async () => {
         showUnlockDialog.value = true
      }

      const unlockConfirm = async () => {

         unlockValidator.value.$validate();
         if (!unlockValidator.value.$invalid) {
            if (!await dcfStore.unlock(unlockReason.value)) {
               // do nothing
            }
            showUnlockDialog.value = false
         }
      }

      return {
         tab: null,
         loading: false,
         formatDate,
         athlete,
         mission,
         missionInfo,
         getSampleTypes,
         isLocked,
         canUnlock,
         unlock,
         showUnlockDialog,
         unlockReason,
         unlockConfirm,
         unlockValidator,
         hasBloodPassport,
         hasHgh

      }
   }
}
</script>

<style></style>
