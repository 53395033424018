const instanceUrl = import.meta.env.VITE_SERVICE_NOW_URL
const clientId = '' // this will be injected by our proxy service
const clientSecret = '' // this will be injected by our proxy service
import Cookies from 'js-cookie'

async function login(username, password) {
    const data = new URLSearchParams({
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
        username: username,
        password: password
    })

    return post(data)
}

async function getUserDetails() {
    const json = Cookies.get('user_details')
    return JSON.parse(json)
}

async function refreshToken(refreshToken) {
    if (refreshToken === 'cookie-token') {
        const tokenUrl = `/auth/refresh`
        try {
            var response = await fetch(tokenUrl, {
                method: 'POST'
            })
            await response.json()

            return 'cookie-token'
        } catch (error) {
            console.error(
                'Error fetching token:',
                error.response ? error.response.data : error.message
            )
        }
    }

    const data = new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: clientId,
        client_secret: clientSecret,
        refresh_token: refreshToken
    })

    return post(data)
}

async function post(data) {
    const tokenUrl = `${instanceUrl}/oauth_token.do`
    try {
        var response = await fetch(tokenUrl, {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
        const token = await response.json()
        return token
    } catch (error) {
        console.error('Error fetching token:', error.response ? error.response.data : error.message)
    }
}

async function fetchCsrfToken() {
    if (!navigator.onLine) {
        return
    }

    try {
        const response = await fetch('/auth/csrf-token')
        if (!response.ok) {
            console.error('There was a problem with the fetch csrf token operation')
        }
        return await response.text()
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
    }
}

export default {
    login,
    refreshToken,
    getUserDetails,
    fetchCsrfToken
}
