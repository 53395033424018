import BaseModule from './base_module'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import snMissionInstruction from '../../service-now/tables/sn_customerservice_m2m_mission_instruction'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'
import snDCOReport from '../../service-now/tables/sn_customerservice_dco_report'
import snOffered from '../../service-now/tables/sn_customerservice_offered'
import snUnsuccessfulAttemptRecord from '../../service-now/tables/sn_customerservice_unsuccessful_attempt_record'
import api from '../../service-now/api'
import { useUserStore } from '@/stores/user'
import pagination from '../../service-now/utils/pagination'
import dsDocument from '@/service-now/tables/ds_document'
import dsDocumentReference from '../../service-now/tables/ds_document_references'
import dal from '../dal'

class TestMission extends BaseModule {
    constructor() {
        super(snTestMission.table)

        this.sync = true

        this.syncOrder = 1 // After Offered

        this.relationships = [
            {
                table: snMissionInstruction.table,
                field: 'u_test_mission'
            },
            {
                table: snTestSession.table,
                field: 'u_mission_order'
            },
            {
                table: dsDocument.table,
                field: 'parentRecordSysId'
            },
            {
                table: dsDocumentReference.table,
                field: 'target_record'
            },
            {
                table: snDCOReport.table,
                field: 'u_number'
            },
            {
                table: snOffered.table,
                field: 'u_offer'
            },
            {
                table: snUnsuccessfulAttemptRecord.table,
                field: 'u_number'
            }
            // NOTE: this fields MUST also have dixiejs indexs in the schema
        ]
    }

    async fetch() {
        const userStore = useUserStore()
        const userId = userStore.user_sys_id
        // Get all the Test Missions that are assigned to the logged in DCO which are either in the state Offered, Accepted, Confirmed, or In-Mission
        const query = `u_assigned_lead_dcoCONTAINS${userId}^state!=${snTestMission.states.new}^state!=${snTestMission.states.results}^state!=${snTestMission.states.cancelled}`
        const query2 = `u_assigned_support_dcosCONTAINS${userId}^state!=${snTestMission.states.new}^state!=${snTestMission.states.results}^state!=${snTestMission.states.cancelled}`
        const { assignedMissionsData } = await api.getTableData(snTestMission.table, query, true)
        const { supportMissionsData } = await api.getTableData(snTestMission.table, query2, true)

        const assignedMissions = assignedMissionsData ? assignedMissionsData.result : []
        const supportMissions = supportMissionsData ? supportMissionsData.result : []

        const offers = await dal.modules.sn_customerservice_offered.getAll()

        const offeredMissions = await pagination.paginateData(
            `sys_idIN`,
            offers.map((x) => x.u_offer),
            (pageQuery) =>
                api.getTableData(
                    snTestMission.table,
                    `${pageQuery}^state!=${snTestMission.states.new}^state!=${snTestMission.states.results}^state!=${snTestMission.states.cancelled}`, true
                )
        )

        return [...assignedMissions, ...supportMissions, ...offeredMissions]
    }
}

const module = new TestMission()
export default module
