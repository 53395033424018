<template>
  <div>
    <form-side-menu></form-side-menu>
    <v-container fluid class="si-form-container">
      <main-header></main-header>
      <v-row>
        <v-col cols="12">
          <v-main>
            <div style="min-height: 100vh;">
              <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" md="12">
                      <h2>Missions</h2>
                      <DividerWithIcons />
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                          v-model="search"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          variant="outlined"
                          hide-details
                          single-line
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="si-expansion-filter-row">
                        <v-col cols="3">
                          <v-switch v-model="userStore.showCurrentAssignedMissions" color="#2e4d23"
                            :label="'Current' + ' (' + missionTotals.current + ')'"></v-switch>
                        </v-col>
                        <v-col cols="3">
                          <v-switch v-model="userStore.showCompletedAssignedMissions" color="#2e4d23"
                            :label="'Completed' + ' (' + missionTotals.completed + ')'"></v-switch>
                        </v-col>
                      </v-row>
                      <v-expansion-panels v-model="panel" style="margin: 0 0 40px 0;">
                        <v-expansion-panel class="si-expansion-panel">
                          <v-expansion-panel-title color="#f97316">
                            Assigned Missions
                          </v-expansion-panel-title>
                          <v-expansion-panel-text>
                            <v-col v-for="(mission, i) in assignedMissionsDataFiltered" :key="i" cols="12" md="12">
                              <v-card class="mx-auto">
                                <v-card-item>
                                  <div class="d-flex justify-space-between align-center ">
                                    <div class="flex-grow-1 flex-shrink-0">
                                      <v-row>
                                        <v-col cols="12" md="6" class="d-flex flex-column">
                                          <h4>{{ mission.number }}</h4>
                                          <span>{{ mission._display_values.u_sport }}&nbsp;</span>
                                          <span>Test Type: {{ mission._display_values.u_test_type }}</span>
                                          <span>Number of Athletes: {{ mission.u_total_test_sessions }}</span>
                                          <span>{{ formatTestingWindow(mission) }}</span>

                                        </v-col>
                                        <v-col cols="12" md="6" class="d-flex flex-column">
                                          <span>{{ mission._display_values.u_venue }}&nbsp;</span>
                                          <span>{{ mission._display_values.u_venue_address }}&nbsp;</span>
                                          <span>{{ mission._display_values.u_region }}&nbsp;</span>
                                          <span>&nbsp;</span>
                                          <span>SOC: {{ mission._display_values.u_soc }}</span>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <v-btn class="si-right-action-button" @click="openMission(mission)">
                                      <v-icon icon="mdi-chevron-right"></v-icon>
                                    </v-btn>
                                  </div>
                                </v-card-item>
                              </v-card>
                            </v-col>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <hr>
                      <v-row class="si-expansion-filter-row">
                        <v-col cols="3">
                          <v-switch v-model="userStore.showOfferedMissions" color="#2e4d23"
                            :label="'Offered' + ' (' + missionTotals.offered + ')'"></v-switch>
                        </v-col>
                        <v-col cols="3">
                          <v-switch v-model="userStore.showAcceptedMissions" color="#2e4d23"
                            :label="'Accepted' + ' (' + missionTotals.accepted + ')'"></v-switch>
                        </v-col>
                        <v-col cols="6">
                          <v-switch v-model="userStore.showTentativelyAcceptedMissions" color="#2e4d23"
                            :label="'Tentatively Accepted' + ' (' + missionTotals.tentativelyAccepted + ')'"></v-switch>
                        </v-col>
                      </v-row>
                      <v-expansion-panels v-model="panel2" style="margin: 0 0 40px 0;">
                        <v-expansion-panel class="si-expansion-panel">
                          <v-expansion-panel-title color="#758f01">
                            Offered Missions
                          </v-expansion-panel-title>
                          <v-expansion-panel-text>
                            <v-col v-for="(mission, i) in offeredMissionsDataFiltered" :key="i" cols="12" md="12">
                              <v-card class="mx-auto">
                                <v-card-item v-if="mission.missionData">
                                  <div class="d-flex justify-space-between align-center ">
                                    <div class="flex-grow-1 flex-shrink-0">
                                      <v-row>
                                        <v-col cols="12" md="6" class="d-flex flex-column">
                                          <h4>{{ mission.missionData.number }}</h4>
                                          <span>{{ mission.missionData._display_values.u_sport }}&nbsp;</span>
                                          <span>Test Type: {{ mission.missionData._display_values.u_test_type }}</span>
                                          <span>Number of Athletes: {{ mission.u_total_test_sessions }}</span>
                                          <span>{{ formatTestingWindow(mission.missionData) }}</span>

                                          <v-chip class="si-status-chip" style="margin-top: 10px;"
                                            :color="mission.color" variant="flat">
                                            {{ mission.status }}
                                          </v-chip>
                                          <span v-if="mission.offer.u_state === 'tentatively_accepted'" style="margin-top:20px;">
                                            Comments: {{ mission.offer.u_reason_for_tentatively_accepting_the_offer }}</span>
                                        </v-col>
                                        <v-col cols="12" md="6" class="d-flex flex-column">
                                          <span>{{ mission.missionData._display_values.u_venue }}&nbsp;</span>
                                          <span>{{ mission.missionData._display_values.u_venue_address }}&nbsp;</span>
                                          <span>{{ mission.missionData._display_values.u_region }}&nbsp;</span>
                                          <span>&nbsp;</span>
                                          <span>SOC: {{ mission.missionData._display_values.u_soc }}</span>
                                        </v-col>
                                      </v-row>
                                    </div>
                                    <v-menu v-if="mission.offer.u_state === 'offered'" class="si-dropdown-menu">
                                      <template v-slot:activator="{ props }">
                                        <v-btn v-bind="props" class="si-right-action-button">
                                          <v-icon icon="mdi-dots-horizontal-circle-outline"></v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item>
                                          <v-list-item-title @click="openOfferDialog(mission.offer, 'accept')"><v-icon
                                              icon="mdi-checkbox-outline"></v-icon>Accept</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title @click="openOfferDialog(mission.offer, 'tentative')"><v-icon
                                              icon="mdi-checkbox-blank-badge-outline"></v-icon>Tentatively
                                            Accept</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-title @click="openOfferDialog(mission.offer, 'decline')"><v-icon
                                              icon="mdi-close-box-outline"></v-icon>Decline</v-list-item-title>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </div>
                                </v-card-item>
                              </v-card>
                            </v-col>
                          </v-expansion-panel-text>
                        </v-expansion-panel>
                      </v-expansion-panels>

                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </div>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="showOfferDialog" transition="dialog-bottom-transition">
      <v-card :prepend-icon="offerDialogProps.icon" :title="offerDialogProps.title" style="margin-left: auto;
        margin-right: auto;
        min-width: 600px;">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-textarea v-if="offerDialogProps.type == 'tentative'"
                v-model="offerDialogProps.offer.u_reason_for_tentatively_accepting_the_offer"
                label="Please provide reason" density="compact" bg-color="white">
              </v-textarea>
              <v-textarea v-else-if="offerDialogProps.type == 'decline'"
                v-model="offerDialogProps.offer.u_reason_for_decline" label="Please provide reason" density="compact"
                bg-color="white">
              </v-textarea>
              <p v-else-if="offerDialogProps.type == 'accept'">
                Are you sure you want to accept this offer?
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="Close" variant="plain" @click="showOfferDialog = false"></v-btn>
          <v-btn text="Confirm" variant="tonal" @click="saveOffer(offerDialogProps)"></v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user.js'
import { formatDateLocal } from '@/utils/utils.js'
import FormSideMenu from '@/components/FormSideMenu.vue'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import MainHeader from '@/components/MainHeader.vue'
import sn_customerservice_offered from '@/dal/modules/sn_customerservice_offered'
import sn_customerservice_test_mission from '@/dal/modules/sn_customerservice_test_mission'
import sn_mission from '@/service-now/tables/sn_customerservice_test_mission'

export default {
  name: 'MissionsDashboard',
  components: {
    FormSideMenu,
    MainHeader,
    DividerWithIcons
  },
  setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const fetchedMissions = ref([])
    const allMissions = ref([])
    const offered = ref([])
    const dcoId = ref({})
    const panel = ref([0])
    const panel2 = ref([0])
    const showOfferDialog = ref(false)
    const offerDialogProps = ref(null)
    const search = ref('')

    onMounted(async () => {
      try {
        dcoId.value = userStore.user_sys_id
        if (!dcoId.value) {
          console.error("User not found")
          return;
        }
        const leadMissions = await sn_customerservice_test_mission.select("u_assigned_lead_dco", dcoId.value)

        allMissions.value = await sn_customerservice_test_mission.getAll()
        const supportMissions = allMissions.value.filter(mission => {
          if (!mission.u_assigned_support_dco) {
            return false
          }
          const supportDcoIds = mission.u_assigned_support_dco.split(',')
          return supportDcoIds.includes(dcoId.value)
        });
        const combinedMissions = new Set([...leadMissions, ...supportMissions])
        fetchedMissions.value = Array.from(combinedMissions)
        offered.value = await sn_customerservice_offered.select("u_offered_to", dcoId.value)

      } catch (error) {
        console.error("Error fetching missions:", error)
      }
    });

    const reloadOffered = async () => {
      try {
        offered.value = await sn_customerservice_offered.select("u_offered_to", dcoId.value);
      } catch (error) {
        console.error("Error reloading offered missions:", error);
      }
    };

    const offeredMissionMapped = computed(() => {
      let data = offered.value.map(offer => ({
        offer: offer,
        missionData: allMissions.value.find(mission => mission.id === offer.u_test_mission || mission.id === offer.u_offer) || null,
        color: offer.u_state === 'offered' ? 'orange' : offer.u_state === 'accepted' ? 'green' : 'blue',
        status: offer.u_state === 'offered' ? 'Offered' : offer.u_state === 'accepted' ? 'Accepted' : 'Tentatively Accepted'
      }));
      return data.filter(offer => {
        const missionExistsInFetched = fetchedMissions.value.some(mission => mission.id === offer.missionData?.id)
        return !missionExistsInFetched
      });
    });

    const offeredMissionsDataFiltered = computed(() => {
      let data = offeredMissionMapped.value
        .filter(offeredMission => {
          if (offeredMission.offer.u_state === 'offered' && userStore.showOfferedMissions) {
            return true;
          }
          if (offeredMission.offer.u_state === 'accepted' && userStore.showAcceptedMissions) {
            return true;
          }
          if (offeredMission.offer.u_state === 'tentatively_accepted' && userStore.showTentativelyAcceptedMissions) {
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          const dateA = new Date(a.missionData.u_scheduled_start_date);
          const dateB = new Date(b.missionData.u_scheduled_start_date);
          if (!a.missionData.u_scheduled_start_date) return 1;
          if (!b.missionData.u_scheduled_start_date) return -1;
          return dateA - dateB;
        });

      data.forEach(x => {
        x.searchMeta = (x.missionData && x.missionData._display_values) ? (x.missionData.number.toLowerCase() + " " + x.missionData._display_values.u_sport
         + " " + x.missionData._display_values.u_test_type + " " + x.missionData._display_values.u_venue + " " + x.missionData._display_values.u_region
         + " " + x.missionData._display_values.u_venue_address + " " + x.missionData._display_values.u_soc ) : ''
      });
      if (search.value && search.value.length > 0) {  
        data = data.filter(x => {
          return x.searchMeta.toLowerCase().includes(search.value.toLowerCase())
        });
      }

      return data;
    });

    const assignedMissionsDataFiltered = computed(() => {
      let data = fetchedMissions.value
        .filter(mission => {
          if (mission.state !== sn_mission.states.completed.toString() && userStore.showCurrentAssignedMissions) {
            return true;
          }
          if (mission.state === sn_mission.states.completed.toString() && userStore.showCompletedAssignedMissions) {
            return true;
          }
          return false;
        })
        .sort((a, b) => {
          const dateA = new Date(a.u_scheduled_start_date);
          const dateB = new Date(b.u_scheduled_start_date);
          if (!a.u_scheduled_start_date) return 1;
          if (!b.u_scheduled_start_date) return -1;
          return dateA - dateB;
        });

      data.forEach(x => {
        x.searchMeta = (x._display_values) ? (x.number.toLowerCase() + " " + x._display_values.u_sport + " " + x._display_values.u_test_type
          + " " + x._display_values.u_venue + " " + x._display_values.u_venue_address + " " + x._display_values.u_region + " " + x._display_values.u_soc )
        : ''
      });
      if (search.value && search.value.length > 0) {  
        data = data.filter(x => {
          return x.searchMeta.toLowerCase().includes(search.value.toLowerCase())
        });
      }
      return data;
    });

    const missionTotals = computed(() => {
      return {
        current: fetchedMissions.value.filter(mission => mission.state !== sn_mission.states.completed.toString()).length,
        completed: fetchedMissions.value.filter(mission => mission.state === sn_mission.states.completed.toString()).length,
        offered: offeredMissionMapped.value.filter(mission => mission.offer.u_state === 'offered' && mission.missionData).length,
        accepted: offeredMissionMapped.value.filter(mission => mission.offer.u_state === 'accepted' && mission.missionData).length,
        tentativelyAccepted: offeredMissionMapped.value.filter(mission => mission.offer.u_state === 'tentatively_accepted' && mission.missionData).length
      };
    });

    const formatTestingWindow = (mission) => {
      const start = formatDateLocal(mission.u_scheduled_start_date)
      const end = formatDateLocal(mission.u_scheduled_end_date)
      return `${start} to ${end}`
    }

    const openMission = (mission) => {
      const url = { name: 'mission-detail', params: { missionId: mission.id } }
      router.push(url)
    }

    const openOfferDialog = (offer, type) => {
      offerDialogProps.value = {
        offer: offer,
        type: type,
        title: '',
        icon: ''
      }
      switch (type) {
        case 'tentative':
          offerDialogProps.value.title = 'Tentatively Accept Offer'
          offerDialogProps.value.icon = 'mdi-checkbox-blank-badge-outline'
          break

        case 'accept':
          offerDialogProps.value.title = 'Accept Offer'
          offerDialogProps.value.icon = 'mdi-checkbox-outline'
          break

        case 'decline':
          offerDialogProps.value.title = 'Decline Offer'
          offerDialogProps.value.icon = 'mdi-close-box-outline'
          break

        default:
          break
      }

      showOfferDialog.value = true
    }

    async function saveOffer(props) {
      switch (props.type) {
        case'tentative':
          props.offer.u_state = 'tentatively_accepted'
          break

        case 'accept':
          props.offer.u_state = 'accepted'
          break

        case 'decline':
          props.offer.u_state = 'declined'
          break

        default:
          break
      }
      let data = toRaw(props.offer)
      try {
        await sn_customerservice_offered.save(data)
        await reloadOffered()
      } catch (error) {
        console.error("Error saving offer:", error)
      } finally {
        showOfferDialog.value = false
      }

    }

    return {
      offeredMissionsDataFiltered,
      assignedMissionsDataFiltered,
      formatTestingWindow,
      tab: null,
      loading: false,
      panel,
      panel2,
      openMission,
      userStore,
      missionTotals,
      showOfferDialog,
      openOfferDialog,
      saveOffer,
      offerDialogProps,
      search,
    }
  },

};
</script>

<style></style>
