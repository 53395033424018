import BaseModule from './base_module'
import snDiscipline from '../../service-now/tables/sn_customerservice_discipline'
import api from '../../service-now/api'
import snTestMission from '../../service-now/tables/sn_customerservice_test_mission'
import snTestSession from '../../service-now/tables/sn_customerservice_test_session'

class Discipline extends BaseModule {
    constructor() {
        super(snDiscipline.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: snTestMission.table,
                field: 'u_discipline'
            },
            {
                table: snTestSession.table,
                field: 'u_discipline'
            }
        ]
    }

    async getBySport(sportId) {
        let all = await this.table().where('u_sport').equals(sportId).sortBy('u_name')
        // filter out some bad data with no names
        return all.filter((x) => x.u_name)
    }

    async fetch() {
        var query = '' // Load all items
        var { data } = await api.getTableData(snDiscipline.table, query, true)
        return data.result
    }
}

const module = new Discipline()
export default module
