<template>
   <form-side-menu :missionId="missionId"></form-side-menu>
   <v-container fluid class="si-form-container">
      <main-header></main-header>
      <v-card v-if="mission" variant="flat" class="si-form-info-bar" rounded="0">
         <v-row dense>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Mission Order</span>
                  <span class="si-info-value">{{ mission.number }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Testing Window</span>
                  <span class="si-info-value">{{ formatTestingWindow(mission) }}</span>
               </div>
            </v-col>
            <v-col>
               <div class="d-flex flex-column">
                  <span class="si-info-heading">Event Name</span>
                  <span class="si-info-value">{{ mission._display_values.u_event_name }}</span>
               </div>
            </v-col>
         </v-row>
      </v-card>
      <RouterView v-if="mission" />
   </v-container>
</template>

<script>

import { ref, onMounted } from 'vue'
import { formatDateLocal } from '@/utils/utils.js';
import FormSideMenu from '@/components/FormSideMenu.vue';
import MainHeader from '@/components/MainHeader.vue';
import { useMissionStore } from '@/stores/mission'

export default {
   name: 'MissionInformation',
   components: {
      FormSideMenu,
      MainHeader,
   },
   props: {
      missionId: {
         type: String,
         required: true
      }
   },
   setup(props) {

      const mission = ref(null);
      const missionInfo = ref(null);
      const missionStore = useMissionStore()

      onMounted(async () => {

         await missionStore.loadMission(props.missionId);

         mission.value = missionStore.mission;
         missionInfo.value = mission.value._display_values;

      })

      const formatTestingWindow = (mission) => {
         const start = formatDateLocal(mission.u_scheduled_start_date);
         const end = formatDateLocal(mission.u_scheduled_end_date);
         return `${start} to ${end}`;
      }

      return {
         tab: null,
         loading: false,
         mission,
         missionInfo,
         formatTestingWindow
      }
   }
}
</script>

<style></style>
