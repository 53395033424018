<template>
    <v-row>
        <v-col cols="12" md="12">
            <div class="d-flex justify-space-between align-center ">
                <h3>Documents</h3>
                <v-menu class="si-dropdown-menu">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" class="si-right-add-button" @click="showAddDocument = true">
                            <v-icon icon="mdi-plus"></v-icon>
                        </v-btn>
                    </template>
                </v-menu>
            </div>
            <v-row>
                <v-col cols="12" md="12">
                    <v-data-table :headers="tableHeaders" :items="documents" height="auto" item-key="id" item-value="id">
                        <template v-slot:[`item.document.name`]="{ item }">
                          <a @click="openDocument(item)" style="display: flex;align-items: center;">
                            <v-icon icon="mdi-file" style="margin-right: 10px;"></v-icon> {{ item.document.name }}
                          </a>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-col>
        <NativeCamera ref="nativeCameraInput" @photo="addPhotoToMission"></NativeCamera>

        <v-dialog v-model="showAddDocument" transition="dialog-bottom-transition" fullscreen>
            <v-card>
                <v-toolbar color="#f97316" dark>
                    <v-btn icon="mdi-close" @click="showAddDocument = false"></v-btn>

                    <v-toolbar-title>New Document</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-items>
                        <v-btn text="Save" variant="text" @click="save"></v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-sheet class="si-form-sheet si-fullscreen-modal text-body-2 mx-auto">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" md="6" class="si-form">
                                <span class="font-italic si-top-label">Name</span>
                                <v-text-field v-model="document.name" label="" density="compact" bg-color="white"
                                    :error-messages="documentValidator.name.$errors.map((e) => e.$message)"
                                    type="text"></v-text-field>
                            </v-col>
                        </v-row>    
                        <v-row>
                            <v-col cols="12" md="6" class="si-form">
                                <span class="font-italic si-top-label">Description</span>
                                <v-textarea v-model="document.description" label="" density="compact" bg-color="white"
                                    :error-messages="documentValidator.description.$errors.map((e) => e.$message)"
                                    type="text"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="text-xs-center">

                            <v-btn @click="openCamera" class="mr-12"><v-icon icon=" mdi-camera" style="margin-right: 10px;"></v-icon> Open
                                Camera</v-btn>
                            <v-btn @click="selectPhoto"><v-icon icon="mdi-image" style="margin-right: 10px;"></v-icon>Select Photo</v-btn>

                            <div class="v-messages" role="alert" aria-live="polite" id="input-33-messages">
                                <div class="v-messages__message"> {{ documentValidator.content.$errors.map((e) =>
                                    e.$message).join(', ') }}</div>
                            </div>
                        </v-row>
                        <v-row class="mt-12">
                            <document-viewer :content="document.content"
                                :content-type="document.contentType"></document-viewer>
                        </v-row>
                    </v-container>
                </v-sheet>
                <v-snackbar v-model="validationError">
                    Please fill in all required fields.

                    <template v-slot:actions>
                        <v-btn color="red" variant="text" @click="validationError = false"> Close </v-btn>
                    </template>
                </v-snackbar>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue'
import attachment from '@/dal/modules/attachment'
import ds_document from '@/dal/modules/ds_document'
import ds_document_version from '@/dal/modules/ds_document_version'
import NativeCamera from '@/components/NativeCamera.vue'
import ds_document_references from '@/dal/modules/ds_document_references'
import sn_customerservice_test_mission from '@/service-now/tables/sn_customerservice_test_mission'
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import dal from '@/dal/index.js'
import DocumentViewer from '@/components/DocumentViewer.vue'
const nativeCameraInput = ref(null);

const tableHeaders = [
    { title: 'Name', value: 'document.name' },
    { title: 'Description', align: 'start', value: 'document.description' },
    { title: 'Default Version', align: 'start', value: 'default_version.version' },
    { title: 'Type', align: 'start', value: 'document.type' },
    { title: 'Classification', align: 'start', value: 'document.classification' },
    { title: 'State', align: 'start', value: 'document.state' },
]

import { useMissionStore } from '@/stores/mission'
import router from '@/router';
const missionStore = useMissionStore()
const mission = computed(() => missionStore.mission)
const documents = ref([])
const showAddDocument = ref(false)
const validationError = ref(false);
const document = ref({
    name: '',
    description: '',
    content: null,
    contentType: null,
})
const openCamera = () => {
    showAddDocument.value
    nativeCameraInput.value.openCamera()
}

const selectPhoto = () => {
    nativeCameraInput.value.selectPhoto()
}

const documentValidator = useVuelidate(
    {
        name: { required },
        description: { required },
        content: { required },
    },
    document
);

const save = async () => {
    documentValidator.value.$validate()

    if (documentValidator.value.$invalid) {
        validationError.value = true
        return;
    }

    // create document record
    const d = await ds_document.createNew()
    d.name = document.value.name
    d.description = document.value.description
    await ds_document.save(d)

    // create version record
    const documentVersion = await ds_document_version.createNew()
    documentVersion.document = d.id
    await ds_document_version.save(documentVersion)

    // create reference record
    const documentReference = await ds_document_references.createNew()
    documentReference.table_name = sn_customerservice_test_mission.table
    documentReference.target_record = mission.value.id
    documentReference.document = d.id
    await ds_document_references.save(documentReference)

    // create attachment
    const a = await attachment.createNew()
    a.table_sys_id = documentVersion.id
    a.table_name = 'ds_document_version'
    a.file_name = document.value.content.name
    a._blob = document.value.content
    await attachment.save(a)

    // attachment will be uploaded on sync
    await dal.commit()

    showAddDocument.value = false

    document.value = {
        name: '',
        description: '',
        photo: null
    }

    await loadDocuments()
}

const addPhotoToMission = async (photo) => {
    document.value.contentType = photo.type
    document.value.content = photo
}

async function loadDocuments() {
    const references = await ds_document_references.select('target_record', mission.value.id)
    const documentIds = references.map(x => x.document)

    const missionDocs = await ds_document.getByIds(documentIds)

    const versions = await ds_document_version.getByIds(missionDocs.map(x => x.default_version))

    documents.value = missionDocs.map(document => {
        return {
            document,
            default_version: versions.find(v => v.id === document.default_version)
        }
    })
}

function openDocument(item) {
    const document = item.document
    router.push({ name: 'document-view', params: { missionId: mission.value.id, documentId: document.id } })
}

onMounted(async () => {
    await loadDocuments()
})

</script>