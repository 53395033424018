<template>
    <div>
        <h1>TEST PAGE</h1>
        <ul>
            <li v-for="mission in data" v-bind:key="mission.id">
                {{ mission.number }} - {{ mission.work_notes }} - {{ mission.state }}

                <input v-model="mission.state" />
            </li>
        </ul>
        <button @click="save">Save</button>
        <document-viewer :document-id="documentId"></document-viewer>
    </div>
</template>

<script>
import { ref, onMounted, toRaw } from 'vue'
import DocumentViewer from '@/components/DocumentViewer.vue';
import dal from '@/dal/index.js'
export default {
    name: 'TestView',
    components: {
        DocumentViewer
    },
    setup() {
        // reactive array
        const data = ref([])
        const documentId = ref(null)

        // Fetch missions asynchronously when the component is mounted
        onMounted(async () => {




            const modification = {}
            modification.data = {}
            modification.data['document'] = 'BOOM'

            await dal.changeLogRepository.db.changeLog
                .where('relationships')
                .equals(`ds_document:document:802b9c25-d1a8-41f6-8e68-2292c076e5a3`).modify(modification)

            // TODO: this should be opened at the start of the application
            let q = await dal.changeLogRepository.db.changeLog
                .where({ table: 'ds_document_version', key: '47d8e4b9-fc12-4acb-b587-0451ef364395' })
                .toArray()


            alert(JSON.stringify(q))

        })

        async function save() {
            // save all the missions to the database
            data.value[0].description = 'Notes 444' + new Date().getTime()

            let first = toRaw(data.value[0]);
            await dal.modules.sn_customerservice_test_mission.save(first)
            await dal.commit()
        }

        return {
            data,
            save,
            documentId
        }
    }
}
</script>

<style></style>
