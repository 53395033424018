<template>
    <v-row>
      <v-col cols="12" style="padding:0 !important">
        <v-main>
          <div style="min-height: 100vh;">

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2><span v-if="isMissionType">Mission</span><span v-else>Athlete</span> Supplementary Report</h2>
                            <DividerWithIcons />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Sample Collection Personnel
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-combobox v-model="selectedPersonnel" label="" density="compact" :items="personnelOptions"
                            @update:model-value="updateAuthor" item-title="name" item-value="id" bg-color="white" ></v-combobox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Author Name
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-text-field v-model="supplementaryReport.u_author" label="" density="compact" required
                            :error-messages="v$.u_author.$errors.map(e => e.$message)" @blur="v$.u_author.$touch"
                            bg-color="white" type="text"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Author Role
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-text-field v-model="supplementaryReport.u_author_role" label="" density="compact"
                            bg-color="white" type="text"></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Author Comments
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-textarea v-model="supplementaryReport.u_comments" label="Comments" density="compact" bg-color="white">
                            </v-textarea>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Author Email
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-text-field v-model="supplementaryReport.u_author_email" label="" 
                            density="compact" bg-color="white" required type="text"
                            :error-messages="v$.u_author_email.$errors.map(e => e.$message)" @blur="v$.u_author_email.$touch">
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Email a Copy
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-checkbox v-model="supplementaryReport.u_email_a_copy" 
                                true-value="true" false-value="false" density="compact" bg-color="white" label="">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="6" md="6" class="si-form">
                            Reason for the report
                        </v-col>
                        <v-col cols="6" md="6" class="si-form">
                            <v-combobox v-model="selectedReason" label="" density="compact" :items="reasonOptions"
                            :error-messages="w$.selectedReason.$errors.map(e => e.$message)"
                            @blur="w$.selectedReason.$touch" required
                            @update:model-value="updateReason" item-title="label" item-value="id" bg-color="white"></v-combobox>
                        </v-col>
                    </v-row>

                </v-container>
            </v-sheet>  
            

            <v-sheet class="si-form-sheet text-body-2 mx-auto">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h2>Signatures</h2>
                            <DividerWithIcons is-compact="true" />
                        </v-col>
                    </v-row>
                    
                    <v-row style="align-items: center;">
                        <v-col cols="6" md="6" class="si-form-signature">
                            <span class="font-italic si-top-label">Author Signature </span>
                            <div class="si-signature-field">
                                <vue-signature-pad
                                    ref='authorSignature'
                                    :max-width='options.maxWidth'
                                    :min-width='options.minWidth'
                                    height="300px"
                                    :options='{
                                    penColor: options.penColor,
                                    backgroundColor: options.backgroundColor,
                                    }'
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-btn @click='clearAuthorSignature' class="si-right-action-button" size="large">
                                <v-icon icon="mdi-undo"></v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-sheet>

            
            <v-container class="pa-0" fluid>
                <v-row class="d-flex justify-end">
                    <v-col cols="3" md="3" class="d-flex">
                        <v-btn @click="goBack" class="si-form-button-2 flex-grow-1" height="48"
                            variant="tonal">
                            Back
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex">
                        <v-btn :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                            variant="tonal">
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>


          </div>
        </v-main>
    </v-col>
</v-row>
  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, computed, toRaw } from 'vue'
import { useRouter, useRoute } from 'vue-router';
import { VueSignaturePad } from '@selemondev/vue3-signature-pad'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_supp_report from '@/dal/modules/sn_customerservice_supp_report'
import sys_choice from '@/dal/modules/sys_choice'
import { isTrue, goBack } from '@/utils/utils.js'
import { useMissionStore } from '@/stores/mission'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import dal from '@/dal/index.js'
import sys_user from '@/dal/modules/sys_user';

export default {
    name: 'SupplementaryReportEdit',
    components: {
        VueSignaturePad,
        DividerWithIcons
    },
    props: {
        id: {
            type: String,
            required: true
        },
        missionId: {
            type: String,
            required: false
        },
        athleteId: {
            type: String,
            required: false
        },
        sessionId: {
            type: String,
            required: true
        },
        isMissionType: {
            type: Boolean,
            required: true,
        }
    },
    setup(props, { emit }) {
        const mission = ref()
        const athlete = ref({})
        const supplementaryReport = ref({})
        const isLoaded = ref(false)
        const authorSignature = ref(null)
        const selectedPersonnel = ref([])
        const selectedReason = ref()
        const reasonOptions = ref([])
        const missionStore = useMissionStore()
        const personnelOptions = ref([])
        const validationError = ref(false)
        const router = useRouter()
        const route = useRoute()

        const options = ref({
            penColor: 'rgb(0,0,0)',
            backgroundColor: 'rgb(255, 255, 255)',
            maxWidth: 2,
            minWidth: 2,
        })

        onMounted(async () => {
            try {

                reasonOptions.value = await sys_choice.getSupplementaryReportReasonOptions()
                await missionStore.loadMission(props.missionId)
                const scpIdList = missionStore.scpList.map(x => x.id)
                if (scpIdList.length > 0) {
                    personnelOptions.value= await sys_user.getByIds(scpIdList)
                }

                if (props.id && props.id !== 'new') {
                    supplementaryReport.value = await sn_customerservice_supp_report.get(props.id)

                    if (supplementaryReport.value.u_reason_for_the_report != '') {
                        selectedReason.value = reasonOptions.value.find(x => x.label === supplementaryReport.value.u_reason_for_the_report)
                    }

                } else {
                    supplementaryReport.value = await sn_customerservice_supp_report.createNew()
                    supplementaryReport.value.u_number = props.sessionId
                }

            } catch (error) {
                console.error("Error:", error)
            } finally {
                isLoaded.value = true
            }

        })

        const updateAuthor = (value) => {
            supplementaryReport.value.u_author = value.name
            supplementaryReport.value.u_author_email = value.email ? value.email : ''
            supplementaryReport.value.u_author_role = value.u_user_type ? value.u_user_type : ''
        }

        const updateReason = (value) => {
            selectedReason.value = value
            supplementaryReport.value.u_reason_for_the_report = value.label
        }

        const close = () => {
            emit('close')
        }

        const clearAuthorSignature = () => {
            if (authorSignature.value) {
                authorSignature.value.clearCanvas()
            }
        }
        
        const supplementaryReportRules = computed(() => {
            const localRules = {
                u_author: { required },
                u_comments_section: {},
                u_author_email: { required, email },
            }
            return localRules
        })

        const variableRules = computed(() => {
            const localRules = {
                selectedReason: { required },
            }
            return localRules
        })

        const v$ = useVuelidate(supplementaryReportRules, { supplementaryReport })
        const w$ = useVuelidate(variableRules, { selectedReason })

        async function save() {
            v$.value.$validate()
            w$.value.$validate()
            if (v$.value.$invalid || w$.value.$invalid) {
                validationError.value = true
                return
            }
            let data = toRaw(supplementaryReport.value)
            await sn_customerservice_supp_report.save(data)
            await dal.commit()
        }

        const handleGoBack = () => {
            goBack(router, route)
        };

        return {
            switchColor: '#2e4d23',
            mission,
            athlete,
            tab: null,
            isLoaded,
            supplementaryReport,
            close,
            save,
            authorSignature,
            clearAuthorSignature,
            options,
            selectedPersonnel,
            personnelOptions,
            selectedReason,
            reasonOptions,
            isTrue,
            goBack: handleGoBack,
            updateAuthor,
            updateReason,
            validationError,
            v$,
            w$
            
        }
    },

}
</script>

<style></style>
