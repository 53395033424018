<template>


  <v-row>
    <v-col cols="12">
      <v-main>
        <div style="min-height: 100vh;">
          <v-sheet class="si-form-sheet si-questionnaire text-body-2 mx-auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <h2>Blood Questionnaire</h2>
                  <DividerWithIcons is-compact="true" />
                </v-col>
              </v-row>
              <div v-if="isHgh || isAbp">
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete been seated for <strong>ten minutes</strong> prior to blood collection?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_been_seated_for_ten_minutes_prior_to_blood_collection"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_been_seated_for_ten_minutes_prior_to_blood_collection) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="!isTrue(dcf.u_has_the_athlete_been_seated_for_ten_minutes_prior_to_blood_collection)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If no)
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Was the sample <strong>collected</strong> immediately following <strong>at least three consecutive
                      days</strong> of competition (hemodilution expected)?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_sample_collected_in_three_consecutive_days_of_competition"
                      :true-value="'Yes'" :false-value="'No'" :color="switchColor"
                      :label="isTrue(dcf.u_sample_collected_in_three_consecutive_days_of_competition) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_sample_collected_in_three_consecutive_days_of_competition)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes)
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_details" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete had a <strong>training session</strong> or <strong>competition</strong> in the last
                    <strong>two hours</strong>?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_had_a_training_session_or_competition_in_the_last_two_hours"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_had_a_training_session_or_competition_in_the_last_two_hours) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_had_a_training_session_or_competition_in_the_last_two_hours)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes) <strong><em>Type of training session</em></strong> and <strong>DCO to describe why the
                      sample was taken</strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_type_of_session_time_of_session_and_reason_the_sample_was_collected" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="isAbp">
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete trained, competed or resided at an <strong>altitude greater than 1500
                      meters</strong> within the previous two weeks?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes) <strong>Name and Location</strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_name_and_location" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>Altitude (m)</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_altitude_m" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>From</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="altitudeFrom" label="" density="compact" bg-color="white" type="date"
                      :error-messages="w$.altitudeFrom.$errors.map(e => e.$message)" @blur="w$.altitudeFrom.$touch"
                      @update:model-value="updateFromDate">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_trained_competed_or_resided_at_an_altitude_greater_than_1500_m)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>To</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="altitudeTo" label="" density="compact" bg-color="white" type="date"
                      :error-messages="w$.altitudeTo.$errors.map(e => e.$message)" @blur="w$.altitudeTo.$touch"
                      @update:model-value="updateToDate">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete used any form of <strong>altitude simulation</strong>, such as a hypoxic tent, mask
                    etc, during the <strong>previous two weeks</strong>?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_used_any_form_of_altitude_simulation_such_as_a_hypoxic_tent_ma"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_used_any_form_of_altitude_simulation_such_as_a_hypoxic_tent_ma) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_used_any_form_of_altitude_simulation_such_as_a_hypoxic_tent_ma)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes) <strong>Type of Device</strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_type_of_device" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_used_any_form_of_altitude_simulation_such_as_a_hypoxic_tent_ma)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    Manner of use (frequency, duration, intensity etc)
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_manner_of_use_frequency_duration_intensity_etc" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete <strong>donated blood</strong> or <strong>lost blood</strong> as a result of medical
                    or emergency condition during the <strong>previous three months</strong>?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_donated_blood_or_lost_blood_as_a_result_of_medical_or_emergenc"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_donated_blood_or_lost_blood_as_a_result_of_medical_or_emergenc) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_donated_blood_or_lost_blood_as_a_result_of_medical_or_emergenc)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes) <strong><em>When?</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_when" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_donated_blood_or_lost_blood_as_a_result_of_medical_or_emergenc)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>Cause of the blood loss</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_cause_of_the_blood_loss" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_donated_blood_or_lost_blood_as_a_result_of_medical_or_emergenc)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>Estimated volume of blood (ml)</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_estimate_volume_of_blood_ml" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete <strong>given or received any blood</strong> transfusion(s) during the
                    <strong>previous three months</strong>?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_given_or_received_any_blood_transfusion_s_during_the_previous_"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_given_or_received_any_blood_transfusion_s_during_the_previous_) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_given_or_received_any_blood_transfusion_s_during_the_previous_)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    (If yes) <strong><em>When?</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_when_details" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_given_or_received_any_blood_transfusion_s_during_the_previous_)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    <strong><em>Estimated volume of blood (ml)</em></strong>
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_estimated_volume_of_blood_ml" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="6" md="6" class="si-form">
                    Has the athlete been exposed to <strong>any extreme environmental conditions in the 2 hours</strong>
                    prior to blood sample collection?
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-switch v-model="dcf.u_has_the_athlete_been_exposed_to_any_extreme_environmental_conditions_in_the_2_"
                      true-value="1" false-value="2" :color="switchColor"
                      :label="isTrue(dcf.u_has_the_athlete_been_exposed_to_any_extreme_environmental_conditions_in_the_2_) ? 'Yes' : 'No'" hide-details
                      inset></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="isTrue(dcf.u_has_the_athlete_been_exposed_to_any_extreme_environmental_conditions_in_the_2_)" class="supplementary-question">
                  <v-col cols="6" md="6" class="si-form">
                    Details of exposure
                  </v-col>
                  <v-col cols="6" md="6" class="si-form">
                    <v-text-field v-model="dcf.u_details_of_exposure" style="padding-top: 20px;" label="" density="compact" bg-color="white" type="text">
                    </v-text-field>
                  </v-col>
                </v-row>
                <hr>

              </div>
              <div class="si-form-gap"></div>

            </v-container>
          </v-sheet>

          <v-sheet class="si-form-sheet text-body-2 mx-auto">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12">
                  <h2>Comments</h2>
                  <DividerWithIcons is-compact="true" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="si-form-inline">
                  <v-textarea v-model="dcf.u_questionnaire_comments" label="Questionnaire comments" density="compact" bg-color="white">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>

          <v-container class="pa-0" fluid>
            <v-row class="d-flex justify-end">
              <v-col cols="12" md="3" class="d-flex">
                <v-btn @click="save" :loading="loading" class="si-form-button-1 flex-grow-1" height="48"
                  variant="tonal">
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-main>
    </v-col>
  </v-row>

  <v-snackbar v-model="validationError">
    Please fill in all required fields.

    <template v-slot:actions>
      <v-btn color="red" variant="text" @click="validationError = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted, toRaw, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useDcfStore } from '@/stores/dcf'
import { required } from '@vuelidate/validators'
import { isTrue, datePickerInitialise, datePickerUpdate } from '@/utils/utils.js'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue'
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf'

export default {
  name: 'AthleteInformation',
  components: {
    DividerWithIcons
  },
  props: {
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dcf = ref({})
    const isHgh = ref(false)
    const isAbp = ref(false)
    const validationError = ref(false)
    const hasSeated = ref(false)
    const collectedImmediately = ref(false)
    const activeLastTwoHours = ref(false)
    const highAltitude = ref(false)
    const altitudeSimulation = ref(false)
    const bloodLoss = ref(false)
    const bloodTransfusion = ref(false)
    const extremeEnvironment = ref(false)
    const altitudeFrom = ref(null)
    const altitudeTo = ref(null)
    const dcfStore = useDcfStore()

    onMounted(async () => {
      dcf.value = await sn_customerservice_dcf.get(props.dcfId)

      if (dcf.value.u_from != '') {
        altitudeFrom.value = datePickerInitialise(dcf.value.u_from)
      }
      if (dcf.value.u_to != ''){
        altitudeTo.value = datePickerInitialise(dcf.value.u_to)
      }

      await dcfStore.loadDcf(props.dcfId)
      isAbp.value = dcfStore.hasBloodPassport  
      isHgh.value = dcfStore.hasHgh  

    })

    const updateToDate = (value) => {
      dcf.value.u_to = datePickerUpdate(value)
    }

    const updateFromDate = (value) => {
      dcf.value.u_from = datePickerUpdate(value)
    }

    const dcfRules = computed(() => {
      const localRules = {
        u_type_of_session_time_of_session_and_reason_the_sample_was_collected: {},
        u_name_and_location: {},
      }
      if (highAltitude.value) {
        localRules.u_name_and_location = { required }
        localRules.u_altitude_m = { required }
      }
      return localRules
    })

    const variableRules = computed(() => {
      const localRules = {
        altitudeFrom: { },
        altitudeTo: { }
      }
      if (highAltitude.value) {
        localRules.altitudeFrom = { required }
        localRules.altitudeTo = { required }
      }
      return localRules
    })

    const v$ = useVuelidate(dcfRules, { dcf })
    const w$ = useVuelidate(variableRules, { altitudeFrom, altitudeTo })

    async function save() {
      v$.value.$validate()
      w$.value.$validate()
      if (v$.value.$invalid || w$.value.$invalid) {
        validationError.value = true
        return
      }

      let data = toRaw(dcf.value)
      await sn_customerservice_dcf.save(data)

      await dal.commit()

    }

    return {
      switchColor: '#2e4d23',
      tab: null,
      loading: false,
      isHgh,
      isAbp,
      hasSeated,
      collectedImmediately,
      activeLastTwoHours,
      highAltitude,
      altitudeSimulation,
      bloodLoss,
      bloodTransfusion,
      extremeEnvironment,
      dcf,
      validationError,
      save,
      altitudeFrom,
      altitudeTo,
      updateFromDate,
      updateToDate,
      isTrue,
      v$,
      w$

    }
  }
}
</script>

<style></style>
