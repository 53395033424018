import { useDate } from 'vuetify'
import moment from 'moment-timezone'
import { useUserStore } from '@/stores/user.js'
import { helpers } from '@vuelidate/validators'

export function isTrue(value) {
    // in SysChoice table 1 appears to === true in ServiceNow 
    return value === true || value === "true" || value === 1 || value === "1" || value === 'Yes' || value === 'yes'
  }


export function playBeep() {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)()
    const oscillator = audioContext.createOscillator()
    const gainNode = audioContext.createGain()

    oscillator.type = 'sine' // You can change this to 'square', 'sawtooth', or 'triangle'
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime) // Frequency in Hz (440 Hz is the standard A note)

    oscillator.connect(gainNode)
    gainNode.connect(audioContext.destination)

    oscillator.start();
    gainNode.gain.setValueAtTime(1, audioContext.currentTime) // Volume
    gainNode.gain.exponentialRampToValueAtTime(0.0001, audioContext.currentTime + 0.5) // Fade out

    oscillator.stop(audioContext.currentTime + 0.5) // Stop after 0.5 seconds
  }

export function goBack(router, route) {  
  if (window.history.length > 1) {
    router.go(-1);
  } else {
    const parentRoute = route.matched[0].path;
    router.push(parentRoute);
  }
}

export function formatDate(dateString) {
    const date = useDate()
    return date.format(dateString, 'keyboardDate')
}

export function getUserTimeZone() {
  return moment.tz.guess(true) // Detect the user's time zone + ignore cache
}

export function formatDateLocal(date) {
  const userStore = useUserStore()
  let userTimeZone = userStore.userTimeZone
  if (!userTimeZone) {
    userTimeZone = getUserTimeZone()
  }
  const utcDate = moment.utc(date) // Service now format in UTC
  if (!utcDate.isValid()) {
    return '';
  }
  const localDate = utcDate.tz(userTimeZone).format('DD MMM YY')
  return localDate
}

export function formatDateTime(date) {
  const userStore = useUserStore()
  let userTimeZone = userStore.userTimeZone
  if (!userTimeZone) {
    userTimeZone = getUserTimeZone()
  }
  const utcDate = moment.utc(date) // Service now format in UTC
  if (!utcDate.isValid()) {
    return '';
  }
  const localDate = utcDate.tz(userTimeZone).format('DD MMM YY HH:mm')
  return localDate
}

export function dateTimePickerUpdate(type, value, record) {
  const userStore = useUserStore()
  let userTimeZone = userStore.userTimeZone
  if (!userTimeZone) {
    userTimeZone = getUserTimeZone()
  }

  if (type === 'date') {
    const newDate = moment.tz(value, 'YYYY-MM-DD', userTimeZone)
    let currentDate = moment.utc(record).tz(userTimeZone)
    if (!currentDate.isValid()) {
      currentDate = moment.utc().tz(userTimeZone)
    }
    const originalTime = currentDate.format('HH:mm:ss')
    const updatedDate = moment.tz(`${newDate.format('YYYY-MM-DD')} ${originalTime}`, 'YYYY-MM-DD HH:mm:ss', userTimeZone)
    record = updatedDate.clone().utc().format('YYYY-MM-DD HH:mm:ss')
    return record
  } else if (type === 'time') {
    const newTime = moment.tz(value, 'HH:mm', userTimeZone)
    let currentDate = moment.utc(record).tz(userTimeZone)
    if (!currentDate.isValid()) {
      currentDate = moment.utc().tz(userTimeZone)
    }
    const originalDate = currentDate.format('YYYY-MM-DD')
    const updatedTime = moment.tz(`${originalDate} ${newTime.format('HH:mm')}`, 'YYYY-MM-DD HH:mm', userTimeZone)
    record = updatedTime.clone().utc().format('YYYY-MM-DD HH:mm:ss')
  return record
  }
}

export function dateTimePickerInitialise(type, record) {
  if (record != '') {
    const userStore = useUserStore()
    let userTimeZone = userStore.userTimeZone
    if (!userTimeZone) {
      userTimeZone = getUserTimeZone()
    }
    const utcDateTime = moment.utc(record)
    const localDateTime = utcDateTime.tz(userTimeZone)
    if (type === 'time') {
      return localDateTime.format('HH:mm')
    } else if (type === 'date') {
      return localDateTime.format('YYYY-MM-DD')
    }
 } else {
    return null
 }
}

export function datePickerUpdate(value) {
  // Assuming for now the datePicker (without time) is just a date string & not in UTC
  const date = moment(value, 'YYYY-MM-DD', true);
  if (!date.isValid()) {
    return null;
  }
  return date.format('YYYY-MM-DD')  
}

export function datePickerInitialise(record) {
  // Assuming for now the datePicker (without time) is just a date string & not in UTC
  if (record != '') {
    return moment(record).format('YYYY-MM-DD')
 } else {
    return null
 }
}

export function mustBeValidDate() {
  return helpers.withMessage('Date must be valid', value => {
    return moment(value, 'YYYY-MM-DD', true).isValid()
  });
}

export function mustBeValidTime() {
  return helpers.withMessage('Time must be valid', value => {
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/
    return timePattern.test(value)
  });
}

export function tableCustomSort(a, b, sortKey, sampleHeaders) {
  if (!a || !b) {
    return 0
  }
  const header = sampleHeaders.find(header => header.value === sortKey)
  const type = header ? header.type : 'text'
  let valueA = a[sortKey]
  let valueB = b[sortKey]
  if (type === 'date') {
    valueA = valueA ? new Date(valueA) : new Date(0)
    valueB = valueB ? new Date(valueB) : new Date(0)
  } else if (type === 'text') {
    valueA = valueA ? valueA.toLowerCase() : ''
    valueB = valueB ? valueB.toLowerCase() : ''
  }

  if (valueA < valueB) return -1
  if (valueA > valueB) return 1
  return 0
}