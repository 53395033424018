import BaseModule from './base_module'
import cmdbModelCategory from '../../service-now/tables/cmdb_model_category'
import cmdbModel from '../../service-now/tables/cmdb_model'
import api from '../../service-now/api'

class ModelCategory extends BaseModule {
    constructor() {
        super(cmdbModelCategory.table)

        this.sync = true

        this.syncOrder = 0

        this.relationships = [
            {
                table: cmdbModel.table,
                field: 'cmdb_model_category'
            }
        ]
    }

    async fetch() {
        const query = ''
        const { data } = await api.getTableData(cmdbModelCategory.table, query, true)
        return data.result
    }
}

const module = new ModelCategory()
export default module
