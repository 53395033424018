import { defineStore } from 'pinia'
import serviceNow from '@/service-now/index.js'
import dal from '@/dal'
import moment from 'moment-timezone'

export const useUserStore = defineStore('user', {
    state: () => ({
        user_sys_id: '',
        username: '',
        displayName: '',
        tempPassCode: null, // Unlock screen passcode
        accessToken: null,
        refreshToken: null,
        showOfferedMissions: true,
        showAcceptedMissions: false,
        showTentativelyAcceptedMissions: false,
        showCurrentAssignedMissions: true,
        showCompletedAssignedMissions: false,
        userTimeZone: null, 
    }),
    actions: {
        async login(usernamem, password) {
            let result = await serviceNow.login(usernamem, password)

            this.setToken(result.access_token, result.refresh_token)

            // Reset passcode on login
            this.tempPassCode = null

            await this.getUserDetails()
        },

        async fetchToken() {
            let result = await serviceNow.refreshToken(this.refreshToken)

            this.setToken(result.access_token, result.refresh_token)
        },

        async logout() {
            this.accessToken = null
            this.refreshToken = null

            // only clear user if there are no change logs
            if ((await dal.changeLogRepository.count('changeLog')) === 0) {
                this.user_sys_id = null
                this.username = null
                this.displayName = null
                this.userTimeZone = null
            }

            window.location.replace('/auth/logout')
        },

        setToken(accessToken, refreshToken) {
            this.accessToken = accessToken
            this.refreshToken = refreshToken
        },

        setPassCode(passCode) {
            this.tempPassCode = passCode
        },

        async getUserDetails() {
            let userDetails = await serviceNow.getUserDetails()

            this.user_sys_id = userDetails.user_sys_id
            this.username = userDetails.user_name
            this.displayName = userDetails.user_display_name
            this.userTimeZone = moment.tz.guess(true)
        },

        async checkLogin() {
            if (!this.accessToken) {
                return false
            }

            try {
                await this.getUserDetails()
            } catch (error) {
                console.error(error)
                return false
            }
            return true
        },

        async checkCookieTokenIsValid() {
            try {
                await this.getUserDetails()
                this.accessToken = 'cookie-token'
                this.refreshToken = 'cookie-token'
                return true
            } catch (error) {
                console.error(error)
                return false
            }
        }
    },
    persist: true
})
