import BaseModule from './base_module'
import dsDocumentVersion from '../../service-now/tables/ds_document_version'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'
import attachment from '@/service-now/tables/attachment'

class DSDocumentVersion extends BaseModule {
    constructor() {
        super(dsDocumentVersion.table)

        this.sync = true

        this.syncOrder = 12 // Make sure this is after documents

        this.relationships = [
            {
                table: attachment.table,
                field: 'table_sys_id'
            }
        ]
    }

    async fetch() {
        const documentIds = await dal.modules.ds_document.getSysIds()

        // TODO: filter on state?
        // version_state: "published"

        return await pagination.paginateData('documentIN', documentIds, (pageQuery) =>
            api.getTableData(dsDocumentVersion.table, `${pageQuery}`)
        )
    }
}

const module = new DSDocumentVersion()
export default module
