import autoBind from 'auto-bind-inheritance';

class BaseModule {

    constructor(name) {
        this.name = name
        this.repository = null

        this.sync = false
        this.syncClientDelete = false
        this.offlineOnly = false

        autoBind(this);
    }

    register(repository) {
        this.repository = repository
    }

    newModel() {
        return this.repository.clone(self._model || {})
    }

    createNew() {
        return Promise.resolve(this.newModel())
    }

    get(id, options) {
        return this.repository.get(this.name, id, this._compileOptions(options))
    }

    save(data) {
        if (data) return this.repository.save(this.name, data)
    }

    getAll(skip, take) {
        return this.repository.getAll(this.name, skip, take)
    }

    getAllByKey() {
        return this.repository.getAllByKey(this.name)
    }

    selectFirst(indexName, value) {
        return this.repository.selectFirst(this.name, indexName, value)
    }

    select(indexName, value) {
        return this.repository.select(this.name, indexName, value)
    }

    count() {
        return this.repository.count(this.name)
    }

    getByKey(indexName, values, options) {
        return this.repository.getByKey(this.name, values, indexName, this._compileOptions(options))
    }

    async delete(data) {
        let pk = await this.repository.getPrimaryKey(this.name)
        let keys = []

        if (Array.isArray(data)) keys = data.map(x => x[pk])
        else keys = data[pk] || data

        return this.repository.delete(this.name, keys)
    }

    putAll(data) {
        return this.repository.putAll(this.name, data)
    }

    put(data) {
        return this.repository.put(this.name, data)
    }

    // TODO: More shortcut methods for modules
    _compileOptions(options){
        return {
            offlineOnly: this.offlineOnly,
            ...(options || [])
        }
    }
}

export default BaseModule