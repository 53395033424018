function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function deprecated() {
    console.warn('dateFromFilter is deprecated. we are are using clear all cache.')
    return ``
}

function apply(dateFrom) {
    const formattedDate = formatDate(dateFrom)
    return `sys_updated_on>=${formattedDate}`
}

/**
 * Page the requests based on batching ids
 *
 * @param {*} queryPrefix
 * @param {*} ids
 * @param {*} fetch
 * @returns
 */
export default {
    apply: deprecated,
    forceApply: apply
}
