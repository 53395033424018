import lodash from 'lodash'

async function paginateData(queryPrefix, ids, fetch) {
    let result = []
    ids = [...new Set(ids)].filter((x) => x)
    const chunks = lodash.chunk(ids, 50) // a commonly accepted limit is 2,083 characters
    for (let chunk of chunks) {
        // check if the chunk is empty
        if (chunk.length === 0) {
            continue
        }

        const pageQuery = `${queryPrefix}${chunk.join(',')}`
        const { data } = await fetch(pageQuery)
        result = [...result, ...data.result]
    }

    return result
}

//localhost/api/service-now/api/now/table/sn_customerservice_dcf?sysparm_query=u_test_session_idINd2a990ea1be40e506bf264ab274bcb01%2Cd4b9d4ea1be40e506bf264ab274bcbab%2Cd9b918ea1be40e506bf264ab274bcb56%2Cdba994ea1be40e506bf264ab274bcbe4%2Ce1da561687a492105002a7190cbb3531%2Ceefb38321b284e506bf264ab274bcb7d%2Cefa9d4ea1be40e506bf264ab274bcb0e%2Cfaa994ea1be40e506bf264ab274bcb40
/**
 * Page the requests based on batching ids
 *
 * @param {*} queryPrefix
 * @param {*} ids
 * @param {*} fetch
 * @returns
 */
export default {
    paginateData
}
