function getObjectDiff(current, previous) {
    var diff = {}

    for (var property in current) {
        if (Object.prototype.hasOwnProperty.call(current, property)) {
            var currentPropertyValue = current[property]
            var previousPropertyValue = previous[property]

            // Skip underscore properties as private
            if (property[0] === '_') continue

            // if property is new add it to diff
            if (!previous || !Object.prototype.hasOwnProperty.call(previous, property)) {
                diff[property] = currentPropertyValue
                continue
            }

            // if property has another type or value (different object or literal)
            if (previousPropertyValue !== currentPropertyValue) {
                // Nulls
                if (currentPropertyValue == null) {
                    diff[property] = null
                }
                // Date
                else if (currentPropertyValue instanceof Date) {
                    // Handle Dates
                    diff[property] = currentPropertyValue.toISOString()

                    // Arrays
                } else if (Array.isArray(currentPropertyValue)) {
                    diff[property] = currentPropertyValue

                    // Objects
                } else if (typeof currentPropertyValue === 'object') {
                    // prop is an object, do recursion
                    var objectDiff = getObjectDiff(currentPropertyValue, previousPropertyValue)
                    // if child object is not empty append to current diff
                    if (!isEmpty(objectDiff)) {
                        diff[property] = objectDiff
                    }

                    // Primitives
                } else {
                    // Default handler - string, number, boolean, null
                    diff[property] = currentPropertyValue
                }
            }
        }
    }
    return diff
}

function isEmpty(obj) {
    for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false
    }
    return true
}

export { getObjectDiff, isEmpty }
