<template>

    <input ref="nativeCameraInput" type="file" accept="image/*" capture="environment" class="hidden" hidden
        @change="readFromInput(nativeCameraInput)" />

    <input ref="nativeFileSelectInput" type="file" accept="image/*" class="hidden" hidden
        @change="readFromInput(nativeFileSelectInput)">
</template>
<script setup>

import { ref, defineExpose, defineEmits } from 'vue';

const emit = defineEmits(['photo'])

const nativeCameraInput = ref(null);
const nativeFileSelectInput = ref(null);

async function openCamera() {
    if (nativeCameraInput.value.capture != undefined) {
        nativeCameraInput.value.click()
    } else {
        alert('Camera not supported on this device')
    }
}

async function selectPhoto() {
    nativeFileSelectInput.value.click()
}

async function readFromInput(el) {
    let files = el.files
    if (files != null) {
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            emit('photo', file)
        }
    }
    el.value = ''
}

defineExpose({
    openCamera,
    selectPhoto,
});
</script>