import BaseModule from './base_module'
import snDeclarationOfMedicationsOrSupplements from '../../service-now/tables/sn_customerservice_declaration_of_medications_supplements'
import api from '../../service-now/api'
import pagination from '../../service-now/utils/pagination'
import dal from '../dal'

class BloodSample extends BaseModule {
    constructor() {
        super(snDeclarationOfMedicationsOrSupplements.table)
        this.sync = true
        this.syncOrder = 12 // Loaded after DCF
    }

    async getByDcf(dcfId) {
        return await this.repository.db[this.name]
            .where('u_dcf_number')
            .equals(dcfId)
            .toArray()
    }

    async fetch() {
        const dcfNumbers = await dal.modules.sn_customerservice_dcf.getAll()
        return await pagination.paginateData(
            'u_dcf_numberIN',
            dcfNumbers.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(snDeclarationOfMedicationsOrSupplements.table, `${pageQuery}`)
        )
    }
}

const module = new BloodSample()
export default module