import BaseModule from './base_module'
import snPotentialFailureToComply from '../../service-now/tables/sn_customerservice_pftc'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'

class PotentialFailureToComply extends BaseModule {
    constructor() {
        super(snPotentialFailureToComply.table)
        this.sync = true
        this.syncOrder = 11 // After Test Session
    }

    async getByMissionId(missionId) {  
        try{
            const sessions = await dal.modules.sn_customerservice_test_session.getByMissionId(missionId)
            if (sessions && sessions.length > 0) {
                const sessionIds = sessions.map((x) => x.id)
                return await this.repository.db[this.name]
                    .where('u_number')
                    .anyOf(sessionIds)
                    .toArray()
            }
        } catch (error) {
            console.error('Error retrieving DCO reports:', error)
            return []
        }
    }
    
    async fetch() {
        const testSessions = await dal.modules.sn_customerservice_test_session.getAll()

        const data = await pagination.paginateData(
            'u_numberIN',
            testSessions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snPotentialFailureToComply.table,
                    `${pageQuery}`
                )
        )

        return data
    }
}

const module = new PotentialFailureToComply()
export default module