<template>
    <div>
        <document-viewer :document-id="documentId"></document-viewer>
    </div>
</template>

<script>
import DocumentViewer from '@/components/DocumentViewer.vue';
export default {
    name: 'DocumentWindow',
    components: {
        DocumentViewer
    },
    props: {
        documentId: {
            type: String,
            required: true
        },
    }
}
</script>

<style></style>
