// We might not need this module but not sure. The supplied wireframe only talks about mission instructions.
import BaseModule from './base_module'
import snSessionInstruction from '../../service-now/tables/sn_customerservice_m2m_session_instruction'
import api from '../../service-now/api'
import dal from '../dal'
import pagination from '../../service-now/utils/pagination'
class TestSessionInstruction extends BaseModule {
    constructor() {
        super(snSessionInstruction.table)
        this.sync = true
        this.syncOrder = 11 // After Test Session
    }

    async fetch() {
        // Only the special instructions relating to test missions that have been loaded into the IndexDB should be fetched.
        // This is done by retrieving all the ids of the loaded test missions.
        const testSessions = await dal.modules.sn_customerservice_test_session.getAll()

        return await pagination.paginateData(
            'u_test_sessionIN',
            testSessions.map((x) => x.sys_id),
            (pageQuery) =>
                api.getTableData(
                    snSessionInstruction.table,
                    `${pageQuery}`
                )
        )
    }
}

const module = new TestSessionInstruction()
export default module
