<template>
    <div>
        <div v-for="page in pages" :key="page">
            <VuePDF :pdf="pdf" :page="page" />
        </div>
    </div>
</template>
<script setup>
/**
 * Inline load a PDF file and display it in the browser
 * see // https://tato30.github.io/vue-pdf/
 * uses pdf.js to render the pdf
 */

import { ref, onMounted, watch } from 'vue'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

const props = defineProps({
    data: Object,
})

const pdfSource = ref(null)
const { pdf, pages } = usePDF(pdfSource)
let arrayBuffer = null;

onMounted(async () => {
    setValue(props.data)
})

async function setValue(data) {
    // convert the blob to a byte array
    if (data instanceof Blob) {
        arrayBuffer = await data.arrayBuffer();
        pdfSource.value = new Uint8Array(arrayBuffer);
        return;
    }

    // else try loading the data
    pdfSource.value = data
}


// Watch the data prop
watch(() => props.data, async (newData) => {
    setValue(newData)
})

</script>
