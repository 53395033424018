<template>
    <div :class="isCompact ? 'divider-with-icons-compact' : 'divider-with-icons'">
      <svg class="text-grn-primary" width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_95_3713)">
          <path d="M0.904297 0.325164L5.904 5.32487L0.904296 10.3246L0.904297 0.325164ZM0.904296 10.3252L5.904 15.3249L0.904296 20.3246L0.904296 10.3246L0.904296 10.3252ZM5.90459 5.32487L10.9043 10.3252L5.90459 15.3249L5.90459 5.32487Z" fill="currentColor"></path>
        </g>
        <defs>
          <clipPath id="clip0_95_3713">
            <rect width="20" height="10" fill="white" transform="translate(10.9043 0.325165) rotate(90)"></rect>
          </clipPath>
        </defs>
      </svg>
      <div class="grn-primary" style="flex-grow:1; height: 1px;"></div>
      <svg class="rotate-180 text-grn-primary" width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_95_3713)">
          <path d="M0.904297 0.325164L5.904 5.32487L0.904296 10.3246L0.904297 0.325164ZM0.904296 10.3252L5.904 15.3249L0.904296 20.3246L0.904296 10.3246L0.904296 10.3252ZM5.90459 5.32487L10.9043 10.3252L5.90459 15.3249L5.90459 5.32487Z" fill="currentColor"></path>
        </g>
        <defs>
          <clipPath id="clip0_95_3713">
            <rect width="20" height="10" fill="white" transform="translate(10.9043 0.325165) rotate(90)"></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DividerWithIcons',
    props: {
        isCompact: {
            type: Boolean,
            default: false,
            required: false
        },
    },
  };
  </script>
  
  <style scoped>
  .divider-with-icons {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .divider-with-icons-compact {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  </style>