<template>
    <v-row>
        <v-col cols="12" md="12" class="text-xs-center">
            <DocumentViewer :documentId="props.documentId"></DocumentViewer>
        </v-col>
    </v-row>
</template>
<script setup>
import DocumentViewer from '@/components/DocumentViewer.vue';

const props = defineProps({
    documentId: String,
})

</script>