<template>
  <v-row>
    <v-col cols="12">
        <v-main>
          <div style="min-height: 100vh;">

            <v-sheet v-if="hasUrineSamples" class="si-form-sheet text-body-2 mx-auto">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="d-flex justify-space-between align-center ">
                      <h3>Urine Samples</h3>
                      <v-menu class="si-dropdown-menu">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" class="si-right-add-button">
                            <v-icon icon="mdi-plus"></v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title @click="showUrineSampleDialog = 'true'">Add Full Urine Sample</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title @click="showPartialUrineSampleDialog = 'true'">Add Partial Urine Sample</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <DividerWithIcons />
                  </v-col>
                </v-row> 
                <v-row v-for="(urineSample, index) in urineSamplesData" :key="index">
                  <v-col cols="12" md="12" class="si-form" style="margin-bottom: 20px;">
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-item>
                        <div class="d-flex justify-space-between align-center ">
                          <div class="flex-grow-1 flex-shrink-0">
                            <v-row>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span v-if="isTrue(urineSample.sample.u_was_a_partial_sample_collected)">Partial Sample Kit # </span>
                                <span v-else>Sample Kit # </span>
                                <span style="font-weight: 600;"> {{ urineSample.kitNumber }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Volume</span>
                                <span v-if="isTrue(urineSample.sample.u_was_a_partial_sample_collected) && urineSample.sample.u_partial_sample_volume_mls != ''" style="font-weight: 600;">
                                   {{ urineSample.sample.u_partial_sample_volume_mls }} mls 
                                </span>
                                <span v-else-if="urineSample.u_volume_of_urine_collected_ml != ''" style="font-weight: 600;">
                                   {{ urineSample.sample.u_volume_of_urine_collected_ml }} mls 
                                </span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Manufacturer</span>
                                <span style="font-weight: 600;"> {{ urineSample.manufacturer }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sealed Time</span>
                                <span v-if="isTrue(urineSample.sample.u_was_a_partial_sample_collected) && urineSample.sample.u_partial_sample_sealed_time != ''" style="font-weight: 600;">
                                  {{ formatDateTime(urineSample.sample.u_partial_sample_sealed_time) }} 
                                </span>
                                <span v-else-if="urineSample.sample.u_urine_sealed_time != ''" style="font-weight: 600;">
                                  {{ formatDateTime(urineSample.sample.u_urine_sealed_time) }} 
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                          <v-btn v-if="isTrue(urineSample.sample.u_was_a_partial_sample_collected)" class="si-right-action-button" @click="openPartialUrineSample(urineSample)">
                            <v-icon icon="mdi-chevron-right"></v-icon>
                          </v-btn>
                          <v-btn v-else class="si-right-action-button" @click="openUrineSample(urineSample)">
                            <v-icon icon="mdi-chevron-right"></v-icon>
                          </v-btn>
                        </div>
                      </v-card-item>
                    </v-card>
                  </v-col>  
                </v-row>
                
                <v-dialog v-model="showUrineSampleDialog" transition="dialog-bottom-transition" fullscreen>
                  <urine-sample-edit id="new" testType="full" :dcfId="dcfId" :missionId="missionId" @close="showUrineSampleDialog = false">
                  </urine-sample-edit>
                </v-dialog>
                <v-dialog v-model="showPartialUrineSampleDialog" transition="dialog-bottom-transition" fullscreen>
                  <urine-sample-edit id="new" testType="partial" :dcfId="dcfId" :missionId="missionId" @close="showPartialUrineSampleDialog = false">
                  </urine-sample-edit>
                </v-dialog>
                
              </v-container>
            </v-sheet>
            
            <v-sheet v-if="hasBloodPassportSamples" class="si-form-sheet text-body-2 mx-auto">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="d-flex justify-space-between align-center ">
                      <h3>Blood Samples (Blood Passport)</h3>
                      <v-menu class="si-dropdown-menu">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" class="si-right-add-button">
                            <v-icon icon="mdi-plus"></v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title @click="showBloodPassportSampleDialog = 'true'">Add Blood Passport Sample</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <DividerWithIcons />
                  </v-col>
                </v-row> 
                <v-row v-for="(bloodPassport, index) in bloodPassportSamplesData" :key="index">
                  <v-col cols="12" md="12" class="si-form" style="margin-bottom: 20px;">
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-item>
                        <div class="d-flex justify-space-between align-center ">
                          <div class="flex-grow-1 flex-shrink-0">
                            <v-row>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sample Kit # </span>
                                <span style="font-weight: 600;"> {{ bloodPassport.kitNumber }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>No. Tubes</span>
                                <span v-if="bloodPassport.sample.u_of_tubes_blood_passport != ''" style="font-weight: 600;">
                                   {{ bloodPassport.sample.u_of_tubes_blood_passport }}
                                </span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Manufacturer</span>
                                <span style="font-weight: 600;"> {{ bloodPassport.manufacturer }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sealed Time</span>
                                <span v-if="bloodPassport.sample.u_sealed_date_time_blood_passport != ''" style="font-weight: 600;">
                                  {{ formatDateTime(bloodPassport.sample.u_sealed_date_time_blood_passport) }} 
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                          <v-btn class="si-right-action-button" @click="openBloodPassportSample(bloodPassport)">
                            <v-icon icon="mdi-chevron-right"></v-icon>
                          </v-btn>
                        </div>
                      </v-card-item>
                    </v-card>
                  </v-col>  
                </v-row>
                
                <v-dialog v-model="showBloodPassportSampleDialog" transition="dialog-bottom-transition" fullscreen>
                  <blood-sample-edit id="new" testType="BloodPassport" :dcfId="dcfId" :missionId="missionId" @close="showBloodPassportSampleDialog = false">
                  </blood-sample-edit>
                </v-dialog>
                
              </v-container>
            </v-sheet>

            
            <v-sheet v-if="hasBloodHghSamples" class="si-form-sheet text-body-2 mx-auto">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="d-flex justify-space-between align-center ">
                      <h3>Blood Samples (hGH)</h3>
                      <v-menu class="si-dropdown-menu">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" class="si-right-add-button">
                            <v-icon icon="mdi-plus"></v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title @click="showHghSampleDialog = 'true'">Add hGH Sample</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <DividerWithIcons />
                  </v-col>
                </v-row> 
                <v-row v-for="(hghSample, index) in bloodHghSamplesData" :key="index">
                  <v-col cols="12" md="12" class="si-form" style="margin-bottom: 20px;">
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-item>
                        <div class="d-flex justify-space-between align-center ">
                          <div class="flex-grow-1 flex-shrink-0">
                            <v-row>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sample Kit # </span>
                                <span style="font-weight: 600;"> {{ hghSample.kitNumber }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>No. Tubes</span>
                                <span v-if="hghSample.sample.u_blood_of_tubes != ''" style="font-weight: 600;">
                                   {{ hghSample.sample.u_blood_of_tubes }}
                                </span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Manufacturer</span>
                                <span style="font-weight: 600;"> {{ hghSample.manufacturer }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sealed Time</span>
                                <span v-if="hghSample.sample.u_blood_sealed_date_time != ''" style="font-weight: 600;">
                                  {{ formatDateTime(hghSample.sample.u_blood_sealed_date_time) }} 
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                          <v-btn class="si-right-action-button" @click="openHghSample(hghSample)">
                            <v-icon icon="mdi-chevron-right"></v-icon>
                          </v-btn>
                        </div>
                      </v-card-item>
                    </v-card>
                  </v-col>  
                </v-row>

                <v-dialog v-model="showHghSampleDialog" transition="dialog-bottom-transition" fullscreen>
                  <blood-sample-edit id="new" testType="hGH" :dcfId="dcfId" :missionId="missionId" @close="showHghSampleDialog = false">
                  </blood-sample-edit>
                </v-dialog>
                
              </v-container>
            </v-sheet>
            
            <v-sheet v-if="hasBloodDbsSamples" class="si-form-sheet text-body-2 mx-auto">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" md="12">
                    <div class="d-flex justify-space-between align-center ">
                      <h3>Blood Samples (Dried Blood Spot)</h3>
                      <v-menu class="si-dropdown-menu">
                        <template v-slot:activator="{ props }">
                          <v-btn v-bind="props" class="si-right-add-button">
                            <v-icon icon="mdi-plus"></v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-title @click="showDbsSampleDialog = 'true'">Add Dried Blood Spot Sample</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <DividerWithIcons />
                  </v-col>
                </v-row> 
                <v-row v-for="(dbsSample, index) in bloodDbsSamplesData" :key="index">
                  <v-col cols="12" md="12" class="si-form" style="margin-bottom: 20px;">
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-item>
                        <div class="d-flex justify-space-between align-center ">
                          <div class="flex-grow-1 flex-shrink-0">
                            <v-row>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sample Kit # </span>
                                <span style="font-weight: 600;"> {{ dbsSample.kitNumber }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Manufacturer</span>
                                <span style="font-weight: 600;"> {{ dbsSample.manufacturer }}</span>
                              </v-col>
                              <v-col cols="12" md="3" class="d-flex flex-column">
                                <span>Sealed Time</span>
                                <span v-if="dbsSample.sample.u_dbs_sealed_date_time != ''" style="font-weight: 600;">
                                  {{ formatDateTime(dbsSample.sample.u_dbs_sealed_date_time) }} 
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                          <v-btn class="si-right-action-button" @click="openDbsSample(dbsSample)">
                            <v-icon icon="mdi-chevron-right"></v-icon>
                          </v-btn>
                        </div>
                      </v-card-item>
                    </v-card>
                  </v-col>  
                </v-row>

                <v-dialog v-model="showDbsSampleDialog" transition="dialog-bottom-transition" fullscreen>
                  <blood-sample-edit id="new" testType="DriedBloodSpot" :dcfId="dcfId" :missionId="missionId" @close="showDbsSampleDialog = false">
                  </blood-sample-edit>
                </v-dialog>
                
              </v-container>
            </v-sheet>

          </div>
        </v-main>
    </v-col>
  </v-row>
  <v-dialog v-model="editUrineSampleDialog" transition="dialog-bottom-transition" fullscreen>
    <urine-sample-edit :id="editProps.id" isEdit="true" :testType="editProps.type" :dcfId="dcfId" :missionId="missionId" @close="editUrineSampleDialog = false">
    </urine-sample-edit>
  </v-dialog>
  <v-dialog v-model="editBloodSampleDialog" transition="dialog-bottom-transition" fullscreen>
    <blood-sample-edit :id="editProps.id" isEdit="true" :testType="editProps.type" :dcfId="dcfId" :missionId="missionId" @close="editBloodSampleDialog = false">
    </blood-sample-edit>
  </v-dialog>
</template>

<script>

import { ref, onMounted, toRaw } from 'vue'
import dal from '@/dal/index.js'
import DividerWithIcons from '@/components/DividerWithIcons.vue';
import sn_customerservice_dcf from '@/dal/modules/sn_customerservice_dcf';
import sn_customerservice_urine_samples from '@/dal/modules/sn_customerservice_urine_samples';
import sn_customerservice_blood_samples from '@/dal/modules/sn_customerservice_blood_samples';
import UrineSampleEdit from '@/views/Samples/UrineSampleEdit.vue'
import BloodSampleEdit from '@/views/Samples/BloodSampleEdit.vue'
import { isTrue } from '@/utils/utils.js'
import core_company from '@/dal/modules/core_company'
import alm_hardware from '@/dal/modules/alm_hardware';
import { formatDateTime } from '@/utils/utils.js';

export default {
  name: 'AthleteInformation',
  components: {
    DividerWithIcons,
    UrineSampleEdit,
    BloodSampleEdit
  },
  props: {
    dcfId: {
      type: String,
      required: true
    },
    missionId: {
        type: String,
        required: true
    }
  },
  setup(props) {
    const dcf = ref({})
    const urineSamples = ref([])
    const bloodPassportSamples = ref([])
    const bloodHghSamples = ref([])
    const bloodDbsSamples = ref([])
    const hasUrineSamples = ref(false)
    const hasBloodPassportSamples = ref(false)
    const hasBloodHghSamples = ref(false)
    const hasBloodDbsSamples = ref(false)
    const showUrineSampleDialog = ref(false)
    const showPartialUrineSampleDialog = ref(false)
    const showBloodPassportSampleDialog = ref(false)
    const showHghSampleDialog = ref(false)
    const showDbsSampleDialog = ref(false)
    const editBloodSampleDialog = ref(false)
    const editUrineSampleDialog = ref(false)
    const urineSamplesData = ref([])
    const kitHardwareList = ref([])
    const kitManufacturerList = ref([])
    const bloodPassportSamplesData = ref([])
    const bloodHghSamplesData = ref([])
    const bloodDbsSamplesData = ref([])
    const editProps = ref({ id: '', type: '' })

    const openUrineSample = (urineSample) => {
      editProps.value = { id: urineSample.sample.id, type: 'full' }
      editUrineSampleDialog.value = true
    }

    const openPartialUrineSample = (urineSample) => {
      editProps.value = { id: urineSample.sample.id, type: 'partial' }
      editUrineSampleDialog.value = true
    }

    const openBloodPassportSample = (bloodPassport) => {
      editProps.value = { id: bloodPassport.sample.id, type: 'BloodPassport' }
      editBloodSampleDialog.value = true
    }

    const openHghSample = (hghSample) => {
      editProps.value = { id: hghSample.sample.id, type: 'hGH' }
      editBloodSampleDialog.value = true
    }

    const openDbsSample = (dbsSample) => {
      editProps.value = { id: dbsSample.sample.id, type: 'DriedBloodSpot' }
      editBloodSampleDialog.value = true
    }

    const getUrineKit = (sample) => {
      const kitId = (sample.u_urine_kit_used != '') ? sample.u_urine_kit_used : ((sample.u_partial_sample_code != '') ? sample.u_partial_sample_code : null)
      if (!kitId) {
        return ''
      }
      return getKitNumber(kitId)
    }
    
    const getUrineManufacturer = (sample) => {
      const manufacturerId = (sample.u_urine_manufacturer != '') ? sample.u_urine_manufacturer : ((sample.u_partial_sample_manufacturer != '') ? sample.u_partial_sample_manufacturer : null)
      if (!manufacturerId) {
        return ''
      }
      return getManufacturer(manufacturerId)
    }

    const getKitNumber = (kitId) => {
      const kitHardware = kitHardwareList.value.find(x => x.id === kitId)
      return kitHardware ? kitHardware.serial_number : ''
    }
    
    const getManufacturer = (manufacturerId) => {
      const manufacturer = kitManufacturerList.value.find(x => x.id === manufacturerId)
      return manufacturer ? manufacturer.name : ''
    }

    onMounted(async () => {

      dcf.value = await sn_customerservice_dcf.get(props.dcfId);

      // TODO: determine via Athlete/Mission data
      hasUrineSamples.value = true
      hasBloodPassportSamples.value = true
      hasBloodHghSamples.value = true
      hasBloodDbsSamples.value = true

      kitHardwareList.value = await alm_hardware.getAll()
      kitManufacturerList.value = await core_company.select(
        'u_type',
        'test_kit_manufacturer'
      )

      urineSamples.value = await sn_customerservice_urine_samples.getByDcf(props.dcfId);
      if (urineSamples.value.length > 0) {
        urineSamplesData.value = urineSamples.value.map(
          (urineSample) => {
            return {
              sample: urineSample,
              kitNumber: getUrineKit(urineSample),
              manufacturer: getUrineManufacturer(urineSample),
            }
          }
        )
      }

      bloodPassportSamples.value = await sn_customerservice_blood_samples.getBloodPassportSamplesByDcf(props.dcfId);
      if (bloodPassportSamples.value.length > 0) {
        bloodPassportSamplesData.value = bloodPassportSamples.value.map(
          (bloodPassportSample) => {
            return {
              sample: bloodPassportSample,
              kitNumber: getKitNumber(bloodPassportSample.u_blood_passport_kit_used),
              manufacturer: getManufacturer(bloodPassportSample.u_manufacturer_blood_passport),
            }
          }
        )
      }

      bloodHghSamples.value = await sn_customerservice_blood_samples.getBloodSamplesByDcf(props.dcfId);

      if (bloodHghSamples.value.length > 0) {
        bloodHghSamplesData.value = bloodHghSamples.value.map(
          (bloodHghSample) => {
            return {
              sample: bloodHghSample,
              kitNumber: getKitNumber(bloodHghSample.u_blood_kit_used),
              manufacturer: getManufacturer(bloodHghSample.u_blood_manufacturer),
            } 
          }
        )
      }

      bloodDbsSamples.value = await sn_customerservice_blood_samples.getDbsSamplesByDcf(props.dcfId);
      if (bloodDbsSamples.value.length > 0) {
        bloodDbsSamplesData.value = bloodDbsSamples.value.map(
          (bloodDbsSample) => {
            return {
              sample: bloodDbsSample,
              kitNumber: getKitNumber(bloodDbsSample.u_dbs_kit_used),
              manufacturer: getManufacturer(bloodDbsSample.u_dbs_manufacturer),
            }
          }
        )
      }

    })

    
    async function save() {
        let data = toRaw(dcf.value)
        await sn_customerservice_dcf.save(data)
        await dal.commit()
    }

    return {
      tab: null,
      loading: false,
      urineSamplesData,
      bloodPassportSamplesData,
      hasUrineSamples,
      hasBloodPassportSamples,
      hasBloodHghSamples,
      hasBloodDbsSamples,
      openUrineSample,
      openPartialUrineSample,
      openBloodPassportSample,
      openHghSample,
      openDbsSample,
      bloodHghSamplesData,
      bloodDbsSamplesData,
      showUrineSampleDialog,
      showBloodPassportSampleDialog,
      showPartialUrineSampleDialog,
      showHghSampleDialog,
      showDbsSampleDialog,
      dcf,
      editBloodSampleDialog,
      editUrineSampleDialog,
      editProps,
      isTrue,
      formatDateTime,
      save

    }
  }
}
</script>

<style></style>
