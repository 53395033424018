<template>
  <v-card>
    <v-toolbar color="#f97316" dark>
      <v-btn icon="mdi-close" @click="close"></v-btn>

      <v-toolbar-title>New Athlete</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text="Save" variant="text" @click="save"></v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-sheet class="si-form-sheet si-fullscreen-modal text-body-2 mx-auto">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">First Name</span>
            <v-text-field v-model="athlete.first_name" label="" density="compact" bg-color="white"
              :error-messages="athleteValidator.first_name.$errors.map((e) => e.$message)" type="text"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">Last Name</span>
            <v-text-field v-model="athlete.last_name" label="" density="compact" bg-color="white"
              :error-messages="athleteValidator.last_name.$errors.map((e) => e.$message)" type="text"></v-text-field>
          </v-col>
        </v-row>
        <div class="si-form-gap"></div>
        <v-row>
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">Gender</span>
            <v-combobox label="" v-model="athlete.gender" density="compact"
              :error-messages="athleteValidator.gender.$errors.map((e) => e.$message)"
              :items="['Female', 'Male', 'Other', 'Prefer not to say']" bg-color="white"></v-combobox>
          </v-col>
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">Date of Birth</span>
            <date-picker v-model="athlete.u_dob" :validation-error="athleteValidator.u_dob"></date-picker>
          </v-col>
        </v-row>
        <div class="si-form-gap"></div>
        <v-row>
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">Email</span>
            <v-text-field v-model="athlete.email" label="" density="compact" bg-color="white"
              :error-messages="athleteValidator.email.$errors.map((e) => e.$message)" type="text"></v-text-field>
          </v-col>
        </v-row>
        <div class="si-form-gap"></div>
        <v-row v-if="showSportSelectors">
          <v-col cols="12" md="6" class="si-form">
            <span class="font-italic si-top-label">Sport</span>
            <v-select label="" v-model="selectedSport" density="compact"
              :error-messages="sportValidator.sport.$errors.map((e) => e.$message)" item-title="u_name" item-value="id"
              :items="sports" bg-color="white"></v-select>
          </v-col>
          <v-col cols="12" md="6" class="si-form" v-if="showDiscipline">
            <span class="font-italic si-top-label">Discipline</span>
            <v-select label="" v-model="selectedDiscipline" density="compact"
              :error-messages="sportValidator.discipline.$errors.map((e) => e.$message)" item-title="u_name"
              item-value="id" :items="disciplines" bg-color="white">

            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-snackbar v-model="validationError">
      Please fill in all required fields.

      <template v-slot:actions>
        <v-btn color="red" variant="text" @click="validationError = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { ref, onMounted, toRaw, watch, computed } from "vue";
import customer_contact from "@/dal/modules/customer_contact";
import DatePicker from "@/components/DatePicker.vue";
import { required, email } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import sn_customerservice_sports from "@/dal/modules/sn_customerservice_sports";
import sn_customerservice_discipline from "@/dal/modules/sn_customerservice_discipline";

export default {
  name: "AthleteEdit",
  components: {
    DatePicker,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    missionNumber: {
      type: String,
      required: true,
    },
    sport: {
      type: String,
      required: true,
    },
    discipline: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const mission = ref();
    const athlete = ref({});
    const isLoaded = ref(false);
    const validationError = ref(false);
    const sports = ref([]);
    const disciplines = ref([]);
    const selectedSport = ref();
    const selectedDiscipline = ref();
    const showSportSelectors = ref(false);

    watch(
      () => selectedSport.value,
      async (value) => {
        disciplines.value = await sn_customerservice_discipline.getBySport(value);
      }
    );

    const showDiscipline = computed(() => {
      return disciplines.value.length > 0;
    });

    const rules = computed(() => {
      return {
        sport: { required },
        discipline: selectedSport.value && showDiscipline.value ? { required } : {},
      };
    });

    const sportValidator = useVuelidate(
      rules,
      {
        sport: selectedSport,
        discipline: selectedDiscipline
      }
    );

    const athleteValidator = useVuelidate(
      {
        first_name: { required },
        last_name: { required },
        gender: { required },
        u_dob: { required },
        email: { required, email }
      },
      athlete
    );

    onMounted(async () => {
      try {

        if (props.id && props.id !== "new") {
          // get Athlete by id
          athlete.value = await customer_contact.get(props.id);
          // sport is by session so do not show
        } else {
          // create new Athlete
          athlete.value = await customer_contact.createNew();
          showSportSelectors.value = true;
        }

        sports.value = await sn_customerservice_sports.getAll();

        selectedSport.value = sports.value.find(
          (s) => s.u_name === props.sport || s.id === props.sport
        )?.id;

        disciplines.value = await sn_customerservice_discipline.getBySport(selectedSport.value);

        selectedDiscipline.value = disciplines.value.find(
          (d) => d.u_name === props.discipline || d.id === props.discipline
        )?.id;


      } catch (error) {
        console.error("Error:", error);
      } finally {
        isLoaded.value = true;
      }
    });

    const close = () => {
      emit("close");
    };

    const save = async () => {
      athleteValidator.value.$validate();
      sportValidator.value.$validate();
      if (athleteValidator.value.$invalid || sportValidator.value.$invalid) {
        validationError.value = true;
        return;
      }

      // save the athlete
      const customerContact = toRaw(athlete.value);

      emit("created", {
        customerContact,
        sport: selectedSport.value,
        discipline: selectedDiscipline.value,
      });
    };

    return {
      mission,
      tab: null,
      isLoaded,
      athlete,
      close,
      save,
      athleteValidator,
      sports,
      disciplines,
      showDiscipline,
      selectedSport,
      selectedDiscipline,
      sportValidator,
      validationError,
      showSportSelectors
    };
  },
};
</script>

<style></style>
